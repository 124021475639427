import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Col,
  Collapse,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography as Text,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React, { useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import config from '@/config'
import { landscapePageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export default function Transaction(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const printRef = useRef()
  const { Panel } = Collapse
  const receiptUrl = config.receipt
  const [params, setParams] = React.useState(null)
  const [transaction, setTransaction] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [searchText, setSearchText] = React.useState()
  const [tableFilters, setTableFilters] = React.useState({})
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [paymentMethods, setPaymentMethods] = React.useState([])
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const tableNoResults = []
  const tableNoFilters = []
  const paymentMethodFilters = []

  const getPaymentMethods = async () => {
    const paymentMethods = await dimorderApi.instance(activeMerchant.id)?.getPaymentMethods()
    setPaymentMethods(paymentMethods)
  }

  React.useEffect(() => {
    getPaymentMethods()
    setTableFilters({})
  }, [activeMerchant])

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const transaction = await dimorderApi.instance(activeMerchant.id)?.getTransaction(params)
          setTransaction(transaction)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(transaction?.data?.length)
  }, [transaction])

  transaction?.data?.forEach((o) => {
    if (o.tableName) {
      if (!tableNoResults.includes(o.tableName)) {
        tableNoResults.push(o.tableName)
        tableNoFilters.push({
          text: o.tableName,
          value: o.tableName,
        })
      }
    }
  })

  if (paymentMethods?.data) {
    Object.entries(paymentMethods.data).forEach((payment) => {
      paymentMethodFilters.push({
        text: payment[1],
        value: payment[1],
      })
    })
  }

  tableNoFilters
    .sort((a, b) => a.value.localeCompare(b.value))
    .sort((a, b) => {
      const num1 = a.value.match(/[^\d]+|\d+/g)
      const num2 = b.value.match(/[^\d]+|\d+/g)
      if (num1[0] !== num2[0] && !isNaN(Number(num1[0])) && !isNaN(Number(num2[0]))) {
        return Number(num1[0]) - Number(num2[0])
      } else if (num1[0] === num2[0] && !isNaN(Number(num1[1])) && !isNaN(Number(num2[1]))) {
        return Number(num1[1]) - Number(num2[1])
      }
      return num1[0].localeCompare(num2[0])
    })
  paymentMethodFilters.sort()

  let searchInput = null

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`${t('app.common.search')}${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex.orderNumber)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            {t('app.common.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            {t('app.common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : colors.textSecondary,
          fontSize: 15,
        }}
      />
    ),
    filteredValue: tableFilters?.order || null,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].orderNumber.toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
    render: (order) => {
      if (
        order.deliveryType === 'table' ||
        (order.deliveryType !== 'table' && order.paymentStatus !== 'cancelled')
      ) {
        return (
          <Button
            className={classes.btn}
            onClick={() => {
              window.open(receiptUrl + `${order.orderId}`)
            }}
          >
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={order.orderNumber ? order.orderNumber.toString() : ''}
            />
          </Button>
        )
      } else {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={order.orderNumber ? order.orderNumber.toString() : ''}
          />
        )
      }
    },
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableFilters(filters)
    setNumberOfResults(extra.currentDataSource.length)
  }

  const transactionData = {
    columns: [
      {
        title: (
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {t('app.constant.order.orderNumber')}
            <Tooltip title={t('page.transaction.orderNoTooltip')}>
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'order',
        ...getColumnSearchProps('order', t('app.constant.order.orderNumber')),
        sorter: (a, b) => a.order.orderNumber.localeCompare(b.order.orderNumber),
      },
      {
        title: t('page.transaction.tableNo'),
        dataIndex: 'tableName',
        filters: tableNoFilters,
        filteredValue: tableFilters?.tableName || null,
        onFilter: (value, record) => record?.tableName?.table === value,
        render: (tableName) => {
          if (!tableName.table) return '--'
          return (
            <Text>
              {tableName.subTable
                ? `${tableName.table}(${String.fromCharCode(tableName.subTable + 64)})`
                : tableName.table}
            </Text>
          )
        },
      },
      {
        title: t('app.constant.order.orderType'),
        dataIndex: 'deliveryType',
        render: (deliveryType) => (
          <Text>
            {deliveryType === 'table'
              ? t('app.constant.deliveryType.table')
              : deliveryType === 'merchantTakeaway'
              ? t('app.constant.deliveryType.merchantTakeaway')
              : deliveryType === 'dimorderStoreDelivery'
              ? t('app.constant.deliveryType.dimorderStoreDelivery')
              : deliveryType === 'dimorderTakeaway'
              ? t('app.constant.deliveryType.dimorderTakeaway')
              : '-'}
          </Text>
        ),
        filters: [
          { text: t('app.constant.deliveryType.table'), value: 'table' },
          {
            text: t('app.constant.deliveryType.merchantTakeaway'),
            value: 'merchantTakeaway',
          },
          {
            text: t('app.constant.deliveryType.dimorderStoreDelivery'),
            value: 'dimorderStoreDelivery',
          },
          {
            text: t('app.constant.deliveryType.dimorderTakeaway'),
            value: 'dimorderTakeaway',
          },
        ],
        filteredValue: tableFilters?.deliveryType || null,
        onFilter: (value, record) => record?.deliveryType.includes(value),
      },
      {
        title: t('app.common.date'),
        dataIndex: 'orderAt',
        sorter: (a, b) => new Date(a.orderAt) - new Date(b.orderAt),
      },
      {
        title: t('page.transaction.pickupAt'),
        dataIndex: 'pickupAt',
      },
      {
        title: t('page.transaction.deliverAt'),
        dataIndex: 'deliverAt',
      },
      {
        title: t('page.transaction.status'),
        dataIndex: 'paymentStatus',
        render: (paymentStatus) => (
          <Text>
            {paymentStatus === 'paid'
              ? t('page.transaction.paid')
              : paymentStatus === 'pending'
              ? t('page.transaction.pending')
              : paymentStatus === 'cancelled'
              ? t('page.transaction.cancelled')
              : '-'}
          </Text>
        ),
        filters: [
          { text: t('page.transaction.paid'), value: 'paid' },
          {
            text: t('page.transaction.pending'),
            value: 'pending',
          },
          {
            text: t('page.transaction.cancelled'),
            value: 'cancelled',
          },
        ],
        filteredValue: tableFilters?.paymentStatus || null,
        onFilter: (value, record) => record?.paymentStatus.includes(value),
      },
      {
        title: t('page.transaction.paymentMethod'),
        dataIndex: 'paymentMethod',
        filters: paymentMethodFilters,
        filteredValue: tableFilters?.paymentMethod || null,
        onFilter: (value, record) => record?.paymentMethod.includes(value),
      },
      {
        title: t('app.common.peopleCount'),
        dataIndex: 'numCustomers',
      },
      {
        title: t('page.transaction.subtotal'),
        dataIndex: 'subtotal',
      },
      {
        title: t('page.transaction.surcharge'),
        dataIndex: 'surcharge',
      },
      {
        title: t('page.transaction.tips'),
        dataIndex: 'tips',
      },
      {
        title: t('page.transaction.discount'),
        dataIndex: 'discount',
      },
      {
        title: t('page.transaction.roundingDiscrepancy'),
        dataIndex: 'roundingDiscrepancy',
      },
      {
        title: t('page.transaction.total'),
        dataIndex: 'total',
      },
    ],
    data: [],
  }

  if (transaction?.data) {
    for (const [key, value] of Object.entries(transaction?.data)) {
      transactionData.data.push({
        key,
        order: {
          orderNumber: value.orderNumber,
          orderId: value.id,
          deliveryType: value.deliveryType,
          paymentStatus: value.paymentStatus,
        },
        tableName: {
          table: value.tableName,
          subTable: value.subTable,
        },
        deliveryType: value.deliveryType ? value.deliveryType : '-',
        orderAt: value.orderAt ? `${moment(value.orderAt).format('YYYY/MM/DD HH:mm')}` : '-',
        pickupAt: value.pickupAt ? `${moment(value.pickupAt).format('YYYY/MM/DD HH:mm')}` : '-',
        deliverAt: value.deliverAt ? `${moment(value.deliverAt).format('YYYY/MM/DD HH:mm')}` : '-',
        paymentStatus: value.paymentStatus ? value.paymentStatus : '-',
        paymentMethod: value.paymentMethod ? value.paymentMethod : '-',
        numCustomers: value.numCustomers ? value.numCustomers : '-',
        subtotal: currencyWithCommas(value.subtotal, 2, 2),
        surcharge: currencyWithCommas(value.surcharge, 2, 2),
        tips: currencyWithCommas(value.tips, 2, 2),
        discount: currencyWithCommas(value.discount, 2, 2),
        roundingDiscrepancy: currencyWithCommas(value.roundingDiscrepancy, 2, 2),
        total: currencyWithCommas(value.total, 1, 1),
      })
    }
  }

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={transactionData.columns}
            dataSource={transactionData.data}
            scroll={{ x: 1500 }}
            onChange={handleTableChange}
            summary={() => {
              const total = {
                subtotal: 0,
                surcharge: 0,
                tips: 0,
                discount: 0,
                roundingDiscrepancy: 0,
                total: 0,
              }
              _.each(total, (v, k) => {
                total[k] = transaction?.data?.reduce((a, c) => {
                  a = a + +c[k]
                  return a
                }, 0)
              })
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{t('page.transaction.total')}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{formatter.format(total.subtotal)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{formatter.format(total.surcharge)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{formatter.format(total.tips)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{formatter.format(total.discount)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>
                        {formatter.format(total.roundingDiscrepancy)}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text className={classes.totalText}>{formatter.format(total.total)}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.transaction.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={transactionData.columns}
            dataSource={transactionData.data}
            pagination={false}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: landscapePageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportTransactionCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.transaction.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {transaction && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
}))
