import axios from 'axios'

import config from '@/config'

export class CustomerApi {
  /** @type {AxiosInstance} */
  axios
  /** @type {string} */
  refreshToken
  /** @type {(auth: CustomerApiAuthData) => void} */
  refreshTokenCallback
  /** @type {() => void} */
  logoutCallback

  constructor(url) {
    this.axios = axios.create({
      baseURL: url || config.api.customer,
    })

    this.axios.interceptors.response.use(
      (response) => {
        return response
      },
      /** @param {AxiosError} error */
      async (error) => {
        const res = error.response
        console.log('[Axios][Interceptor] Response Error', res)
        const errorMessage = res?.data?.errors?.[0].title || error.message || error
        if (res?.data?.errors?.[0].title === 'error.token.expired') {
          // 如果錯誤是因為 token 過期      //避免因refresh token過期造成無限loop
          if (this.refreshToken != null && res?.config?.url !== '/tokens') {
            // 取得新的 token
            try {
              const { data: auth } = await this.refresh(this.refreshToken)
              // 呼叫 callback
              this.refreshTokenCallback?.(auth)
            } catch (error) {
              // refresh 失敗，登出
              this.logoutCallback?.(errorMessage)
              return Promise.reject(error)
            }

            // 換新 token 重新 request
            error.config.headers.Authorization = this.axios.defaults.headers.common.Authorization
            return await this.axios.request(error.config)
          } else {
            this.logoutCallback?.(errorMessage)
          }
        }
        if (res?.data?.errors?.[0].title === 'error.token.invalid') {
          // token 錯誤，登出
          this.logoutCallback?.(errorMessage)
        }
        return Promise.reject(error)
      },
    )
  }

  setToken = (auth) => {
    this.axios.defaults.headers.common.Authorization = `${auth.token_type} ${auth.access_token}`
    this.refreshToken = auth.refresh_token
  }

  async sendOtp(phone) {
    const response = await this.axios.get(`/auth/otp/${phone}/pin`)

    return response.data
  }

  async login(account, pin) {
    const endpoint = '/auth/otp'

    const response = await this.axios.post(endpoint, { account, pin })

    this.setToken(response.data.data)

    return response.data
  }

  async kconnectLogin(code) {
    const endpoint = '/auth/kconnect'

    const { data } = await this.axios.post(endpoint, { code })

    return data
  }

  /**
   * @param {(auth: CustomerApiAuthData) => void} callback
   */
  setRefreshTokenCallback = (callback) => {
    this.refreshTokenCallback = callback
  }

  /**
   * @param {() => void} callback
   */
  setLogoutCallback(callback) {
    this.logoutCallback = callback
  }

  refresh = async () => {
    /** @type {AxiosResponse<ResponseData<CustomerApiAuthData>>} */
    const response = await this.axios.post('/tokens', {
      refresh_token: this.refreshToken,
    })

    this.setToken(response.data.data)

    return response.data
  }

  async updateMerchant(token, refreshToken) {
    const endpoint = '/auth/dimorder-merchant'

    const response = await this.axios.post(endpoint, { token, refresh_token: refreshToken })

    return response.data
  }

  async getMe() {
    const endpoint = '/me'

    const response = await this.axios.get(endpoint)

    return response.data
  }

  /**
   * @param {File} file
   * @returns {Promise}
   */
  async uploadCustomerAvatar(file) {
    const formData = new FormData()
    formData.append('avatar', file)

    /** @type {AxiosResponse} */
    const response = await this.axios.post('/me:upload-avatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  }

  /**
   * @param {CustomerApiCustomerInfo} customer
   * @returns {Promise}
   */
  async updateCustomerInfo(customer) {
    /** @type {AxiosResponse} */
    const response = await this.axios.patch('/me', customer)

    return response.data
  }
}

export default new CustomerApi()
