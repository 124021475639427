import { DeleteOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { ModalForm, ProFormText, ProFormSelect } from '@ant-design/pro-form'
import { Button, Radio, Row, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { FLOOR_PLAN } from '@/constants'

const areaSizeOptions = [
  {
    value: '10.2',
    label: '10.2″',
  },
  {
    value: '11',
    label: '11″',
  },
  {
    value: '12.9',
    label: '12.9″',
  },
]

export default function AreaSection(props) {
  const { areas = [], areaSize, selectedAreaName, onAddArea, onDeleteArea, onSelectArea } = props
  const { t } = useTranslation()

  // 新增區域的Form
  function AddAreaButton() {
    let areaSizeValue = null
    for (const key in FLOOR_PLAN) {
      const subObject = FLOOR_PLAN[key]
      if (
        typeof subObject === 'object' &&
        subObject.width === areaSize?.width &&
        subObject.height === areaSize?.height
      ) {
        areaSizeValue = key
        break
      }
    }
    const checkUniqueAreaName = (_, value) => {
      const existingArea = areas.find((area) => area === value.toString())
      if (!existingArea) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(t('page.floorPlan.form.areaExist')))
    }
    const toolTip = () => {
      return (
        <>
          <div>10.2″：</div>
          <div>iPad(9th generation)</div>
          <div>11″：</div>
          <div>iPad(10th generation)</div>
          <div>iPad Air(4/5th generation)</div>
          <div>iPad Pro 11-in.</div>
          <div>12.9″：</div>
          <div>iPad Pro 12.9-in.</div>
        </>
      )
    }

    return (
      <ModalForm
        title={t('page.floorPlan.areaButton.add')}
        trigger={
          <Button type='primary' icon={<PlusOutlined />} size='large'>
            {t('page.floorPlan.areaButton.add')}
          </Button>
        }
        submitter={{
          searchConfig: {
            submitText: t('app.common.yes'),
            resetText: t('app.common.no'),
          },
        }}
        onFinish={(value) => {
          onAddArea(value)
        }}
      >
        <ProFormText
          name='name'
          label={t('page.floorPlan.form.areaName')}
          placeholder={t('page.floorPlan.form.pleaseEnterAreaName')}
          rules={[{ required: true }, { validator: checkUniqueAreaName }]}
        />
        <ProFormSelect
          width='sm'
          name='size'
          initialValue={areaSizeValue ?? areaSizeOptions[0].value}
          options={areaSizeOptions}
          label={t('page.floorPlan.form.areaSize')}
          placeholder={t('page.floorPlan.form.pleaseSelect')}
          tooltip={toolTip}
          disabled={areas.length > 0}
          rules={[{ required: true }]}
        />
      </ModalForm>
    )
  }

  // 刪除區域 Button
  function DeleteAreaButton() {
    const handleDeleteTableGroup = () => {
      Modal.confirm({
        title: `${t('page.floorPlan.areaButton.delete')} ${selectedAreaName} ?`,
        icon: <ExclamationCircleFilled />,
        okText: t('app.common.yes'),
        okType: 'danger',
        cancelText: t('app.common.no'),
        onOk() {
          onDeleteArea()
        },
      })
    }

    return (
      <Button
        type='primary'
        icon={<DeleteOutlined />}
        size='large'
        onClick={handleDeleteTableGroup}
      >
        {t('page.floorPlan.areaButton.delete')}
      </Button>
    )
  }

  // 顯示所有區域
  function AreaList() {
    return (
      <Radio.Group
        borderRadius={10}
        size='large'
        value={selectedAreaName}
        buttonStyle='solid'
        onChange={(e) => {
          onSelectArea(e.target.value)
        }}
      >
        {areas.map((area, i) => {
          return (
            <Radio.Button key={i} value={area}>
              {area}
            </Radio.Button>
          )
        })}
      </Radio.Group>
    )
  }

  return (
    <Row justify='space-between'>
      <AreaList />
      <div style={{ gap: 5, display: 'flex' }}>
        {selectedAreaName && <DeleteAreaButton />}
        <AddAreaButton />
      </div>
    </Row>
  )
}
