import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IUserState} */
export const initialState = {
  isInit: false,
  isLogin: false,
  user: null,
}

export default produce((draft, action) => {
  switch (action?.type) {
    case ActionTypes.INIT: {
      draft.isInit = true
      break
    }

    case ActionTypes.LOGIN: {
      draft.isLogin = true
      break
    }

    case ActionTypes.UPDATE_USER: {
      draft.user = action.payload
      break
    }

    case 'LOGOUT': {
      return initialState
    }

    case 'test': {
      draft.testValue = action.payload
      break
    }

    default:
      break
  }
}, initialState)
