import { Typography } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import { useGroupId } from '@/hooks/group'

export default function BreadcrumbItem(props) {
  const { route, routes } = props
  const groupId = useGroupId()

  const isLast = Boolean(routes.indexOf(route) === routes.length - 1)
  const path = route.path.replace(':groupId', groupId)

  return isLast ? (
    <Typography.Text style={{ fontSize: 20 }}>{route.breadcrumbName}</Typography.Text>
  ) : (
    <Link to={path}>
      <Typography.Text style={{ fontSize: 20 }}>{route.breadcrumbName}</Typography.Text>
    </Link>
  )
}
