import { PageContainer } from '@ant-design/pro-layout'
import { Typography as Text } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Maintenance(props) {
  const { t } = useTranslation()

  return (
    <PageContainer ghost header={{ title: t('page.maintenance.title') }}>
      <Text> {t('page.maintenance.description')} </Text>
      <Text> {t('page.maintenance.time')}</Text>
    </PageContainer>
  )
}
