import { makeStyles } from '@material-ui/styles'
import { Col, Row, Typography as Text } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/Card'
import { currencyWithCommas } from '@/libs/numberWithCommas'

const columns = [
  {
    key: 'sales',
    t: {
      day: 'page.overview.avgDailySales',
      week: 'page.overview.avgWeeklySales',
      month: 'page.overview.avgMonthlySales',
      year: 'page.overview.avgYearlySales',
    },
  },
  {
    key: 'customerCount',
    t: {
      day: 'page.overview.avgDailyCustomer',
      week: 'page.overview.avgWeeklyCustomer',
      month: 'page.overview.avgMonthlyCustomer',
      year: 'page.overview.avgYearlyCustomer',
    },
  },
  {
    key: 'orderCount',
    t: {
      day: 'page.overview.avgDailyOrder',
      week: 'page.overview.avgWeeklyOrder',
      month: 'page.overview.avgMonthlyOrder',
      year: 'page.overview.avgYearlyOrder',
    },
  },
]

function Averages({ overall, interval, print }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const dataByInterval = {
    day: overall.averagePerDay,
    week: overall.averagePerWeek,
    month: overall.averagePerMonth,
    year: overall.averagePerYear,
  }

  const renderColumn = (col) => {
    if (print) {
      return (
        <Col key={col.key} span={8} className={classes.printContainer}>
          <Text className={classes.printData}>
            {col.key === 'sales'
              ? currencyWithCommas(dataByInterval[interval][col.key])
              : dataByInterval[interval][col.key]}
          </Text>
          <Text className={classes.printDataTitle}>{t(col.t[interval])}</Text>
        </Col>
      )
    }

    return (
      <Col key={col.key} xl={8} xs={24} className={classes.container}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='right'
          boxShadow='1px 1px 5px rgba(0, 0, 0, 0.3)'
        >
          <Text className={classes.data}>
            {col.key === 'sales'
              ? currencyWithCommas(dataByInterval[interval][col.key])
              : dataByInterval[interval][col.key]}
          </Text>
          <Text className={classes.dataTitle}>{t(col.t[interval])}</Text>
        </Card>
      </Col>
    )
  }

  return <Row gutter={16}>{columns.map(renderColumn)}</Row>
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  printData: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  printDataTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  container: {
    paddingTop: 20,
  },
  data: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  dataTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  },
}))

export default Averages
