import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  TimePicker,
  Typography as Text,
  message,
} from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Card from '@/components/Card'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

import CategorySelector from './CategorySelector'

export default function FilterDialog(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { RangePicker } = DatePicker
  const {
    filter,
    filterTime,
    filterOrderType,
    filterCustomers,
    filterSerial,
    filterCategory,
    filterPayment,
    filterDataInterval,
    paymentMethods,
    compare,
    momentFormatX,
    skipCompareTime,
    enableOverlap,
  } = props
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const today = moment(new Date())
  const startOfDay = moment().startOf('day')
  const endOfDay = moment().endOf('day')
  const [fromDate, setFromDate] = useState(today)
  const [toDate, setToDate] = useState(today)
  const [fromTime, setFromTime] = useState(startOfDay)
  const [toTime, setToTime] = useState(endOfDay)
  const [filterFromTime, setFilterFromTime] = useState(startOfDay)
  const [filterToTime, setFilterToTime] = useState(endOfDay)
  const [compareFromDate, setCompareFromDate] = useState(today)
  const [compareToDate, setCompareToDate] = useState(today)
  const [compareFromTime, setCompareFromTime] = useState(startOfDay)
  const [compareToTime, setCompareToTime] = useState(endOfDay)
  const [compareTime, setCompareTime] = useState(false)
  const [orderType, setOrderType] = useState('all')
  const [groupBy, setGroupBy] = useState('day')
  const [serial, setSerial] = useState(null)
  const [minCustomer, setMinCustomer] = useState(0)
  const [maxCustomer, setMaxCustomer] = useState(999)
  const [payment, setPayment] = useState('all')
  const [categoryIds, setCategoryIds] = useState(null)
  const [filterActiveKey, setFilterActiveKey] = useState('0')
  const [isFilterActive, setIsFilterActive] = useState(false)
  const { Panel } = Collapse
  const { Option } = Select
  const format = 'HH:mm'
  const lang = useSelector((state) => state.app.lang)
  // reset payment filter after switching active merchant
  useEffect(() => {
    setPayment('all')
  }, [activeMerchant])

  const onFilterActiveKeyChange = (key) => {
    setFilterActiveKey(key)
    setIsFilterActive(!isFilterActive)
  }

  const reset = () => {
    setFromDate(today)
    setToDate(today)
    setFromTime(startOfDay)
    setToTime(endOfDay)
    setCompareFromDate(today)
    setCompareToDate(today)
    setCompareFromTime(startOfDay)
    setCompareToTime(endOfDay)
    setFilterFromTime(startOfDay)
    setFilterToTime(endOfDay)
    setCompareTime(false)
    setOrderType('all')
    setMinCustomer(0)
    setMaxCustomer(999)
    setSerial(null)
    setPayment('all')
  }

  const rangesZH = {
    今天: [moment(), moment()],
    昨天: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    本月: [moment().startOf('month'), moment()],
    上月: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    本年: [moment().startOf('year'), moment()],
    最近7天: [moment().subtract(6, 'days'), moment()],
    最近30天: [moment().subtract(29, 'days'), moment()],
  }

  const rangesEN = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Month': [moment().startOf('month'), moment()],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
    'This Year': [moment().startOf('year'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  }

  const checkCompareTime = () => {
    let temp = compareFromDate.format('YYYY-MM-DD')
    let error = false
    if (
      compareToDate.diff(compareFromDate, 'day') !== toDate.diff(fromDate, 'day') ||
      compareToTime.diff(compareFromTime, 'minute') !== toTime.diff(fromTime, 'minute')
    ) {
      error = true
      message.warning(t('app.component.filterDialog.error.timeRange'))
    }
    while (!moment(temp).isAfter(compareToDate.format('YYYY-MM-DD')) && !error) {
      if (
        moment(temp).isBetween(fromDate.format('YYYY-MM-DD'), toDate.format('YYYY-MM-DD')) ||
        moment(temp).isSame(fromDate.format('YYYY-MM-DD')) ||
        moment(temp).isSame(toDate.format('YYYY-MM-DD'))
      ) {
        if (!enableOverlap) {
          error = true
          message.warning(t('app.component.filterDialog.error.timeOverlap'))
        }
      }
      temp = moment(temp).add(1, 'days').format('YYYY-MM-DD')
    }
    return error
  }

  const checkCategoryIsEmpty = () => {
    let error = false
    if (categoryIds?.length === 0) {
      message.warning(t('app.component.filterDialog.error.categoryIsEmpty'))
      error = true
    }
    return error
  }

  const fetchParams = () => {
    const params = {
      start: moment(fromDate.format('YYYY-MM-DD') + ' ' + fromTime.format('HH:mm')).toISOString(),
      end: moment(toDate.format('YYYY-MM-DD') + ' ' + toTime.format('HH:mm')).toISOString(),
      interval_start: undefined,
      interval_end: undefined,
      delivery_type: undefined,
      min_people: undefined,
      max_people: undefined,
      order_number: undefined,
      compare: compareTime,
      compare_date_start: undefined,
      compare_date_end: undefined,
      startX: undefined,
      endX: undefined,
      category_ids: undefined,
      payment_methods: undefined,
      payment_name: undefined,
      group_by: undefined,
    }
    if (momentFormatX) {
      params.startX = moment(fromDate.format('YYYY-MM-DD') + ' ' + fromTime.format('HH:mm')).format(
        'X',
      )
      params.endX = moment(toDate.format('YYYY-MM-DD') + ' ' + toTime.format('HH:mm')).format('X')
    }

    if (compareTime) {
      if (compare) {
        params.compare_date_start = moment(
          compareFromDate.format('YYYY-MM-DD') + ' ' + compareFromTime.format('HH:mm'),
        ).toISOString()
        params.compare_date_end = moment(
          compareToDate.format('YYYY-MM-DD') + ' ' + compareToTime.format('HH:mm'),
        ).toISOString()
      }
    }

    if (filter && isFilterActive) {
      if (filterTime) {
        params.interval_start = filterFromTime.format('HH:mm')
        params.interval_end = filterToTime.format('HH:mm')
      }
      if (filterOrderType) {
        if (orderType !== 'all') {
          params.delivery_type = orderType
        }
      }
      if (filterCustomers) {
        params.min_people = minCustomer
        params.max_people = maxCustomer
      }
      if (filterSerial) {
        params.order_number = serial
      }
      if (filterCategory) {
        params.category_ids = categoryIds
      }
      if (filterPayment) {
        if (payment !== 'all') {
          params.payment_methods = [payment]
        }
      }
      if (filterDataInterval) {
        params.group_by = groupBy
      }
    }
    return params
  }

  const onSubmit = () => {
    if (compareTime) {
      const error = checkCompareTime()
      if (error) return
    }
    if (filterCategory && isFilterActive) {
      const error = checkCategoryIsEmpty()
      if (error) return
    }

    const params = fetchParams()

    props.setParams(params)
  }

  const onCompareTimeChange = () => {
    setCompareTime(!compareTime)
  }

  const disabledDate = (current) => {
    return current > moment().endOf('day')
  }

  const onDateChange = ([start, end]) => {
    setFromDate(start.startOf('day'))
    setToDate(end.startOf('day'))
  }

  const onTimeChange = ([start, end]) => {
    setFromTime(start.startOf('minute'))
    setToTime(end.startOf('minute'))
    if (compareTime && skipCompareTime) {
      onCompareRangeChange([start.startOf('minute'), end.startOf('minute')])
    }
  }

  const onCompareDateChange = ([start, end]) => {
    setCompareFromDate(start.startOf('day'))
    setCompareToDate(end.startOf('day'))
  }

  const onCompareRangeChange = ([start, end]) => {
    setCompareFromTime(start.startOf('minute'))
    setCompareToTime(end.startOf('minute'))
  }

  const onFilterTimeChange = ([start, end]) => {
    setFilterFromTime(start.startOf('minute'))
    setFilterToTime(end.startOf('minute'))
  }

  const onOrderTypeChange = (orderType) => {
    setOrderType(orderType)
  }

  const onMinCustomerChange = (customer) => {
    setMinCustomer(customer)
  }

  const onMaxCustomerChange = (customer) => {
    setMaxCustomer(customer)
  }

  const onSerialChange = (serial) => {
    setSerial(serial.target.value)
  }

  const onPaymentChange = (payment) => {
    setPayment(payment)
  }

  const onGroupByChange = (groupBy) => {
    setGroupBy(groupBy)
  }

  let paymentOptions = null

  const setPaymentName = (type, name) => {
    switch (type) {
      case 'ae': {
        return t('app.constant.payment.ae')
      }
      case 'unionPay': {
        return t('app.constant.payment.unionPay')
      }
      case 'gift-card': {
        return t('app.constant.payment.gift-card')
      }
      case 'octopus': {
        return t('app.constant.payment.octopus')
      }
      case 'cash': {
        return t('app.constant.payment.cash')
      }
      case 'visa': {
        return t('app.constant.payment.visa')
      }
      case 'alipay': {
        return t('app.constant.payment.alipay')
      }
      case 'wechat': {
        return t('app.constant.payment.wechat')
      }
      case 'master': {
        return t('app.constant.payment.master')
      }
      case 'jcb': {
        return t('app.constant.payment.jcb')
      }
      case 'tng': {
        return t('app.constant.payment.tng')
      }
      case 'payme': {
        return t('app.constant.payment.payme')
      }
      default: {
        if (name) return name
        else return type
      }
    }
  }

  if (paymentMethods) {
    paymentOptions = Object.entries(paymentMethods.data).map((payment, index) => {
      const name = setPaymentName(payment[0], payment[1])
      return <Option key={payment[0]} value={payment[0]}>{`${name}`}</Option>
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        width={360}
        height='100%'
        padding='20px'
        borderRadius='0px 0px 0px 0px'
        boxShadow='1px 1px  5px rgba(0, 0, 0, 0.5)'
        textAlign='center'
      >
        <Text className={classes.title}>{t('app.component.filterDialog.desc')}</Text>
        {compare && (
          <Text className={classes.msg}>{t('app.component.filterDialog.reportTimeRange')}</Text>
        )}
        <Text className={classes.msg}>{t('app.component.filterDialog.selectDate')}</Text>
        <Row gutter={40}>
          <Col span={24}>
            <RangePicker
              allowClear={false}
              size='large'
              value={[fromDate, toDate]}
              onChange={onDateChange}
              className={classes.dateTimePicker}
              disabledDate={disabledDate}
              ranges={lang === 'zh-HK' ? rangesZH : rangesEN}
            />
          </Col>
        </Row>
        <Text className={classes.msg}>{t('app.component.filterDialog.selectTime')}</Text>
        <Row gutter={40}>
          <Col span={24}>
            <TimePicker.RangePicker
              allowClear={false}
              size='large'
              format={format}
              minuteStep={60}
              value={[fromTime, toTime]}
              onChange={onTimeChange}
              className={classes.dateTimePicker}
              order={false}
            />
          </Col>
        </Row>
        {compare && (
          <Checkbox checked={compareTime} onChange={onCompareTimeChange}>
            {t('app.component.filterDialog.compareTimeRange')}
          </Checkbox>
        )}
        {compareTime && (
          <div>
            <Text className={classes.msg}>{t('app.component.filterDialog.selectDate')}</Text>
            <Row gutter={40}>
              <Col span={24}>
                <RangePicker
                  allowClear={false}
                  size='large'
                  value={[compareFromDate, compareToDate]}
                  onChange={onCompareDateChange}
                  className={classes.dateTimePicker}
                  disabledDate={disabledDate}
                  ranges={lang === 'zh-HK' ? rangesZH : rangesEN}
                />
              </Col>
            </Row>
            {!skipCompareTime && (
              <>
                <Text className={classes.msg}>{t('app.component.filterDialog.selectTime')}</Text>
                <Row gutter={40}>
                  <Col span={24}>
                    <TimePicker.RangePicker
                      allowClear={false}
                      size='large'
                      format={format}
                      minuteStep={60}
                      value={[compareFromTime, compareToTime]}
                      onChange={onCompareRangeChange}
                      className={classes.dateTimePicker}
                    />
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
        {filter && (
          <div style={{ paddingTop: 15, paddingBottom: 15 }}>
            <Collapse ghost activeKey={filterActiveKey} onChange={onFilterActiveKeyChange}>
              <Panel
                header={t('app.component.filterDialog.advancedFilter')}
                key='1'
                style={{
                  fontSize: 14,
                  textAlign: 'left',
                }}
              >
                {filterSerial && (
                  <Space>
                    <Text className={classes.msg}>{t('app.constant.order.orderNumber')}</Text>
                    <Row gutter={60}>
                      <Col
                        span={11.5}
                        style={{
                          paddingTop: 15,
                          width: 260,
                          marginLeft: 10,
                        }}
                      >
                        <Input
                          placeholder={t('app.constant.order.orderNumber')}
                          onChange={onSerialChange}
                        />
                      </Col>
                    </Row>
                  </Space>
                )}
                {filterTime && (
                  <>
                    <br />
                    <Space>
                      <Text className={classes.filter}>
                        {t('app.component.filterDialog.timeRange')}
                      </Text>
                      <Row gutter={33} style={{ marginLeft: 22 }}>
                        <Col span={22}>
                          <TimePicker.RangePicker
                            allowClear={false}
                            size='middle'
                            format={format}
                            minuteStep={60}
                            value={[filterFromTime, filterToTime]}
                            onChange={onFilterTimeChange}
                            className={classes.filterPicker}
                          />
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
                {filterOrderType && (
                  <>
                    <br />
                    <Space>
                      <Text className={classes.msg}>{t('app.constant.order.orderType')}</Text>
                      <Row gutter={60}>
                        <Col span={11.5} style={{ paddingTop: 15 }}>
                          <Select
                            value={orderType}
                            style={{ width: 200, marginLeft: 11 }}
                            onChange={onOrderTypeChange}
                          >
                            <Option value='all'>{t('app.common.all')}</Option>
                            <Option value='table'>{t('app.constant.deliveryType.table')}</Option>
                            <Option value='merchantTakeaway'>
                              {t('app.constant.deliveryType.merchantTakeaway')}
                            </Option>
                            <Option value='dimorderTakeaway'>
                              {t('app.constant.deliveryType.dimorderTakeaway')}
                            </Option>
                            <Option value='dimorderStoreDelivery'>
                              {t('app.constant.deliveryType.dimorderStoreDelivery')}
                            </Option>
                          </Select>
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
                {filterCustomers && (
                  <>
                    <br />
                    <Space>
                      <Text className={classes.msg}>{t('app.common.peopleCount')}</Text>
                      <Row gutter={20} style={{ paddingLeft: 33 }}>
                        <Col span={10} style={{ paddingTop: 15 }}>
                          <InputNumber
                            min={0}
                            max={20}
                            value={minCustomer}
                            onChange={onMinCustomerChange}
                            size='middle'
                          />
                        </Col>
                        <Text className={classes.filter} style={{ paddingTop: 15 }}>
                          ~
                        </Text>
                        <Col span={10} style={{ paddingTop: 15 }}>
                          <InputNumber
                            min={0}
                            max={1000}
                            value={maxCustomer}
                            onChange={onMaxCustomerChange}
                            size='middle'
                          />
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
                {filterCategory && (
                  <>
                    <br />
                    <CategorySelector setCategory_ids={setCategoryIds} />
                  </>
                )}
                {filterPayment && (
                  <>
                    <br />
                    <Space>
                      <Text className={classes.msg}>
                        {t('app.component.filterDialog.paymentMethod')}
                      </Text>
                      <Row gutter={60}>
                        <Col span={11.5} style={{ paddingTop: 15 }}>
                          <Select
                            value={payment}
                            style={{ width: 200, marginLeft: 11 }}
                            onChange={onPaymentChange}
                          >
                            <Option value='all'>{t('app.common.all')}</Option>
                            {paymentOptions}
                          </Select>
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
                {filterDataInterval && (
                  <>
                    <br />
                    <Space>
                      <Text className={classes.msg}>
                        {t('app.component.filterDialog.groupBy.title')}
                      </Text>
                      <Row gutter={60}>
                        <Col span={11.5} style={{ paddingTop: 15 }}>
                          <Select
                            value={groupBy}
                            style={{ width: 200, marginLeft: 11 }}
                            onChange={onGroupByChange}
                          >
                            <Option value='day'>
                              {t('app.component.filterDialog.groupBy.day')}
                            </Option>
                            <Option value='week'>
                              {t('app.component.filterDialog.groupBy.week')}
                            </Option>
                            <Option value='month'>
                              {t('app.component.filterDialog.groupBy.month')}
                            </Option>
                            <Option value='year'>
                              {t('app.component.filterDialog.groupBy.year')}
                            </Option>
                          </Select>
                        </Col>
                      </Row>
                    </Space>
                  </>
                )}
              </Panel>
            </Collapse>
          </div>
        )}
        <Divider style={{ borderColor: colors.dividerDark }} />
        <Space align='center' direction='horizontal'>
          <Button className={classes.btn} size='middle' onClick={reset}>
            {t('app.common.reset')}
          </Button>
          <Button className={classes.btn2} size='middle' onClick={onSubmit}>
            {t('app.common.submit')}
          </Button>
        </Space>
      </Card>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: colors.textSecondary,
    textAlign: 'left',
  },
  dateTimePicker: {
    width: 300,
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
  filterPicker: {
    width: 200,
  },
  msg: {
    fontSize: 14,
    paddingTop: 10,
    paddingLeft: 10,
    textAlign: 'left',
  },
  filter: {
    fontSize: 14,
    textAlign: 'left',
    paddingLeft: 10,
  },
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
    marginRight: 20,
    alignSelf: 'right',
  },
  btn2: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    marginRight: 20,
    alignSelf: 'right',
  },
  extra: {
    fontSize: 14,
    marginTop: 7,
  },
}))
