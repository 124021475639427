import styled from 'styled-components'

import Tag from '@/components/Tag'

const Box = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : 'rgba(0, 0, 0, 0.05) 5px 5px 5px')};
  border-style: ${(props) => (props.borderStyle ? props.borderStyle : 'none')};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '10px')};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : 'none')};
  border-color: ${(props) => (props.borderColor ? props.borderColor : 'none')};
`

const ColorWrap = styled(Box)`
  background-color: ${(props) => (props.color ? props.color : 'rgba(255, 255, 255, 0.0)')};
`

const Div = styled(Box)`
  background-color: #fff;
  padding: ${(props) => (props.padding ? props.padding : '10px')};

  position: relative;
  top: 7px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
`

const AbsoluteTag = styled(Tag)`
  position: absolute;
  top: -7px;
  right: 0px;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '10px')};
`

export default function Card(props) {
  const {
    width,
    height,
    color,
    tag,
    textAlign,
    padding,
    boxShadow,
    borderRadius,
    borderWidth,
    borderColor,
    borderStyle,
  } = props
  const { children } = props

  return (
    <ColorWrap width={width} height={height} color={color} borderRadius={borderRadius}>
      <Div
        width={width}
        height={height}
        textAlign={textAlign}
        padding={padding}
        boxShadow={boxShadow}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderColor={borderColor}
        borderStyle={borderStyle}
      >
        {tag && <AbsoluteTag {...tag} />}
        {children}
      </Div>
    </ColorWrap>
  )
}
