import { createStore as _createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'

import rootReducer from './reducer'

const middleware = [ReduxThunk]

export const createStore = () =>
  _createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(...middleware)))

const store = createStore()

export default store
