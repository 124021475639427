import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IAppState} */
export const initialState = {
  isInit: false,
  lang: 'zh-HK',
  adPop: {
    lastPopDate: '',
    visible: false,
  },
}

export default produce((draft, action) => {
  switch (action?.type) {
    case ActionTypes.INIT: {
      draft.isInit = true
      break
    }

    case 'LOGOUT': {
      return initialState
    }

    case ActionTypes.UPDATE_LANG: {
      const { lang } = action.payload
      draft.lang = lang
      break
    }

    case ActionTypes.UPDATE_ADLASTPOPDATE: {
      draft.adPop.lastPopDate = action.payload
      break
    }

    case ActionTypes.POP_AD: {
      draft.adPop.visible = true
      break
    }

    case ActionTypes.CLOSE_AD: {
      draft.adPop.visible = false
      break
    }

    default:
      break
  }
}, initialState)
