import * as _ from 'lodash'

import i18n from '@/i18n'
import customerApi from '@/libs/api/customerApi'
import dimorderApi, { DimorderApi } from '@/libs/api/dimorderApi'
import checkCRMPermission, { parseJwt } from '@/libs/checkCRMPermission'

import actions from '../actions'
import ActionTypes from './ActionTypes'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init(customerInfo) {
  return async (dispatch, getState) => {
    if (customerInfo?.data?.platforms) {
      await dispatch(fetchMerchants(customerInfo))

      // 如果 localStorage 有 activeMerchant 就 active 這個 merchant 否則 active 第一個
      const activeMerchantId = localStorage.getItem('activeMerchant')
      if (activeMerchantId) {
        await dispatch(activateMerchantById(activeMerchantId))
      } else {
        const firstMerchantId = getState().merchant.merchants[0]?.id
        console.log('init active merchant', firstMerchantId)
        await dispatch(activateMerchantById(firstMerchantId))
      }
      await dispatch(fetchCRMGroups(customerInfo))
    }

    dispatch({
      type: ActionTypes.INIT,
      payload: {},
    })
  }
}

export function fetchCRMGroups() {
  return async (dispatch, getState) => {
    const groups = {}
    const merchants = getState().merchant.merchants

    for (const merchant of merchants) {
      const hasCRMPermission = await checkCRMPermission(merchant.id, merchant.username)
      if (hasCRMPermission) {
        try {
          const group = await dimorderApi.instance(merchant.id).getCRMGroup()
          if (group) {
            // 建立 group 與 merchant dimorder api 的關聯
            const groupDimorderApi = dimorderApi.addGroup(group.id, merchant.id)

            // 取得 merchant 的 printer setting
            const merchantPrinterSetting = await dimorderApi
              .instance(merchant.id)
              .getPrinterSetting()

            const updateMerchantData = {
              ...merchant,
              printerSetting: merchantPrinterSetting,
            }

            // Add group to groups object
            if (!groups[group.id]) {
              const userCount = await groupDimorderApi.getUserCount()
              groups[group.id] = {
                ...group,
                totalUserCount: userCount?.total ?? 0,
                groupMerchants: {
                  [merchant.id]: updateMerchantData,
                },
              }
            } else {
              groups[group.id].groupMerchants[merchant.id] = updateMerchantData
            }
          }
        } catch (error) {
          console.error('fetchCRMGroups error', error)
        }
      }
    }

    dispatch({
      type: ActionTypes.UPDATE_CRM_GROUPS,
      payload: { groups },
    })
  }
}

export function updateCRMGroup(data) {
  return async (dispatch, getState) => {
    const crmGroups = getState().merchant.crmGroups
    const group = crmGroups[data?.id]
    if (_.isEmpty(group)) return
    try {
      dispatch({
        type: ActionTypes.UPDATE_CRM_GROUP,
        payload: { group: { ...group, ...data } },
      })
    } catch (error) {
      console.error('updateCRMGroup error', error)
    }
  }
}

export function fetchMerchants(customerInfo) {
  return async (dispatch, getState) => {
    const merchants = []
    for await (const platform of customerInfo.data.platforms) {
      if (platform.name === 'dimorder-merchant') {
        const merchantItem = await dispatch(addMerchant(platform))
        merchants.push(merchantItem)
      }
    }

    dispatch({ type: ActionTypes.ADD_MERCHANT, payload: merchants })
  }
}

export function activateMerchantById(merchantId) {
  return async (dispatch, getState) => {
    const merchants = getState().merchant.merchants
    const activeMerchant = merchants.find((merchant) => merchant.id === merchantId)
    if (activeMerchant) {
      const categories = await dimorderApi.instance(merchantId).getCategories()

      dispatch({
        type: ActionTypes.ACTIVE_MERCHANT,
        payload: {
          ...activeMerchant,
          categories: Object.entries(categories?.data).map((category) => {
            return {
              id: category[0],
              name: category[1],
            }
          }),
        },
      })
    }
  }
}

export function loginMerchant(params) {
  return async (dispatch, getState) => {
    try {
      const response = await DimorderApi.scopelogin(params)
      const permissions = _.get(response, 'permissions', [])

      if (permissions.includes('admin') || permissions.includes('merchant-portal')) {
        await customerApi.updateMerchant(response.token, response.refresh)
        const accounts = await dispatch(actions.user.getCustomerInfo())
        await dispatch(init(accounts))
      } else {
        throw new Error('!permission')
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export function addMerchant(platform) {
  return async (dispatch, getState) => {
    const { identifier } = parseJwt(platform.access_token)

    /**
     *
     * @param {Error} error
     * @param {string} merchantName
     * @param {string} merchantShortCode
     * @param {string} username
     */
    const refreshFailedCallback = (error, merchantName, merchantShortCode, username) => {
      dispatch(
        actions.merchant.openMerchantLoginDialog({
          data: {
            title: i18n.t('app.component.addMerchantModal.expiredMerchant', { merchantName }),
            merchantShortCode,
            username,
          },
        }),
      )
      console.log('refresh failed', error)
    }

    const apiInstance = dimorderApi.create(
      platform.uid,
      identifier,
      platform.access_token,
      platform.refresh_token,
      refreshFailedCallback,
    )
    const merchantData = await apiInstance.getMerchant()

    const merchantItem = {
      name: merchantData.name,
      id: merchantData.id,
      username: identifier,
      address: merchantData.address,
      image: merchantData.images.cover,
      setting: merchantData.setting,
    }
    return merchantItem
  }
}

/**
 * open the merchant login dialog
 * @typedef OpenMerchantLoginDialogParams
 * @property {object} data
 * @property {string} [data.title='']
 * @property {string} [data.merchantName='']
 * @property {string} [data.username='']
 * @property {string} [data.merchantShortCode='']
 * @property {boolean} [firstLogin=false]
 * @property {() => void} [onSuccess]
 * @param {OpenMerchantLoginDialogParams} params
 * @returns {ThunkFunction}
 */
export function openMerchantLoginDialog({ data, firstLogin = false, onSuccess = () => {} }) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.OPEN_MERCHANT_LOGIN_DIALOG,
      payload: {
        data,
        firstLogin,
        onSuccess,
      },
    })
  }
}

/**
 * control the merchant login dialog close and reset dialog data
 * @returns {ThunkFunction}
 */
export function closeMerchantLoginDialog() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_MERCHANT_LOGIN_DIALOG,
    })
  }
}
