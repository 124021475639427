const config = {
  api: {
    portalLocal: 'http://10.0.0.97:3300',
    customer: 'https://customer-api.dimorder.com',
    dimorder: 'https://api.dimorder.com',
    node: 'https://node.dimorder.com',
  },
  env: 'prod',
  receipt: 'https://mp-order.dimorder.com/',
  report: 'https://report-api.dimorder.com/report/',
}

export default config
