import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Modal, Row, Col, Table, Typography as Text } from 'antd'
import _ from 'lodash'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'

export default function DepartmentReport(props) {
  const { t } = useTranslation()
  const printRef = useRef()
  const classes = useStyle()
  const { Panel } = Collapse
  const { activeMerchant } = useSelector((state) => state.merchant)
  const [activeKey, setActiveKey] = useState('1')
  const [params, setParams] = useState(null)
  const [departmentReports, setDepartmentReports] = useState([])
  const [loading, setLoading] = useState(false)
  const numberOfResults = departmentReports.length

  useEffect(() => {
    const getDepartmentReports = async () => {
      if (!params) return
      setLoading(true)
      const reports = []
      try {
        const departmentReports = await dimorderApi
          .instance(activeMerchant.id)
          ?.getKitchenDepartmentReports(params)
        for (const department of departmentReports.data) {
          reports.push({
            key: department.id,
            department: department.name,
            quantity: department.count,
            totalSales: department.total,
          })
        }
        setDepartmentReports(reports)
      } catch (error) {
        console.log(error)
        Modal.error({
          title: t('app.common.error'),
          content: t('app.common.submitError'),
        })
      } finally {
        setLoading(false)
        setActiveKey('0')
      }
    }

    getDepartmentReports()
  }, [params, t, activeMerchant])

  const departmentData = useMemo(() => {
    const columns = [
      {
        title: t('page.departmentReport.department'),
        dataIndex: 'department',
      },
      {
        title: t('page.departmentReport.quantity'),
        dataIndex: 'quantity',
        sorter: (a, b) => a.quantity - b.quantity,
      },
      {
        title: t('page.departmentReport.totalSales'),
        dataIndex: 'totalSales',
        sorter: (a, b) => a.totalSales - b.totalSales,
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
      },
    ]

    return { columns, data: departmentReports }
  }, [departmentReports])

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <br />
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={departmentData.columns}
            dataSource={departmentData.data}
            summary={() => {
              const totalSalesOverall = _.sumBy(departmentReports, 'totalSales')
              const quantityTotal = _.sumBy(departmentReports, 'quantity')

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <Text className={classes.totalText}>{t('page.departmentReport.total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text className={classes.totalText}>{quantityTotal}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text className={classes.totalText}>
                      {currencyWithCommas(totalSalesOverall)}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }}
          />
        </Card>
        <br />
        <Text className={classes.msg}>{t('page.departmentReport.remark')}</Text>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.departmentReport.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <br />
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={departmentData.columns}
              dataSource={departmentData.data}
              pagination={false}
              summary={() => {
                const totalSalesOverall = _.sumBy(departmentReports, 'totalSales')
                const quantityTotal = _.sumBy(departmentReports, 'quantity')

                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.departmentReport.total')}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{quantityTotal}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalSalesOverall)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
          <br />
          <Text className={classes.msg}>{t('page.departmentReport.remark')}</Text>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportKitchentDepartmentReportCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.departmentReport.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} momentFormatX />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {!loading && params && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyle = makeStyles(() => ({
  msg: {
    fontSize: 14,
    paddingTop: 10,
    paddingLeft: 10,
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
    alignItems: 'center',
  },
}))
