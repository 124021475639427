import { CaretDownFilled, CaretUpFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row, Table, Tooltip, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { Bar } from '@/components/chart'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

const salesItemBarChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  maxBarThickness: 80,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      title: {
        display: true,
        text: '售出數量',
        font: {
          size: 14,
        },
      },
    },
  },
}

export default function SalesReportByItem(props) {
  const { t } = useTranslation()
  const printRef = useRef()
  const classes = useStyles(props)
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [salesReportByItem, setSalesReportByItem] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  React.useEffect(() => {
    setParams(null)
    setSalesReportByItem(null)
    setActiveKey('1')
  }, [activeMerchant])

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const salesReportByItem = await dimorderApi
            .instance(activeMerchant.id)
            ?.getSalesReportByItem(params)
          setSalesReportByItem(salesReportByItem)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t])

  React.useEffect(() => {
    setNumberOfResults(salesReportByItem?.items?.length)
  }, [salesReportByItem])

  const salesItemBarChartData = {
    labels: [],
    datasets: [],
  }

  salesReportByItem?.items?.map((item) => {
    return salesItemBarChartData.labels.push(item.name)
  })

  salesItemBarChartData.datasets.push({
    label: t('page.salesReportByItem.reportTimeRange'),
    data: salesReportByItem?.items?.map((item) => {
      return item.quantitySold.main
    }),
    backgroundColor: colors.data1,
  })

  if (params?.compare) {
    salesItemBarChartData.datasets.push({
      label: t('page.salesReportByItem.compareTimeRange'),
      data: salesReportByItem?.items?.map((item) => {
        return item.quantitySold.compare
      }),
      backgroundColor: colors.data3,
    })
  }

  const salesItemTableData = {
    columns: [
      {
        title: t('page.salesReportByItem.productName'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t('page.salesReportByItem.category'),
        dataIndex: 'category',
        sorter: (a, b) => a.category.localeCompare(b.category),
      },
      {
        title: t('page.salesReportByItem.currentPrice'),
        dataIndex: 'price',
      },
      {
        title: t('page.salesReportByItem.salesCount'),
        dataIndex: 'quantity',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.quantity.count - b.quantity.count,
        render: (quantity) => (
          <span>
            {params?.compare ? (
              quantity.countDifference >= 0 ? (
                quantity.countDifference === 0 ? (
                  <Text>
                    {quantity.count} ( {quantity.countDifference} )
                  </Text>
                ) : (
                  <Text>
                    {quantity.count} ( {quantity.countDifference}{' '}
                    <CaretUpFilled style={{ color: colors.success }} /> )
                  </Text>
                )
              ) : (
                <Text>
                  {quantity.count} ( {-quantity.countDifference}{' '}
                  <CaretDownFilled style={{ color: colors.error }} /> )
                </Text>
              )
            ) : (
              <Text>{quantity.count}</Text>
            )}
          </span>
        ),
      },
      {
        title: (
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {t('page.salesReportByItem.sales')}
            <Tooltip title={t('page.salesReportByItem.salesDesc')}>
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'sales',
        sorter: (a, b) => a.sales.amount - b.sales.amount,
        render: (sales) => (
          <span>
            {params?.compare ? (
              sales.amountDifferencePercentage >= 0 ? (
                sales.amountDifferencePercentage === 0 ? (
                  <Text>{currencyWithCommas(sales.amount)}</Text>
                ) : (
                  <Text>
                    {currencyWithCommas(sales.amount)} ( {sales.amountDifferencePercentage}%{' '}
                    <CaretUpFilled style={{ color: colors.success }} /> )
                  </Text>
                )
              ) : (
                <Text>
                  {currencyWithCommas(sales.amount)} ( {-sales.amountDifferencePercentage}%{' '}
                  <CaretDownFilled style={{ color: colors.error }} /> )
                </Text>
              )
            ) : (
              <Text>{currencyWithCommas(sales.amount)}</Text>
            )}
          </span>
        ),
      },
    ],
    data: [],
  }

  if (!params?.compare) {
    salesItemTableData.columns.push({
      title: t('app.constant.order.discount'),
      dataIndex: 'discount',
      render: (discount) => <Text>{currencyWithCommas(discount)}</Text>,
      sorter: (a, b) => a.discount - b.discount,
    })
  }

  if (params?.compare) {
    salesItemTableData.columns.push({
      title: t('page.salesReportByItem.salesDifference'),
      dataIndex: 'salesDifference',
      sorter: (a, b) => a.salesDifference - b.salesDifference,
      render: (salesDifference) =>
        salesDifference >= 0 ? (
          salesDifference === 0 ? (
            <Text>{currencyWithCommas(salesDifference)}</Text>
          ) : (
            <Text style={{ color: colors.success }}>{currencyWithCommas(salesDifference)}</Text>
          )
        ) : (
          <Text style={{ color: colors.error }}>{currencyWithCommas(-1 * salesDifference)}</Text>
        ),
    })
  }

  if (salesReportByItem) {
    for (const [key, value] of Object.entries(salesReportByItem?.items)) {
      salesItemTableData.data.push({
        key,
        name: value.name,
        category: value.categoryName,
        price: `${
          Number(value.priceTable) > 0 ? currencyWithCommas(value.priceTable) : value.priceTable
        }  
        /  
        ${
          Number(value.priceTakeaway) > 0
            ? currencyWithCommas(value.priceTakeaway)
            : value.priceTakeaway
        }`,
        quantity: {
          count: value.quantitySold?.main,
          countDifference: value.quantitySold?.change,
        },
        sales: {
          amount: value.sales?.main,
          amountDifferencePercentage: value.sales?.changePercentage,
        },
        discount: value.discount,
        salesDifference: value.sales?.change,
      })
    }
  }

  const displayResult = () => (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.salesReportByItem.itemSalesCount')}</Text>
            <Bar
              data={salesItemBarChartData}
              options={salesItemBarChartOptions}
              height={350}
              width='98%'
            />
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {`${t('page.salesReportByItem.table')} ${moment(params?.start).format(
                'YYYY-MM-DD HH:mm',
              )} ${t('app.common.to')} ${moment(params?.end).format('YYYY-MM-DD HH:mm')} `}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={salesItemTableData.columns}
              dataSource={salesItemTableData.data}
              scroll={{ x: 800 }}
              summary={() => {
                const totalQuantity = salesReportByItem?.totalQuantitySold
                const totalSales = salesReportByItem?.totalSales
                const totalDiscount = salesReportByItem?.totalDiscount
                return (
                  <>
                    {!params?.compare && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {t('page.salesReportByItem.total')}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSales)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalDiscount)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.salesReportByItem.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <>
            <Text className={classes.title}>{t('page.salesReportByItem.itemSalesCount')}</Text>
            <Bar
              data={salesItemBarChartData}
              options={salesItemBarChartOptions}
              height={350}
              width={1170}
            />
          </>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <>
            <Text className={classes.title}>
              {`${t('page.salesReportByItem.table')} ${moment(params?.start).format(
                'YYYY-MM-DD HH:mm',
              )} ${t('app.common.to')} ${moment(params?.end).format('YYYY-MM-DD HH:mm')} `}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={salesItemTableData.columns}
              dataSource={salesItemTableData.data}
              pagination={false}
              summary={() => {
                const totalQuantity = salesReportByItem?.totalQuantitySold
                const totalSales = salesReportByItem?.totalSales
                const totalDiscount = salesReportByItem?.totalDiscount
                return (
                  <>
                    {!params?.compare && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {t('page.salesReportByItem.total')}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalQuantity}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSales)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalDiscount)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportSalesReportByItemCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.salesReportByItem.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog
            compare
            enableOverlap
            filter
            filterTime
            filterOrderType
            filterCustomers
            filterCategory
            setParams={setParams}
          />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {salesReportByItem && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
  },
}))
