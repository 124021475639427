import { Col, Row } from 'antd'
import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'

import SummaryRow from '@/components/SummaryRow'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import colors from '@/style/colors'

export default function SummaryInfoColumn(props) {
  const { data, title } = props
  const { t } = useTranslation()
  const summaryData = [
    {
      title: t('page.dailyClosing.sales'),
      count: data?.data?.sales?.count,
      amount: currencyWithCommas(data?.data?.sales?.amount),
    },
    {
      title: t('page.dailyClosing.surcharge'),
      amount: currencyWithCommas(data?.data?.surcharge),
    },
    {
      title: t('page.dailyClosing.merchantDiscount'),
      count: data?.data?.merchantDiscount?.count,
      amount: `(${currencyWithCommas(-1 * data?.data?.merchantDiscount?.amount)})`,
    },
    {
      title: t('page.dailyClosing.merchantPromocode'),
      count: data?.data?.merchantPromocode?.count ? data?.data?.merchantPromocode?.count : 0,
      amount: `(${currencyWithCommas(-1 * data?.data?.merchantPromocode?.amount)})`,
    },
    {
      title: t('page.dailyClosing.cancelledOrder'),
      count: data?.data?.cancelled?.count,
      amount: `(${currencyWithCommas(-1 * data?.data?.cancelled?.amount)})`,
      textStyle: { color: colors.textTertiary },
    },
    {
      title: t('app.constant.order.totalSales'),
      amount: currencyWithCommas(data?.data?.totalSales),
      textStyle: { color: colors.textHighlight },
    },
    {
      title: t('page.dailyClosing.tips'),
      amount: currencyWithCommas(data?.data?.tips),
    },
    {
      title: t('page.dailyClosing.roundingDiscrepancy'),
      amount:
        data?.data?.difference >= 0
          ? currencyWithCommas(data?.data?.difference)
          : `(${currencyWithCommas(Math.abs(data?.data?.difference))})`,
    },
    {
      title: t('page.dailyClosing.totalIncome'),
      amount: currencyWithCommas(data?.data?.revenue),
      textStyle: { color: colors.textHighlight },
    },
    {
      title: t('page.dailyClosing.avgPerGuest'),
      amount: currencyWithCommas(data?.data?.avgPerGuest),
    },
    /* { Wait API update
      title: '平均訂單銷售額',
      amount: '$116.8',
    }, */
    {
      title: t('page.dailyClosing.guestCount'),
      amount: data?.data?.guest ? data?.data?.guest : '0',
    },
  ]

  const renderTotalItem = (item) => {
    switch (data?.type) {
      case 'sales': {
        if (item.title === t('page.dailyClosing.avgPerGuest')) return null
        if (item.title === t('page.dailyClosing.guestCount')) return null
        if (item.title === t('page.dailyClosing.merchantPromocode')) return null
        break
      }
      case 'table': {
        if (item.title === t('page.dailyClosing.merchantPromocode')) return null
        break
      }
      case 'merchantTakeaway': {
        if (item.title === t('page.dailyClosing.surcharge')) return null
        if (item.title === t('page.dailyClosing.merchantPromocode')) return null
        if (item.title === t('page.dailyClosing.avgPerGuest')) return null
        if (item.title === t('page.dailyClosing.guestCount')) return null
        break
      }
      case 'dimOrderTakeaway': {
        if (item.title === t('page.dailyClosing.surcharge')) return null
        if (item.title === t('page.dailyClosing.merchantDiscount')) return null
        if (item.title === t('page.dailyClosing.avgPerGuest')) return null
        if (item.title === t('page.dailyClosing.guestCount')) return null
        if (item.title === t('page.dailyClosing.tips')) return null
        break
      }
      case 'dimOrderStoreDelivery': {
        if (item.title === t('page.dailyClosing.surcharge')) return null
        if (item.title === t('page.dailyClosing.merchantDiscount')) return null
        if (item.title === t('page.dailyClosing.avgPerGuest')) return null
        if (item.title === t('page.dailyClosing.guestCount')) return null
        if (item.title === t('page.dailyClosing.tips')) return null
        break
      }
      default: {
        break
      }
    }
    return (
      <SummaryRow
        title={item.title}
        count={item.count}
        amount={item.amount}
        textStyle={item.textStyle}
      />
    )
  }

  return (
    <Row style={{ display: 'flex', justifyContent: 'center' }}>
      <Col span={24}>
        <SummaryRow
          title={title}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 10 }}
        />
        <FlatList list={summaryData} renderItem={renderTotalItem} />
      </Col>
    </Row>
  )
}
