import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Row } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import OrderSubmitted from './OrderSubmitted'
import OrderingForm from './OrderingForm'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
})

export default function DimSupplyOrdering(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const [submitted, setSubmitted] = useState(false)

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.dimSupplyOrdering.title'),
      }}
    >
      <Row className={classes.container}>
        <Col xs={24} xl={20}>
          {submitted && <OrderSubmitted setSubmitted={setSubmitted} />}
          {!submitted && <OrderingForm setSubmitted={setSubmitted} />}
        </Col>
      </Row>
    </PageContainer>
  )
}
