import { Bar } from 'react-chartjs-2'

export default function BarChart(props) {
  const { width, height, ...other } = props

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width,
        height,
      }}
    >
      <Bar {...other} />
    </div>
  )
}
