import { MailOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons'
import ProForm, { ProFormCaptcha, ProFormText } from '@ant-design/pro-form'
import { Button, Col, ConfigProvider, Input, Modal, Row, message } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhHK from 'antd/lib/locale/zh_HK'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Card from '@/components/Card'
import LanguageOption from '@/components/LanguageOption'
import Loading from '@/components/Loading'
import customerApi from '@/libs/api/customerApi'
import logger, { logId } from '@/libs/logger'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

export default function Auth(props) {
  const { t } = useTranslation()
  const [registerUserName, setRegisterUserName] = useState(false)
  const { isLogin } = useSelector((state) => state.user)
  const { isInit } = useSelector((state) => state.app)
  const [username, setUserName] = useState()
  const [initialized, setInitialized] = useState(false)
  const [usernameLoading, setUsernameLoading] = useState(false)
  const locale = useSelector((state) => state.app.lang)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const from = query.get('from')
  const code = query.get('code')

  const dispatch = useDispatch()

  // reset state on login success
  const reset = () => {
    setRegisterUserName(false)
    setUserName(null)
    setUsernameLoading(false)
  }

  const loginMerchant = () => {
    return dispatch(
      actions.merchant.openMerchantLoginDialog({
        data: {
          title: t('app.component.merchant.addMerchant'),
        },
        firstLogin: true,
        onSuccess: reset,
      }),
    )
  }

  const onLogin = useCallback(
    async (value, isKConnect = false) => {
      try {
        let responseData
        if (isKConnect) {
          responseData = await customerApi.kconnectLogin(value)
        } else {
          responseData = await customerApi.login(`+852${value.phone}`, value.otp)
        }
        dispatch(actions.user.updateCustomerApiAuth(responseData.data))
        const accountData = await dispatch(actions.user.getCustomerInfo())
        await dispatch(actions.merchant.init(accountData))
        if (checkEmptyMerchants(accountData)) {
          setRegisterUserName(true)
        } else {
          dispatch(actions.user.login())
          dispatch(actions.merchant.closeMerchantLoginDialog())
          reset()
        }
      } catch (error) {
        logger.log('[login] Login Error', { error })
        Modal.error({
          title: `${t('page.auth.error.otp.input.title')} (${logId})`,
          content: t('page.auth.error.otp.input.msg'),
        })
      }
    },
    [dispatch, t],
  )

  useEffect(() => {
    if (from === 'kconnect' && !initialized) {
      setInitialized(true)
      onLogin(code, true)
    }
  }, [initialized, from, onLogin, code])

  useEffect(() => {
    if (!isInit) {
      dispatch(actions.app.init())
    }
  }, [dispatch, isInit])

  const onUserNameChange = (name) => {
    setUserName(name.target.value)
  }

  const checkEmptyMerchants = (accountData) => {
    let result = true
    accountData.data.platforms.forEach((account) => {
      if (account.name === 'dimorder-merchant') {
        result = false
      }
    })
    return result
  }

  function validateUserName(name) {
    const MAX_NAME_BYTE = 20
    const regexpLA = /[a-zA-z ]/
    const regexpCJK = /[\u4E00-\u9FFF\u3400-\u4DBF]/

    if (!name) {
      message.error(t('app.component.userInfoModal.error.name.empty'))
      return false
    }

    if (name.length > MAX_NAME_BYTE) {
      message.error(
        t('app.component.userInfoModal.error.name.length', {
          cjk: MAX_NAME_BYTE / 2,
          la: MAX_NAME_BYTE,
        }),
      )
      return false
    }

    let byte = 0
    const invalidChars = []
    for (const char of name) {
      if (regexpLA.test(char)) {
        byte += 1
      } else if (regexpCJK.test(char)) {
        byte += 2
      } else {
        invalidChars.push(char)
      }
    }

    if (invalidChars.length > 0) {
      message.error(t('app.component.userInfoModal.error.name.regex'))
      return false
    }

    if (byte > MAX_NAME_BYTE) {
      message.error(
        t('app.component.userInfoModal.error.name.length', {
          cjk: MAX_NAME_BYTE / 2,
          la: MAX_NAME_BYTE,
        }),
      )
      return false
    }

    return true
  }

  const handleUsernameSubmit = async () => {
    if (!validateUserName(username)) {
      return
    }
    setUsernameLoading(true)
    await dispatch(actions.user.updateCustomerInfo({ name: username }))
    setUsernameLoading(false)
    loginMerchant()
  }

  if (!isInit) return <Loading />

  return (
    <ConfigProvider locale={locale === 'zh-HK' ? zhHK : enUS}>
      {isLogin ? (
        props.children
      ) : (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: '2em',
          }}
        >
          {' '}
          <Col xl={8} xs={24}>
            <Card
              color={colors.white}
              width='100%'
              height='100%'
              textAlign='center'
              padding='4em 2em 12em'
            >
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  style={{
                    height: '70px',
                  }}
                  alt='logo'
                  src='https://img.dimorder.com/414x/public/Landing_Banner/logo/dimpos-v2.png'
                />
              </span>
              <h1
                style={{
                  textAlign: 'center',
                  marginTop: '1em',
                  marginBottom: '1em',
                  fontSize: 35,
                }}
              >
                {t('page.auth.title')}
              </h1>
              {!registerUserName && (
                <ProForm
                  onFinish={onLogin}
                  submitter={{
                    searchConfig: {
                      submitText: t('page.auth.login'),
                    },
                    render: (_, dom) => dom.pop(),
                    submitButtonProps: {
                      size: 'large',
                      style: {
                        width: '100%',
                      },
                    },
                  }}
                >
                  <ProFormText
                    fieldProps={{
                      size: 'large',
                      prefix: <MobileOutlined />,
                      maxLength: 8,
                    }}
                    name='phone'
                    placeholder={t('page.auth.input.phone')}
                    rules={[
                      {
                        required: true,
                        message: t('page.auth.error.phone.empty'),
                      },

                      {
                        pattern: /^\d{8}$/,
                        message: t('page.auth.error.phone.format'),
                      },
                    ]}
                  />
                  <ProFormCaptcha
                    fieldProps={{
                      size: 'large',
                      prefix: <MailOutlined />,
                    }}
                    captchaProps={{
                      size: 'large',
                    }}
                    captchaTextRender={(timing, count) => {
                      if (timing) {
                        return `${count} ${t('page.auth.otp.send')}`
                      }
                      return t('page.auth.otp.send')
                    }}
                    phoneName='phone'
                    name='otp'
                    rules={[
                      {
                        required: true,
                        message: t('page.auth.error.otp.empty'),
                      },
                    ]}
                    placeholder={t('page.auth.input.otp')}
                    onGetCaptcha={async (phone) => {
                      try {
                        await customerApi.sendOtp(`+852${phone}`) // TODO: dynamic country code
                        await message.success(`${t('page.auth.message.otp.success')}${phone}!`)
                      } catch (error) {
                        Modal.error({
                          title: t('page.auth.error.otp.send.title'),
                          content: t('page.auth.error.otp.send.msg'),
                        })
                      }
                    }}
                  />
                </ProForm>
              )}
              {registerUserName && (
                <>
                  <Input
                    prefix={<UserOutlined className='site-form-item-icon' />}
                    value={username}
                    onChange={onUserNameChange}
                    size='large'
                    style={{ width: '100%', marginTop: 20 }}
                    allowClear
                    placeholder={t('page.auth.input.username')}
                  />
                  <Button
                    style={{
                      width: '100%',
                      marginTop: 30,
                      marginBottom: 20,
                      background: colors.textPrimary,
                      borderColor: colors.textPrimary,
                      color: colors.white,
                    }}
                    size='large'
                    onClick={handleUsernameSubmit}
                    loading={usernameLoading}
                  >
                    {t('app.common.submit')}
                  </Button>
                </>
              )}
              <br />
              <LanguageOption position='auth' />
            </Card>
          </Col>
        </Row>
      )}
    </ConfigProvider>
  )
}
