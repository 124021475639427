import { Spin } from 'antd'

export default function Loading() {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Spin size='large' style={{ position: 'absolute', top: '45%', fontSize: 50 }} />
    </div>
  )
}
