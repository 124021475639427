import { Image } from 'antd'

const GROUP_IMAGE = { height: 300 }

export default function GroupImage(props) {
  const { url } = props
  return (
    <div style={{ marginBottom: 8 }}>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: GROUP_IMAGE.height,
          backgroundImage: `url(${url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          filter: 'blur(5px)',
        }}
      />
      {url ? (
        <Image
          preview={false}
          height={GROUP_IMAGE.height}
          width='100%'
          style={{
            position: 'absolute',
            objectFit: 'contain',
          }}
          src={url}
        />
      ) : (
        <div style={{ height: GROUP_IMAGE.height, backgroundColor: 'lightgray' }} />
      )}
    </div>
  )
}
