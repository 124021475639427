import { Doughnut } from 'react-chartjs-2'

export default function DoughnutChart(props) {
  const { width, height, flex, ...other } = props

  return (
    <div style={{ flex, width, height }}>
      <Doughnut {...other} />
    </div>
  )
}
