import { makeStyles } from '@material-ui/styles'
import { Image } from 'antd'

const IMAGE_URL = 'https://img.dimorder.com/414x/public/Landing_Banner/logo/dimpos-v2.png'

export default function ReportLogoHeader(props) {
  const classes = useStyles(props)

  return (
    <header className={classes.header}>
      <Image src={IMAGE_URL} height={66} width={150} preview={false} />
    </header>
  )
}

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: 8,
  },
}))
