import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography as Text,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ReportCard from '@/components/ReportCard'
import { products } from '@/constants/dimSupplyProducts'
import dimorderApi from '@/libs/api/dimorderApi'
import { countWorkingdays } from '@/libs/date'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

const { Option, OptGroup } = Select

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    margin: '5px 0px',
  },
  topRemark: {
    fontSize: 16,
  },
  remark: {
    fontSize: 16,
    color: colors.textSecondary,
  },
  qtyContainer: {
    display: 'flex',
    height: 32,
    marginBottom: 24,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  qtyText: {
    marginLeft: 5,
    lineHeight: '32px',
  },
  totalText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  btn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    width: '80%',
    fontSize: 15,
    height: 35,
  },
})

export default function OrderingForm(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [form] = useForm()
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const deliveryAddress = useSelector((state) => state.merchant.activeMerchant?.address)
  const workingDaysToAdd = 3
  const daysToAdd = countWorkingdays(moment().startOf('day'), workingDaysToAdd)
  const [loading, setLoading] = useState(false)
  const [unitTexts, setUnitTexts] = useState([])
  const [itemPrices, setItemPrices] = useState([])
  const [itemQtys, setItemQtys] = useState([])
  const [fields, setFields] = useState([
    { name: ['items'], value: [{ id: '', quantity: 1 }] },
    {
      name: ['pickupDate'],
      value: moment().startOf('day').add(daysToAdd, 'days'),
    },
    { name: ['customerName'], value: '' },
    { name: ['phone'], value: '' },
  ])

  const optionCategoryLabelMap = {
    GR: '以下 MOQ 2 包',
    TL: '以下 MOQ $500',
    GN: '以下 MOQ 4 件',
    GN_addOn: 'GN 加購項目',
  }
  const optionGroups = Object.values(
    products.reduce((a, c) => {
      if (!a[c.optionCategory]) {
        a[c.optionCategory] = {
          label: optionCategoryLabelMap[c.optionCategory],
          values: [c],
        }
      } else {
        a[c.optionCategory].values.push(c)
      }
      return a
    }, {}),
  )

  const paymentOptions = [
    {
      label: t('page.dimSupplyOrdering.orderForm.paymentOptions.statement'),
      value: 'deduct',
    },
    {
      label: t('page.dimSupplyOrdering.orderForm.paymentOptions.payme'),
      value: 'payme',
    },
    {
      label: t('page.dimSupplyOrdering.orderForm.paymentOptions.bankTransfer'),
      value: 'bankTransfer',
    },
    {
      label: t('page.dimSupplyOrdering.orderForm.paymentOptions.fps'),
      value: 'fps',
    },
    {
      label: t('page.dimSupplyOrdering.orderForm.paymentOptions.other'),
      value: 'others',
    },
  ]

  const validateMessages = {
    required: t('page.dimSupplyOrdering.orderForm.error.required'),
  }

  const disableDate = (current) => {
    if ([6, 0].includes(current.startOf('day').day())) return true

    const tooEarly = current.startOf('day') < moment().startOf('day').add(daysToAdd, 'days')
    return tooEarly
  }

  const getTotalPrice = (indexes = []) => {
    let totalPrice = 0
    for (let i = 0; i < itemQtys.length; i++) {
      if (indexes.length && !indexes.includes(i)) continue
      totalPrice += itemQtys[i] * itemPrices[i]
    }
    return totalPrice
  }

  const resetForm = () => {
    setFields([
      { name: ['items'], value: [''] },
      { name: ['pickupDate'], value: moment().add(1, 'day') },
      { name: ['customerName'], value: '' },
      { name: ['phone'], value: '' },
    ])
  }

  const handleFinish = async (values) => {
    const items = []
    values.items.forEach((item) => {
      const product = products.find((p) => p.sku === item.id)
      items.push({
        name: product.name,
        quantity: item.quantity,
        price: product.price,
        sku: product.sku,
      })
    })
    const params = {
      name: values.customerName,
      items,
      paymentMethod: values.paymentMethod,
      pickupAt: moment(values.pickupDate).startOf('day'),
      phone: values.phone,
      deliveryAddress,
    }
    try {
      setLoading(true)
      await dimorderApi.instance(activeMerchant.id)?.dimSupplyOrdering(params)
      setLoading(false)
      props.setSubmitted(true)
      resetForm()
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('page.dimSupplyOrdering.orderForm.error.submit.title'),
        content: t('page.dimSupplyOrdering.orderForm.error.submit.content'),
      })
      setLoading(false)
    }
  }

  const renderMinusCircle = (name, remove, index) => (
    <MinusCircleOutlined
      onClick={() => {
        setUnitTexts((prevState) => prevState.filter((_v, index) => index !== name))
        setItemPrices((prevState) => prevState.filter((_v, index) => index !== name))
        setItemQtys((prevState) => prevState.filter((_v, index) => index !== name))
        remove(index)
      }}
      style={{ marginTop: 8, fontSize: 16 }}
    />
  )

  return (
    <ReportCard>
      <Text className={classes.topRemark}>
        <a
          target='_blank'
          href='https://docs.google.com/spreadsheets/d/16VxTcVlONbO8Rnwg3I7YepLfNohduAm6-6vTsXRrnCc/edit?usp=sharing'
          rel='noreferrer'
        >
          {t('page.dimSupplyOrdering.orderForm.topRemark')}
        </a>
      </Text>
      <Form
        name='colaOrderingForm'
        form={form}
        onFinish={handleFinish}
        validateMessages={validateMessages}
        fields={fields}
        initialValues={{ items: [''] }}
        onChange={(_, allFields) => {
          setFields(allFields)
        }}
      >
        <Text className={classes.title}>{t('page.dimSupplyOrdering.orderForm.title')}</Text>
        <div style={{ padding: 10, paddingBottom: 25 }}>
          <Row style={{ marginTop: 8 }}>
            <Col xl={14} md={0} xs={0}>
              <Text className={classes.qtyText}>{t('page.dimSupplyOrdering.orderForm.item')}</Text>
            </Col>
            <Col xl={5} md={0} xs={0}>
              <Text className={classes.qtyText}>{t('page.dimSupplyOrdering.orderForm.qty')}</Text>
            </Col>
            <Col xl={4} md={0} xs={0}>
              <Text className={classes.qtyText}>
                {t('page.dimSupplyOrdering.orderForm.unitPrice')}
              </Text>
            </Col>
          </Row>
          <Form.List name='items'>
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 20,
                }}
              >
                {fields.map(({ fieldKey, name, ...restProps }, index) => {
                  console.debug('fields', fields)
                  return (
                    <Row key={index} style={{ paddingBottom: 15 }}>
                      <Col xl={0} md={2} xs={23}>
                        <Text className={classes.qtyText}>
                          {t('page.dimSupplyOrdering.orderForm.item')}
                        </Text>
                      </Col>
                      <Col xl={14} md={21} xs={23} style={{ paddingRight: 35, height: 40 }}>
                        <Form.Item
                          {...restProps}
                          name={[index, 'id']}
                          fieldKey={[fieldKey, 'id']}
                          validateTrigger={[]}
                          rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                              validator: (_, value) => {
                                const itemSKU = getFieldValue().items[index]?.id
                                const product = products.find((p) => p.sku === itemSKU)
                                const optionCategory = product?.optionCategory
                                // check if TL items fulfilled the minimum amount $500
                                if (optionCategory === 'TL') {
                                  // Find the index of all TL items
                                  const itemsIndex = getFieldValue().items.flatMap(
                                    (product, index) => {
                                      const item = products.find((p) => p.sku === product?.id)
                                      const itemOptionCategory = item?.optionCategory
                                      return itemOptionCategory === 'TL' ? index : []
                                    },
                                  )
                                  // count the total amount of TL items
                                  const totalPrice = getTotalPrice(itemsIndex)
                                  if (totalPrice < 500) {
                                    return Promise.reject(
                                      new Error(
                                        t('page.dimSupplyOrdering.orderForm.error.minPrice_TL'),
                                      ),
                                    )
                                  }
                                }
                                // check if GN items fulfilled the minimum quantity 4
                                if (optionCategory?.startsWith('GN')) {
                                  const addOnItems = ['GN-214', 'GN-215', 'GN-216']
                                  // Find the quantity of all GN items except addOnItems
                                  const itemsQuantity = getFieldValue().items.flatMap((product) => {
                                    const item = products.find((p) => p.sku === product.id)
                                    const itemOptionCategory = item?.optionCategory
                                    return itemOptionCategory === 'GN' &&
                                      !addOnItems.includes(product.id)
                                      ? product.quantity
                                      : []
                                  })
                                  // count the total amount of TL items
                                  const totalItem = itemsQuantity.reduce((a, b) => a + b, 0)
                                  if (totalItem < 4) {
                                    if (addOnItems.includes(itemSKU)) {
                                      // cannot buy add-on items if GN items < 4
                                      return Promise.reject(
                                        new Error(
                                          t('page.dimSupplyOrdering.orderForm.error.addOn_GN'),
                                        ),
                                      )
                                    } else {
                                      // cannot buy GN items if GN items < 4
                                      return Promise.reject(
                                        new Error(
                                          t('page.dimSupplyOrdering.orderForm.error.minQty_GN'),
                                        ),
                                      )
                                    }
                                  }
                                }
                                return Promise.resolve()
                              },
                            }),
                          ]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            dropdownMatchSelectWidth={false}
                            onChange={(item) => {
                              const product = products.find((p) => p.sku === item)
                              const unitText = product.unit
                              const price = product.price
                              if (unitTexts.length > 0 && name < unitTexts.length) {
                                setUnitTexts((prevState) => {
                                  const unitTexts = [...prevState]
                                  unitTexts[name] = unitText
                                  return unitTexts
                                })
                                setItemPrices((prevState) => {
                                  const prices = [...prevState]
                                  prices[name] = price
                                  return prices
                                })
                                setItemQtys((prevState) => {
                                  const quantitys = [...prevState]
                                  quantitys[name] = 1
                                  return quantitys
                                })
                              } else {
                                setUnitTexts((prevState) => [...prevState, unitText])
                                setItemPrices((prevState) => [...prevState, price])
                                setItemQtys((prevState) => [...prevState, 1])
                              }
                            }}
                          >
                            {optionGroups.map((g) => {
                              return (
                                <OptGroup key={g.label} label={g.label}>
                                  {g.values.map((v) => (
                                    <Option key={v.sku} value={v.sku}>
                                      {v.sku} - {v.name}({v.quantity}) - ${v.price}/{v.unit}
                                    </Option>
                                  ))}
                                </OptGroup>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={0} md={1} xs={1}>
                        {fields.length > 1 && renderMinusCircle(name, remove, index)}
                      </Col>
                      <Col xl={0} md={2} xs={24}>
                        <Text className={classes.qtyText}>
                          {t('page.dimSupplyOrdering.orderForm.qty')}
                        </Text>
                      </Col>
                      <Col xl={5} md={22} xs={24} style={{ height: 40 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Form.Item
                            {...restProps}
                            name={[index, 'quantity']}
                            fieldKey={[fieldKey, 'quantity']}
                            rules={[
                              { required: true },
                              ({ getFieldValue }) => ({
                                validator: (_, value) => {
                                  const itemSKU = getFieldValue().items[index]?.id
                                  const product = products.find((p) => p.sku === itemSKU)
                                  const optionCategory = product?.optionCategory
                                  if (optionCategory === 'GR' && (!value || value < 2)) {
                                    return Promise.reject(
                                      new Error(
                                        t('page.dimSupplyOrdering.orderForm.error.minQty_GR'),
                                      ),
                                    )
                                  }
                                  if (optionCategory === 'TL' && (!value || value < 1)) {
                                    return Promise.reject(
                                      new Error(t('page.dimSupplyOrdering.orderForm.error.minQty')),
                                    )
                                  }
                                  if (optionCategory === 'GN' && (!value || value < 1)) {
                                    return Promise.reject(
                                      new Error(t('page.dimSupplyOrdering.orderForm.error.minQty')),
                                    )
                                  }
                                  return Promise.resolve()
                                },
                              }),
                            ]}
                          >
                            <InputNumber
                              size='middle'
                              disabled={name > unitTexts.length - 1 || unitTexts.length === 0}
                              type='number'
                              min='1'
                              onChange={(num) => {
                                if (unitTexts.length > 0 && name < unitTexts.length) {
                                  setItemQtys((prevState) => {
                                    const itemQtys = [...prevState]
                                    itemQtys[name] = num
                                    return itemQtys
                                  })
                                } else {
                                  setItemQtys((prevState) => [...prevState, num])
                                }
                              }}
                            />
                          </Form.Item>
                          <Text className={classes.qtyText}>
                            {unitTexts[name] ? `x ${t(unitTexts[name])}` : ''}
                          </Text>
                        </div>
                      </Col>
                      <Col xl={0} md={2} xs={24}>
                        <Text className={classes.qtyText}>
                          {t('page.dimSupplyOrdering.orderForm.unitPrice')}
                        </Text>
                      </Col>
                      <Col xl={4} md={21} xs={24}>
                        <Text className={classes.qtyText}>
                          {itemPrices[name]
                            ? `${currencyWithCommas(itemPrices[name])} / ${t(unitTexts[name])}`
                            : '$0'}
                        </Text>
                      </Col>
                      <Col xl={1} md={0} xs={0}>
                        {fields.length > 1 && renderMinusCircle(name, remove, index)}
                      </Col>
                    </Row>
                  )
                })}
                <Form.Item>
                  <Button
                    type='dashed'
                    disabled={form.getFieldValue().items.some((item) => item.id === '')}
                    onClick={() => add({ id: '', quantity: 1 })}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t('page.dimSupplyOrdering.orderForm.addItem')}
                  </Button>
                </Form.Item>
              </div>
            )}
          </Form.List>
          <Divider style={{ borderColor: colors.dividerDark, borderWidth: 2 }} />
          <Row style={{ marginTop: 8 }}>
            <Col xl={14} md={14} xs={8}>
              <Text className={classes.totalText}>
                {t('page.dimSupplyOrdering.orderForm.total')}
              </Text>
            </Col>
            <Col xl={5} md={5} xs={8}>
              <Text className={classes.totalText}>
                {`${itemQtys?.reduce((sum, n) => sum + n, 0)} ${t(
                  'page.dimSupplyOrdering.orderForm.qtyUnit',
                )}`}
              </Text>
            </Col>
            <Col xl={4} md={4} xs={8}>
              <Text className={classes.totalText}>{currencyWithCommas(getTotalPrice())}</Text>
            </Col>
          </Row>
        </div>
        <div>
          <Text className={classes.subtitle}>
            *{t('page.dimSupplyOrdering.orderForm.pickupDate')}
          </Text>
          <Form.Item name='pickupDate' rules={[{ required: true }]}>
            <DatePicker allowClear={false} disabledDate={disableDate} />
          </Form.Item>
          <Text className={classes.subtitle}>
            *{t('page.dimSupplyOrdering.orderForm.customerName')}
          </Text>
          <Form.Item name='customerName' rules={[{ required: true }]}>
            <Input maxLength={20} />
          </Form.Item>
          <Text className={classes.subtitle}>*{t('page.dimSupplyOrdering.orderForm.phone')}</Text>
          <Form.Item
            name='phone'
            rules={[
              {
                required: true,
              },
              {
                pattern: /^\d{8}$/,
                message: t('page.dimSupplyOrdering.orderForm.error.phone'),
              },
            ]}
          >
            <Input maxLength={8} />
          </Form.Item>
          <Text className={classes.subtitle}>
            *{t('page.dimSupplyOrdering.orderForm.paymentMethod')}
          </Text>
          <Form.Item name={['paymentMethod']} rules={[{ required: true }]}>
            <Select options={paymentOptions} dropdownMatchSelectWidth={false} />
          </Form.Item>
          <Form.Item>
            <div className={classes.footerContainer}>
              <Button htmlType='submit' className={classes.btn} loading={loading}>
                {t('page.dimSupplyOrdering.orderForm.submit')}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
      <Text className={classes.remark}>{t('page.dimSupplyOrdering.orderForm.remark')}</Text>
    </ReportCard>
  )
}
