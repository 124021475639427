import {
  UploadOutlined,
  MinusCircleOutlined,
  ExclamationCircleFilled,
  InboxOutlined,
} from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography as Text,
  Upload,
  message,
} from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReportCard from 'src/components/ReportCard'
import dimorderApi from 'src/libs/api/dimorderApi'
import { useSelector } from 'src/redux'

import { dummyRequest } from '@/libs/uploadFile'
import colors from '@/style/colors'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
  },
  boldText: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  btn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    width: '100%',
    fontSize: 15,
    height: 35,
  },
})

export default function OctopusAccount(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [isAddingOctopusAccount, setIsAddingOctopusAccount] = useState(false)
  const [octopusAccounts, setOctopusAccounts] = useState([])

  const getOctopusAccounts = async () => {
    try {
      const response = await dimorderApi.instance(activeMerchant.id)?.getOctopusAccounts()
      const updatedOctopusAccounts = response?.data?.data ?? []
      setOctopusAccounts(updatedOctopusAccounts)
    } catch (error) {
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.submitError'),
      })
    }
  }

  useEffect(async () => {
    getOctopusAccounts()
  }, [activeMerchant])

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = ''
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }

  const limitFileType = (file) => {
    const isValidType = file.type === 'application/x-pkcs12'
    if (!isValidType) {
      message.error(t('page.octopusAccount.error.fileType'))
    }
    return isValidType || Upload.LIST_IGNORE
  }

  const onFinish = async (values) => {
    await getBase64(values.pfx[0].originFileObj)
      .then((result) => {
        values.pfx = result.split(',')[1]
      })
      .catch((err) => {
        console.log(err)
      })
    try {
      await dimorderApi.instance(activeMerchant.id)?.addOctopusAccount(values)
      setIsAddingOctopusAccount(false)
      getOctopusAccounts()
    } catch (error) {
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.submitError'),
      })
    }
  }

  /**
   * @typedef DeleteOctopusAccountButtonProps
   * @property {string} account
   * @param {DeleteOctopusAccountButtonProps} props
   * @returns
   */
  function DeleteOctopusAccountButton(props) {
    const { account = '' } = props

    const handleDeleteOctopusAccount = () => {
      Modal.confirm({
        title: t('page.octopusAccount.alert.deleteOctopusAccount'),
        icon: <ExclamationCircleFilled />,
        content: account,
        okText: t('app.common.yes'),
        okType: 'danger',
        cancelText: t('app.common.no'),
        async onOk() {
          try {
            await dimorderApi.instance(activeMerchant.id)?.deleteOctopusAccount(account)
            getOctopusAccounts()
          } catch (error) {
            Modal.error({
              title: t('app.common.error'),
              content: t('app.common.submitError'),
            })
          }
        },
      })
    }

    return <MinusCircleOutlined onClick={handleDeleteOctopusAccount} />
  }

  /**
   * @typedef OctopusAccountListItemProps
   * @property {String} createdAt
   * @property {String} version
   * @property {String} account
   * @param {Object} props
   * @param {OctopusAccountListItemProps} props.octopusAccount
   * @returns
   */
  function OctopusAccountListItem({ octopusAccount }) {
    return (
      <Row style={{ paddingBottom: '10px' }}>
        <Col sm={0} xs={9}>
          <Text className={classes.text}>{t('page.octopusAccount.account')}</Text>
        </Col>
        <Col sm={12} xs={14}>
          <Text className={classes.text}>{octopusAccount.account}</Text>
        </Col>
        <Col sm={0} xs={1}>
          <DeleteOctopusAccountButton account={octopusAccount.account} />
        </Col>
        <Col sm={0} xs={9}>
          <Text className={classes.text}>{t('page.octopusAccount.version')}</Text>
        </Col>
        <Col sm={3} xs={14}>
          <Text className={classes.text}>{octopusAccount.version}</Text>
        </Col>
        <Col sm={0} xs={9}>
          <Text className={classes.text}>{t('page.octopusAccount.createdAt')}</Text>
        </Col>
        <Col sm={8} xs={14}>
          <Text className={classes.text}>
            {moment(octopusAccount.createdAt).format('YYYY-MM-DD HH:mm')}
          </Text>
        </Col>
        <Col sm={1} xs={0}>
          <DeleteOctopusAccountButton account={octopusAccount.account} />
        </Col>
      </Row>
    )
  }

  /**
   * @param {Object} props
   * @param {OctopusAccountListItemProps[]} props.accounts
   * @returns
   */
  function OctopusAccountList({ accounts = [] }) {
    if (accounts.length === 0) {
      return (
        <Row style={{ justifyContent: 'center', padding: '25px' }}>
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <InboxOutlined style={{ fontSize: '55px' }} />
            <Text className={classes.boldText}>{t('page.octopusAccount.noOctopusAccount')}</Text>
          </Col>
        </Row>
      )
    }
    return accounts.map((account, index) => (
      <OctopusAccountListItem key={index} octopusAccount={account} />
    ))
  }

  function AddOctopusAccountForm() {
    return (
      <Form
        name='basic'
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          label={t('page.octopusAccount.account')}
          name='account'
          rules={[{ required: true, max: 500 }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('page.octopusAccount.file')}
          name='pfx'
          rules={[{ required: true, message: t('page.octopusAccount.error.upload') }]}
          valuePropName='fileList'
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
        >
          <Upload
            name='file'
            beforeUpload={limitFileType}
            showUploadList
            maxCount={1}
            customRequest={dummyRequest}
          >
            <Button type='primary' icon={<UploadOutlined />}>
              {t('app.common.upload')}
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label={t('page.octopusAccount.password')}
          name='password'
          rules={[{ required: true, max: 500 }]}
        >
          <Input />
        </Form.Item>

        <Form.Item className={classes.container}>
          <Button type='primary' htmlType='submit' className={classes.btn}>
            {t('app.common.submit')}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  function AddOctopusAccount() {
    if (!isAddingOctopusAccount) {
      return (
        <Button
          type='primary'
          className={classes.btn}
          onClick={() => {
            setIsAddingOctopusAccount(true)
          }}
        >
          {t('page.octopusAccount.addOctopusAccount')}
        </Button>
      )
    }
    return <AddOctopusAccountForm />
  }

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.octopusAccount.title'),
      }}
    >
      <Row className={classes.container}>
        <Col xs={24} xl={14}>
          <ReportCard>
            <Row>
              <Col sm={12} xs={0}>
                <Text className={classes.text}>{t('page.octopusAccount.account')}</Text>
              </Col>
              <Col sm={3} xs={0}>
                <Text className={classes.text}>{t('page.octopusAccount.version')}</Text>
              </Col>
              <Col sm={9} xs={0}>
                <Text className={classes.text}>{t('page.octopusAccount.createdAt')}</Text>
              </Col>
              <Col sm={24} xs={0}>
                <Divider style={{ borderColor: colors.black, borderWidth: 2 }} />
              </Col>
            </Row>
            <OctopusAccountList accounts={octopusAccounts} />
            <Divider style={{ borderColor: colors.textTertiary, borderWidth: 1 }} />
            <AddOctopusAccount />
          </ReportCard>
        </Col>
      </Row>
    </PageContainer>
  )
}
