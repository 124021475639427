/* eslint-disable import/no-anonymous-default-export */
export default {
  primary: '#071D41',
  secondary: '#E8F0FF',
  textPrimary: '#3590FF',
  textSecondary: '#8C8C8C',
  textTertiary: '#AAAAAA',
  textHighlight: '#EE7B06',
  button: '#1C3AAE',
  divider: '#9693FF75',
  dividerDark: '#808080',
  black: '#000000',
  white: '#FFFFFF',
  grey: '#262626',
  success: '#4caf50',
  error: '#f44336',
  warning: '#ff9800',
  hover: '#CCCCCC',
  data1: '#0065FF',
  data2: '#abf5d1',
  data3: '#57d9a3',
  data4: '#e2ffee',
  data5: '#ff5630',
  data6: '#51a4ea',
  data7: '#b3d4ff',
  data8: '#ffab00',
  data9: '#fff0b3',
}
