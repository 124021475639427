import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Row, Tooltip, Typography as Text } from 'antd'
import FlatList from 'flatlist-react'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import SummaryRow from '@/components/SummaryRow'
import usePaymentMethods from '@/hooks/usePaymentMethods'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import colors from '@/style/colors'

import SummaryInfoColumn from './SummaryInfoColumn'

export default function SummaryInfo(props) {
  const { data } = props
  const { t, i18n } = useTranslation()
  const paymentMethods = usePaymentMethods()

  const tableGroupSalesSection = React.useMemo(() => {
    return _.find(data?.sections, (section) => section?.type === 'tableGroupSales')
  }, [data])

  const renderTableSalesItem = (item, index) => {
    return (
      <SummaryRow
        key={String(index) + item.tableName}
        title={item.tableName}
        count={item.count}
        amount={currencyWithCommas(item.totalSales)}
        renderTableSalesItem
      />
    )
  }

  const renderTotalItem = (item) => {
    return (
      <SummaryRow
        title={item.title}
        count={item.count}
        amount={item.amount}
        textStyle={item.textStyle}
      />
    )
  }

  const setPaymentName = (type, name) => {
    return i18n.exists(name)
      ? t(name)
      : i18n.exists(`app.constant.payment.${type}`)
      ? t(`app.constant.payment.${type}`)
      : name || type
  }

  // FIXME: can refactor, number index, dynamic rendering from data
  const paymentMethodMerchantData = [
    ...data.sections[1].data.payments.map((payment) => ({
      title: setPaymentName(payment.type, payment.name),
      count: payment.count,
      amount: currencyWithCommas(payment.amount),
    })),
    {
      title: t('page.dailyClosing.total'),
      amount: currencyWithCommas(data?.sections[1]?.data?.total),
      textStyle: { color: colors.textHighlight },
    },
  ]

  const tipsPaymentData = [
    ...data.sections[3].data.payments.map((payment) => ({
      title: paymentMethods[payment.type] ?? setPaymentName(payment.type, payment.name),
      count: payment.count,
      amount: currencyWithCommas(payment.amount),
    })),
    {
      title: t('page.dailyClosing.total'),
      amount: currencyWithCommas(data?.sections[3]?.data?.total),
      textStyle: { color: colors.textHighlight },
    },
  ]

  const auditData = [
    {
      title: t('page.dailyClosing.cancelledItem'),
      count: data?.sections[4]?.data?.cancelItem?.count,
      amount: currencyWithCommas(-1 * data?.sections[4]?.data?.cancelItem?.amount),
    },
    {
      title: t('page.dailyClosing.cancelledOrder'),
      count: data?.sections[4]?.data?.cancelOrder?.count,
      amount: currencyWithCommas(-1 * data?.sections[4]?.data?.cancelOrder?.amount),
    },
  ]

  const payInAmount = data?.sections[11]?.data?.cash?.find((c) => c.type === 'in')?.amount
  const payOutAmount = data?.sections[11]?.data?.cash?.find((c) => c.type === 'out')?.amount
  const payInAndOutTotal = data?.sections[11]?.data?.total

  const payInAndOutData = []

  if (payInAmount) {
    payInAndOutData.push({
      title: t('page.dailyClosing.payIn'),
      amount: currencyWithCommas(payInAmount),
    })
  }

  if (payOutAmount) {
    payInAndOutData.push({
      title: t('page.dailyClosing.payOut'),
      amount: `(${currencyWithCommas(payOutAmount)})`,
    })
  }

  if (payInAndOutTotal) {
    payInAndOutData.push({
      title: t('page.dailyClosing.total'),
      amount:
        payInAndOutTotal > 0
          ? `${currencyWithCommas(payInAndOutTotal)}`
          : `(${currencyWithCommas(-1 * payInAndOutTotal)})`,
      textStyle: { color: colors.textHighlight },
    })
  }

  const customOrderModifierData = [
    ...data.sections[9].data.modifiers.map((m) => ({
      title: m.name,
      count: m.count,
      amount: `(${currencyWithCommas(-1 * m.amount)})`,
    })),
    {
      title: t('page.dailyClosing.total'),
      amount: `(${currencyWithCommas(-1 * data?.sections[9]?.data?.total)})`,
      textStyle: { color: colors.textHighlight },
    },
  ]
  const customItemModifierData = [
    ...data.sections[10].data.modifiers.map((m) => ({
      title: m.name,
      count: m.count,
      amount: `(${currencyWithCommas(-1 * m.amount)})`,
    })),
    {
      title: t('page.dailyClosing.total'),
      amount: `(${currencyWithCommas(-1 * data?.sections[10]?.data?.total)})`,
      textStyle: { color: colors.textHighlight },
    },
  ]

  return (
    <Row>
      <Col span={24}>
        <SummaryInfoColumn
          data={data?.sections[0]}
          title={
            <Text>
              {t('page.dailyClosing.merchantSales')}
              <Tooltip title={t('page.dailyClosing.tooltip')}>
                <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
              </Tooltip>
            </Text>
          }
        />

        <SummaryRow
          title={t('page.dailyClosing.customOrderModifiers')}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
        />
        <FlatList list={customOrderModifierData} renderItem={renderTotalItem} />

        <SummaryRow
          title={t('page.dailyClosing.customItemModifiers')}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
        />
        <FlatList list={customItemModifierData} renderItem={renderTotalItem} />

        <SummaryRow
          title={t('page.dailyClosing.paymentMethodMerchant')}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
        />
        <FlatList list={paymentMethodMerchantData} renderItem={renderTotalItem} />

        <SummaryInfoColumn
          data={data?.sections[12]}
          title={t('page.dailyClosing.dimorderDineIn')}
        />

        <SummaryRow
          title={t('page.dailyClosing.tipsPaymentMethod')}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
        />
        <FlatList list={tipsPaymentData} renderItem={renderTotalItem} />

        <SummaryRow
          title={t('page.dailyClosing.audit')}
          textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
        />
        <FlatList list={auditData} renderItem={renderTotalItem} />

        {payInAndOutData.length > 0 && (
          <>
            <SummaryRow
              title={t('page.dailyClosing.payInAndOut')}
              textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
            />
            <FlatList list={payInAndOutData} renderItem={renderTotalItem} />
          </>
        )}

        <br />

        <SummaryInfoColumn data={data?.sections[5]} title={t('app.constant.deliveryType.table')} />

        <br />

        {/* 枱號群銷售單數與總額，沒有資料時不顯示 */}
        {tableGroupSalesSection?.data?.length > 0 && (
          <>
            <SummaryRow
              title={t('page.dailyClosing.tableGroupSales')}
              textStyle={{ fontSize: 24, fontWeight: 'bold', paddingTop: 30 }}
            />
            <FlatList list={tableGroupSalesSection?.data} renderItem={renderTableSalesItem} />
          </>
        )}

        <br />

        <SummaryInfoColumn
          data={data?.sections[6]}
          title={t('page.dailyClosing.merchantTakeaway')}
        />

        <br />

        <SummaryInfoColumn
          data={data?.sections[7]}
          title={t('page.dailyClosing.dimorderTakeaway')}
        />

        <br />

        <SummaryInfoColumn
          data={data?.sections[8]}
          title={t('page.dailyClosing.dimorderDelivery')}
        />
      </Col>
    </Row>
  )
}
