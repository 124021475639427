import { FileExcelFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import ReportActionButtonBase from './ReportActionButton'

/**
 * @typedef ExportReportButtonProps
 * @property {() => void} onClick
 */

/**
 *
 * @param {ExportReportButtonProps} props
 * @returns
 */
export default function ExportReportButton(props) {
  const { onClick } = props
  const { t } = useTranslation()

  return (
    <ReportActionButtonBase
      title={t('app.common.export')}
      icon={<FileExcelFilled style={{ paddingTop: 1, fontSize: 20 }} />}
      onClick={onClick}
    />
  )
}
