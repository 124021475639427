import { makeStyles } from '@material-ui/styles'
import { Typography as Text } from 'antd'
import moment from 'moment'

/**
 * @typedef DurationFilterLabelProps
 * @property {string} start 2022-08-18T16:00:00.000Z
 * @property {string} end 2022-08-19T15:59:00.000Z
 */

/**
 *
 * @param {DurationFilterLabelProps} props
 * @returns
 */
export default function DurationFilterLabel(props) {
  const { start, end, show } = props
  const classes = useStyles(props)

  const label =
    start && end
      ? `[
        ${moment(start).format('YYYY-MM-DD HH:mm')}
      ] - [
        ${moment(end).format('YYYY-MM-DD HH:mm')}
      ]`
      : ''

  if (!show) return null
  if (!label) return null
  return <Text className={classes.label}>{label}</Text>
}

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 13,
    textAlign: 'end',
  },
}))
