import betaConfig from './beta'
import devConfig from './dev'
import prodConfig from './prod'
import testConfig from './test'

const configs = {
  dev: devConfig,
  test: testConfig,
  beta: betaConfig,
  production: prodConfig,
}

export default configs[process.env.REACT_APP_ENV] || devConfig
