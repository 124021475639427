import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Button, Col, Collapse, Modal, Row, Typography as Text } from 'antd'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Banner from '@/components/Banner'
import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import PopUpAd from '@/components/PopUpAd'
import config from '@/config'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

export default function ReportDownload(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [activeKey, setActiveKey] = React.useState('1')
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const reportUrl = config.report

  React.useEffect(() => {
    dispatch(actions.app.popAd())
  })

  const downloadOrderReportCsv = () => {
    try {
      const urlParams = new URLSearchParams()
      urlParams.append('id', activeMerchant.id)
      urlParams.append('start', params.start)
      urlParams.append('end', params.end)
      const downloadUrl = `${reportUrl}orders?${urlParams.toString()}`
      window.location.href = downloadUrl
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  const downloadSalesReportCsv = () => {
    try {
      const urlParams = new URLSearchParams()
      urlParams.append('id', activeMerchant.id)
      urlParams.append('start', params.start)
      urlParams.append('end', params.end)
      const downloadUrl = `${reportUrl}sales?${urlParams.toString()}`
      window.location.href = downloadUrl
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.reportDownload.title') }}>
      <PopUpAd />
      <Banner />
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      {params && (
        <Row gutter={16}>
          <Col xs={24} xl={12} className={classes.container}>
            <Card
              width='100%'
              height='100%'
              padding='20px'
              borderRadius='2px'
              textAlign='center'
              boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
            >
              <Text style={{ fontSize: 22, textAlign: 'center', lineHeight: 3 }}>
                {activeMerchant?.name} - {t('page.reportDownload.orderReport')}
              </Text>
              <Text style={{ fontSize: 20, textAlign: 'center', lineHeight: 3 }}>
                [ {moment(params?.start).format('YYYY-MM-DD HH:mm')} ] - [{' '}
                {moment(params?.end).format('YYYY-MM-DD HH:mm')} ]
              </Text>
              <br />
              <Button onClick={downloadOrderReportCsv} className={classes.btn}>
                下載
              </Button>
            </Card>
          </Col>
          <Col xs={24} xl={12} className={classes.container}>
            <Card
              width='100%'
              height='100%'
              padding='20px'
              borderRadius='2px'
              textAlign='center'
              boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
            >
              <Text style={{ fontSize: 22, textAlign: 'center', lineHeight: 3 }}>
                {activeMerchant?.name} - {t('page.reportDownload.salesReport')}
              </Text>
              <Text style={{ fontSize: 20, textAlign: 'center', lineHeight: 3 }}>
                [ {moment(params?.start).format('YYYY-MM-DD HH:mm')} ] - [{' '}
                {moment(params?.end).format('YYYY-MM-DD HH:mm')} ]
              </Text>
              <br />
              <Button onClick={downloadSalesReportCsv} className={classes.btn}>
                {t('page.reportDownload.download')}
              </Button>
            </Card>
          </Col>
        </Row>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 20,
  },
  btn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    marginBottom: 20,
    width: 150,
  },
  extra: {
    fontSize: 13,
  },
}))
