import { useLocation, matchPath } from 'react-router-dom'

import { useSelector } from '@/redux'

export function useGroupId() {
  const location = useLocation()
  const match = matchPath(location.pathname, { path: '/crm/:groupId', exact: false })

  return match?.params?.groupId
}

export function useGroup(groupId) {
  const groupIdInPathname = useGroupId()
  const searchGroupId = groupId || groupIdInPathname
  const crmGroups = useSelector((state) => state.merchant.crmGroups) ?? []
  const group = crmGroups[searchGroupId]

  return group
}
