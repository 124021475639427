import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import DurationFilterButton from '@root/src/components/DurationFilterButton'
import FilterDialog from '@root/src/components/FilterDialog'
import { Button, Col, Modal, Row, Spin, Table, Typography as Text, Tabs, Collapse } from 'antd'
import { saveAs } from 'file-saver'
import produce from 'immer'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./worker/statements.worker.js'

import ReportCard from '@/components/ReportCard'
import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

const { Panel } = Collapse

const tabs = [
  { id: 'storeDelivery', text: '外賣' },
  { id: 'table', text: '堂食' },
]

const initialStatementStore = _.reduce(
  tabs,
  (acc, tab) => {
    acc[tab.id] = []
    return acc
  },
  {},
)

export default function DimorderStatement(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [statementStore, setStatementStore] = useState(initialStatementStore)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedTab, setSelectedTab] = useState(tabs[0].id)
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState(null)
  const [activeKey, setActiveKey] = useState('0')
  const [isSearchHide, setIsSearchHide] = useState(true)
  const [isBatchDownloading, setIsBatchDownloading] = useState(false)
  const [isShowingTimeLabel, setIsShowingTimeLabel] = useState(false)
  const [displayData, setDisplayData] = useState([])

  const resetState = () => {
    const start = moment(0).toISOString()
    const end = moment().toISOString()

    setParams({ start, end })
    setIsSearchHide(true)
    setActiveKey('0')
    setIsShowingTimeLabel(false)
  }

  const setStateParams = (props) => {
    setParams(props)
    setIsShowingTimeLabel(true)
  }

  useEffect(() => {
    if (!(params?.start && params?.end)) {
      resetState()
    }
  }, [params])

  useEffect(() => {
    // 在報表頁面切換餐廳時，清空所有資料
    resetState()
  }, [activeMerchant?.id])

  useEffect(async () => {
    if (!params) return
    setLoading(true)
    setStatementStore(initialStatementStore)
    try {
      const deliveryType = selectedTab
      const statementResponse = await dimorderApi
        .instance(activeMerchant.id)
        ?.dimorderStatement({ deliveryType, ...params })
      const selectAllStatements = {
        key: 'download-all-statements',
        date: '',
        downloadUrl: '',
        downloadType: 'all',
      }

      if (statementResponse?.length > 0) {
        const dateFormatter = (date) => moment(date).format('YYYY-MM-DD')
        const parsedStatements = _.map(statementResponse, (statement) => {
          const { jobId, downloadLink, startedAt, endedAt } = statement
          const formattedDate = `[ ${dateFormatter(startedAt)} ] - [ ${dateFormatter(endedAt)} ]`
          return {
            key: jobId,
            date: formattedDate,
            downloadUrl: downloadLink,
          }
        })

        setStatementStore(
          produce((draft) => {
            draft[selectedTab] = [selectAllStatements, ...parsedStatements]
          }),
        )

        setDisplayData([selectAllStatements, ...parsedStatements])
      }

      setActiveKey('0')
      setIsSearchHide(true)
    } catch (error) {
      console.error(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
      setActiveKey('1')
      setIsSearchHide(false)
    } finally {
      setLoading(false)
    }
  }, [activeMerchant?.id, selectedTab, params])

  useEffect(() => {
    // 當 statementStore 更新或切換 tab 時，更新 tab 需顯示的資料，並清空上一個 tab 選擇的資料
    setDisplayData(statementStore[selectedTab])
    setSelectedRows([])
  }, [statementStore, selectedTab, params])

  const LoadingSpin = useCallback(() => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: 60,
        }}
      >
        <Spin size='large' />
      </div>
    )
  }, [])

  const BatchDownloadButton = useCallback(
    (props) => {
      const { selectedRows, isBatchDownloading } = props
      const isDisabled = selectedRows.length === 0 || isBatchDownloading
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            disabled={isDisabled}
            className={classes.btn}
            onClick={() => {
              setIsBatchDownloading(true)
              const worker = new Worker()
              worker.postMessage({ selectedRows })
              worker.addEventListener('message', (message) => {
                const { downloadable } = message.data
                worker.terminate()
                saveAs(
                  downloadable,
                  `${activeMerchant?.name} - ${t('page.dimorderStatement.statement')}.zip`,
                )
                setIsBatchDownloading(false)
              })
            }}
          >
            {isBatchDownloading ? (
              <Spin size='small' />
            ) : (
              t('page.dimorderStatement.batch_download')
            )}
          </Button>
        </div>
      )
    },
    [activeMerchant],
  )

  const DownloadButton = useCallback((props) => {
    const { downloadUrl } = props
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          className={classes.btn}
          onClick={() => {
            window.location.href = downloadUrl
          }}
        >
          {t('page.dimorderStatement.download')}
        </Button>
      </div>
    )
  }, [])

  const renderDownloadButton = useCallback(
    (downloadUrl, record) => {
      const isBatchDownload = record.downloadType === 'all'
      return isBatchDownload ? (
        <BatchDownloadButton selectedRows={selectedRows} isBatchDownloading={isBatchDownloading} />
      ) : (
        <DownloadButton downloadUrl={downloadUrl} />
      )
    },
    [selectedRows, isBatchDownloading],
  )

  const rowSelection = useMemo(
    () => ({
      type: 'checkbox',
      selectedRows,
      onChange: (newSelectedRowKeys, newSelectedRows) => {
        setSelectedRows(newSelectedRows)
      },
      getCheckboxProps: (record) => {
        const { downloadType, downloadUrl } = record
        const isBatchDownload = downloadType === 'all'
        return {
          style: { opacity: isBatchDownload ? 0 : 1 }, // 隱藏下載已勾選表單這行的 checkbox
          disabled: isBatchDownload,
          downloadUrl,
        }
      },
    }),
    [selectedRows],
  )

  const columns = useMemo(
    () => [
      {
        title: t('page.dimorderStatement.date'),
        dataIndex: 'date',
      },
      {
        title: t('page.dimorderStatement.download'),
        dataIndex: 'downloadUrl',
        render: renderDownloadButton,
      },
    ],
    [renderDownloadButton],
  )

  return (
    <PageContainer ghost header={{ title: t('page.dimorderStatement.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => {
          if (typeof key === 'object') {
            setActiveKey(key?.[key.length - 1] ?? '0')
          } else {
            setActiveKey(key)
          }
        }}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton
              showLabel={isShowingTimeLabel}
              start={params?.start}
              end={params?.end}
              activeKey={activeKey}
            />
          }
        >
          <FilterDialog filterTime setParams={setStateParams} />
        </Panel>
      </Collapse>
      <br />
      {isSearchHide && (
        <>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col xs={24} xl={14}>
              <ReportCard>
                <Text className={classes.title}>
                  {`${activeMerchant?.name} - ${t('page.dimorderStatement.statement')}`}
                </Text>
                <br />
                <Tabs
                  defaultActiveKey='storeDelivery'
                  centered
                  className={classes.tab}
                  onChange={(e) => {
                    setSelectedTab(e)
                    setIsSearchHide(true)
                  }}
                  destroyInactiveTabPane
                >
                  {tabs.map((tab) => (
                    <Tabs.TabPane tab={tab.text} key={tab.id}>
                      {loading ? (
                        <LoadingSpin />
                      ) : (
                        <Table
                          rowSelection={rowSelection}
                          columns={columns}
                          dataSource={displayData}
                          pagination={false}
                        />
                      )}
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              </ReportCard>
            </Col>
          </Row>
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles({
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  tab: {
    '& .ant-tabs-nav-list': {
      width: '100%',
    },
    '& .ant-tabs-tab': {
      justifyContent: 'center',
      display: 'flex',
      width: `${(1 / tabs.length) * 100 - 1}%`,
    },
    '& .ant-tabs-nav-operations': {
      display: 'none !important',
    },
  },
})
