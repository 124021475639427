import { QuestionCircleOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Col, Divider, Row, Tooltip, Typography as Text } from 'antd'
import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'

import SummaryRow from '@/components/SummaryRow'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import colors from '@/style/colors'

export default function SummaryInfo(props) {
  const { data } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  const categoriesSummary = []

  data?.categorySection?.forEach((category) => {
    const title = category.categoryName
    const amount = category.sales ? category.sales : '0'
    categoriesSummary.push({
      title,
      quantity: category.quantity,
      amount,
    })
  })

  data?.setSection?.forEach((setCategory) => {
    const title = setCategory.categoryName
    const amount = setCategory.sales ? setCategory.sales : '0'
    categoriesSummary.push({
      title,
      quantity: setCategory.quantity,
      amount,
    })
  })

  const categoriesDetail = []
  const setDetail = []

  data?.categorySection?.forEach((category) => {
    const menuItems = []
    const setItems = []
    category.items.forEach((item) => {
      if (!item.isSetItem) {
        menuItems.push({
          name: item.name,
          amount: item.sales ? item.sales : '0',
          count: item.quantity ? item.quantity : '0',
        })
      } else if (item.isSetItem) {
        setItems.push({
          name: `[${t('page.salesReportByCategory.setItem')}] ${item.name}`,
          count: item.quantity,
        })
      }
    })
    setItems.forEach((setItem) => {
      menuItems.push({
        name: setItem.name,
        count: setItem.count,
      })
    })
    categoriesDetail.push({
      name: category.categoryName,
      menuItems,
      quantity: category.quantity,
      amount: category.sales,
    })
  })

  data?.setSection?.forEach((setCategory) => {
    const menuItems = []
    setCategory.sets.forEach((set) => {
      if (!set.isSetItem) {
        menuItems.push({
          name: `${set.setName} (${t('page.salesReportByCategory.set')})`,
          amount: set.sales ? set.sales : '0',
          count: set.quantity ? set.quantity : '0',
        })
      }
    })
    categoriesDetail.push({
      name: setCategory.categoryName,
      menuItems,
      quantity: setCategory.quantity,
      amount: setCategory.sales ? setCategory.sales : '0',
    })
  })

  data?.setSection.forEach((setCategory) => {
    const sets = []
    setCategory.sets.forEach((set) => {
      const setItems = []
      set.items.forEach((combination) => {
        combination.itemNames.forEach((item, index) => {
          if (index === 0) {
            setItems.push({
              name: combination.itemNames[index],
              count: combination.quantity,
              amount: combination.sales,
            })
          } else {
            setItems.push({
              name: combination.itemNames[index],
            })
          }
          if (index === combination.itemNames.length - 1) {
            setItems.push({
              name: '',
            })
          }
        })
      })
      sets.push({
        name: set.setName,
        amount: set.sales,
        count: set.quantity,
        setItems,
      })
      return null
    })
    setDetail.push({
      setCategory: setCategory.categoryName,
      sets,
    })
    return null
  })

  const renderTotalItem = (item) => {
    return (
      <SummaryRow
        title={<Text className={classes.categoryText}>{item.title}</Text>}
        count={item.quantity}
        amount={currencyWithCommas(item.amount)}
        textStyle={item.textStyle}
      />
    )
  }

  const renderSetCategory = (setDetail) => {
    return (
      <>
        <SummaryRow title={<Text className={classes.categoryText}>{setDetail.setCategory}</Text>} />
        <FlatList list={setDetail.sets} renderItem={renderSet} />
      </>
    )
  }

  const renderSet = (set) => {
    return (
      <>
        <SummaryRow
          title={<Text className={classes.itemText}>{set.name}</Text>}
          amount={currencyWithCommas(set.amount)}
          count={set.count}
        />

        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider className={classes.smallDivider} />
        </Row>

        {set.setItems.length > 0 && <FlatList list={set.setItems} renderItem={renderSetItem} />}
      </>
    )
  }

  const renderSetItem = (item) => {
    return (
      <SummaryRow
        title={item.name ? <Text className={classes.setItemText}>{item.name}</Text> : <br />}
        count={item.count ? item.count : ''}
        amount={item.amount ? currencyWithCommas(item.amount) : ''}
        textStyle={{ color: colors.textSecondary, fontSize: 14 }}
      />
    )
  }

  const renderCategory = (category) => {
    return (
      <>
        <SummaryRow
          title={<Text className={classes.categoryText}>{category.name}</Text>}
          amount=''
        />
        <FlatList list={category.menuItems} renderItem={renderCategoryItem} />
        <SummaryRow
          title={<Text className={classes.totalText}>{t('page.salesReportByCategory.sales')}</Text>}
          count={category.quantity}
          amount={currencyWithCommas(category.amount)}
          textStyle={{ color: colors.textHighlight }}
        />
        <br />
      </>
    )
  }

  const renderCategoryItem = (item) => {
    return (
      <SummaryRow
        title={<Text className={classes.itemText}>{item.name}</Text>}
        count={item.count}
        amount={item.amount ? currencyWithCommas(item.amount) : '--'}
      />
    )
  }
  const categoriesSummaryTotal = {
    title: t('page.salesReportByCategory.total'),
    quantity: 0,
    amount: 0,
  }
  categoriesSummaryTotal.quantity = categoriesSummary.reduce((a, c) => {
    a = a + c.quantity
    return a
  }, 0)
  categoriesSummaryTotal.amount = categoriesSummary.reduce((a, c) => {
    a = a + +c.amount
    return a
  }, 0)

  return (
    <Row>
      <Col span={24}>
        <Text className={classes.title}>
          {t('page.salesReportByCategory.summary')}
          <Tooltip title={t('page.salesReportByCategory.summaryDesc')}>
            <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
          </Tooltip>
        </Text>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider className={classes.divider} />
        </Row>
        {(data?.categorySection?.length > 0 || data?.setSection?.length > 0) && (
          <FlatList list={categoriesSummary} renderItem={renderTotalItem} />
        )}
        {/* 總計 */}
        <hr />
        <SummaryRow
          title={<Text className={classes.categoryText}>{categoriesSummaryTotal.title}</Text>}
          count={categoriesSummaryTotal.quantity}
          amount={currencyWithCommas(categoriesSummaryTotal.amount)}
        />
        <Text className={classes.title}>
          {t('page.salesReportByCategory.categoryItemSales')}
          <Tooltip title={t('page.salesReportByCategory.tooltip.categoryItemSales')}>
            <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
          </Tooltip>
        </Text>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider className={classes.divider} />
        </Row>
        {data?.categorySection?.length > 0 && (
          <FlatList list={categoriesDetail} renderItem={renderCategory} />
        )}
        <Text className={classes.title}>
          {t('page.salesReportByCategory.categorySalesDetail')}
          <Tooltip title={t('page.salesReportByCategory.tooltip.categorySalesDetail')}>
            <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
          </Tooltip>
        </Text>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Divider className={classes.divider} />
        </Row>
        {data?.setSection?.length > 0 && (
          <FlatList list={setDetail} renderItem={renderSetCategory} />
        )}
      </Col>
    </Row>
  )
}

const useStyles = makeStyles(() => ({
  divider: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'grey',
  },
  smallDivider: {
    borderColor: 'grey',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 12,
    textAlign: 'center',
  },
  categoryText: {
    fontWeight: 'bold',
  },
  itemText: {
    paddingLeft: 10,
  },
  totalText: {
    paddingLeft: 10,
    color: colors.textHighlight,
  },
  setItemText: {
    color: colors.textSecondary,
    paddingLeft: 20,
    fontSize: 14,
  },
}))
