import { makeStyles } from '@material-ui/styles'
import { Typography as Text } from 'antd'

import DurationFilterLabel from './DurationFilterLabel'
import ReportLogoHeader from './ReportLogoHeader'

/**
 * @typedef ReportPrintContainerProps
 * @property {React.MutableRefObject<HTMLDivElement>} printRef
 * @property {string} start 2022-08-18T16:00:00.000Z
 * @property {string} end 2022-08-19T15:59:00.000Z
 * @property {string} title
 * @property {JSX.Element} [children]
 */

/**
 *
 * @param {ReportPrintContainerProps} props
 * @returns
 */
export default function ReportPrintContainer(props) {
  const { printRef, start, end, title, children } = props
  const classes = useStyles(props)

  return (
    <div ref={printRef} className={classes.print}>
      <ReportLogoHeader />
      <br />
      <Text className={classes.title}>{title}</Text>
      <DurationFilterLabel start={start} end={end} />
      {children}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  print: {
    '@media screen': {
      overflow: 'hidden',
      height: 0,
    },
    '@media print': {
      display: 'block',
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
}))
