import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Auth from '@/page/Auth'
import store from '@/redux/store'
import GlobalStyles from '@/style/GlobalStyles'
import theme from '@/style/theme'
import history from '@/utils/history'

import App from './App'
import AddMerchantModal from './components/AddMerchantModal'

import '@/i18n'

import '@ant-design/pro-layout/dist/layout.css'
import 'antd/dist/antd.css'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <GlobalStyles />
        <Auth>
          <App />
        </Auth>
        <AddMerchantModal />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)
