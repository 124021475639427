import { QuestionCircleOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row, Table, Tooltip, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { Bar } from '@/components/chart'
import { momentLocaleZH, pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

import Averages from './Averages'

export default function Overview(props) {
  const { t } = useTranslation()
  const { Panel } = Collapse
  const classes = useStyles(props)
  const printRef = useRef()
  const [params, setParams] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [overview, setOverView] = React.useState(null)
  const [activeKey, setActiveKey] = React.useState('1')
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const lang = useSelector((state) => state.app.lang)

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const overview = await dimorderApi.instance(activeMerchant.id)?.getOverview(params)
          setOverView(overview)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(overview?.byInterval?.length)
  }, [overview])

  const salesByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const customerCountByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const orderCountByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const averageSalesByOrderByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const averageSalesByCustomerByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const discountByDate = {
    labels: [],
    datasets: [
      {
        label: t('page.overview.value'),
        data: [],
        backgroundColor: colors.data1,
      },
    ],
  }

  const overviewByDate = {
    columns: [
      {
        title: t('app.common.date'),
        dataIndex: 'date',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: (
          <Text>
            {t('app.constant.order.totalSales')}
            <Tooltip
              title={
                <Text style={{ color: colors.white }}>
                  ＊ {t('page.overview.salesDesc')} <br />＊ {t('page.salesReportByItem.salesDesc')}
                </Text>
              }
            >
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'totalSales',
        sorter: (a, b) => a.totalSales - b.totalSales,
        render: (totalSales) => (
          <span>
            <Text>{currencyWithCommas(totalSales)}</Text>
          </span>
        ),
      },
      {
        title: t('app.constant.order.sales'),
        dataIndex: 'salesAmount',
        sorter: (a, b) => a.salesAmount - b.salesAmount,
        render: (salesAmount) => (
          <span>
            <Text>{currencyWithCommas(salesAmount)}</Text>
          </span>
        ),
      },
      {
        title: t('app.constant.order.surcharge'),
        dataIndex: 'surcharge',
        sorter: (a, b) => a.surcharge - b.surcharge,
        render: (surcharge) => (
          <span>
            <Text>{currencyWithCommas(surcharge)}</Text>
          </span>
        ),
      },
      {
        title: t('app.constant.order.merchantDiscount'),
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
        render: (discount) => (
          <span>
            <Text>{currencyWithCommas(discount)}</Text>
          </span>
        ),
      },
      {
        title: t('app.constant.order.cancelled'),
        dataIndex: 'cancelledAmount',
        sorter: (a, b) => a.cancelledAmount - b.cancelledAmount,
        render: (cancelledAmount) => (
          <span>
            <Text>{currencyWithCommas(cancelledAmount)}</Text>
          </span>
        ),
      },
      {
        title: t('page.overview.customers'),
        dataIndex: 'customers',
        sorter: (a, b) => a.customers - b.customers,
      },
      {
        title: t('page.overview.orderCount'),
        dataIndex: 'orderCount',
        sorter: (a, b) => a.orderCount - b.orderCount,
      },
      {
        title: t('page.overview.customerAvgSpending'),
        dataIndex: 'avgConsumePerCustomer',
        sorter: (a, b) => a.avgConsumePerCustomer - b.avgConsumePerCustomer,
        render: (avgConsumePerCustomer) => (
          <span>
            <Text>{currencyWithCommas(avgConsumePerCustomer)}</Text>
          </span>
        ),
      },
      {
        title: t('page.overview.orderAvgSpending'),
        dataIndex: 'avgConsumePerOrder',
        sorter: (a, b) => a.avgConsumePerOrder - b.avgConsumePerOrder,
        render: (avgConsumePerOrder) => (
          <span>
            <Text>{currencyWithCommas(avgConsumePerOrder)}</Text>
          </span>
        ),
      },
    ],
    data: [],
  }

  const renderLabel = (time) => {
    moment.locale('zh', momentLocaleZH)

    switch (params?.group_by) {
      case 'day': {
        return moment(time).format('YYYY-MM-DD')
      }

      case 'week': {
        return `${moment(time).format('YYYY-MM-DD')} - ${moment(time)
          .add(6, 'day')
          .format('YYYY-MM-DD')}`
      }

      case 'month': {
        if (lang === 'zh-HK') {
          return moment(time).locale('zh', momentLocaleZH).format('MMM YYYY')
        } else {
          return moment(time).locale('en').format('MMM YYYY')
        }
      }

      case 'year': {
        return moment(time).format('YYYY')
      }

      default: {
        return moment(time).format('YYYY-MM-DD')
      }
    }
  }

  if (overview) {
    for (const [key, value] of Object.entries(overview?.byInterval)) {
      const label = renderLabel(value.time)
      salesByDate.labels.push(label)
      customerCountByDate.labels.push(label)
      orderCountByDate.labels.push(label)
      averageSalesByOrderByDate.labels.push(label)
      averageSalesByCustomerByDate.labels.push(label)
      discountByDate.labels.push(label)
      salesByDate.datasets[0].data.push(value.totalSales)
      customerCountByDate.datasets[0].data.push(value.customerCount)
      orderCountByDate.datasets[0].data.push(value.orderCount)
      averageSalesByOrderByDate.datasets[0].data.push(value.averageSalesPerOrder)
      averageSalesByCustomerByDate.datasets[0].data.push(value.averageSalesPerCustomer)
      discountByDate.datasets[0].data.push(value.discount)
      overviewByDate.data.push({
        key,
        date: label,
        totalSales: value.totalSales,
        salesAmount: value.sales,
        surcharge: value.surcharge,
        cancelledAmount: value.cancelledAmount,
        discount: value.discount,
        customers: value.customerCount,
        orderCount: value.orderCount,
        avgConsumePerCustomer: value.averageSalesPerCustomer,
        avgConsumePerOrder: value.averageSalesPerOrder,
      })
    }
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportOverviewCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  const drawBarChart = (data) => (
    <Bar
      height={300}
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          axis: 'x',
        },
        layout: {
          padding: 10,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maxBarThickness: 80,
      }}
    />
  )

  const drawBarChartForPrint = (data) => (
    <Bar
      height={300}
      width={550}
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          axis: 'x',
        },
        layout: {
          padding: 10,
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        maxBarThickness: 80,
      }}
    />
  )

  const displayResult = () => (
    <>
      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('app.constant.order.totalSales')}</Text>
            {drawBarChart(salesByDate)}
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.overview.customerCount')}</Text>
            {drawBarChart(customerCountByDate)}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.overview.orderCount')}</Text>
            {drawBarChart(orderCountByDate)}
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.overview.orderAvgSpending')}</Text>
            {drawBarChart(averageSalesByOrderByDate)}
          </Card>
        </Col>
      </Row>

      <Averages overall={overview.overall} interval={params.group_by ?? 'day'} />

      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.overview.customerAvgSpending')}</Text>
            {drawBarChart(averageSalesByCustomerByDate)}
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px 5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('app.constant.order.discount')}</Text>
            {drawBarChart(discountByDate)}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {`${t('page.overview.table')}${moment(params.start).format('YYYY-MM-DD HH:mm')} ${t(
                'app.common.to',
              )} ${moment(params.end).format('YYYY-MM-DD HH:mm')} `}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={overviewByDate.columns}
              dataSource={overviewByDate.data}
              scroll={{ x: 500 }}
              summary={() => {
                const overallSales = overview?.overall.totalSales
                const totalSales = overview?.overall?.sales
                const totalDiscount = overview?.overall?.discount
                const totalCustomerCount = overview?.overall?.customerCount
                const totalOrderConut = overview?.overall?.orderCount
                const totalSurcharge = overview?.overall?.surcharge
                const totalCancelledAmount = overview?.overall?.cancelledAmount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(overallSales)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{currencyWithCommas(totalSales)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalSurcharge)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalCancelledAmount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalCustomerCount}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalOrderConut}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.overview.title')}
    >
      <Row gutter={16}>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('app.constant.order.totalSales')}</Text>
            {drawBarChartForPrint(salesByDate)}
          </>
        </Col>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.overview.customerCount')}</Text>
            {drawBarChartForPrint(customerCountByDate)}
          </>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.overview.orderCount')}</Text>
            {drawBarChartForPrint(orderCountByDate)}
          </>
        </Col>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.overview.orderAvgSpending')}</Text>
            {drawBarChartForPrint(averageSalesByOrderByDate)}
          </>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.overview.customerAvgSpending')}</Text>
            {drawBarChartForPrint(averageSalesByCustomerByDate)}
          </>
        </Col>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('app.constant.order.discount')}</Text>
            {drawBarChartForPrint(discountByDate)}
          </>
        </Col>
      </Row>

      <Averages overall={overview.overall} interval={params.group_by ?? 'day'} print />

      <Row gutter={16}>
        <Col span={24} className={classes.printContainer}>
          <>
            <Text className={classes.title}>
              {`${t('page.overview.table')}${moment(params.start).format('YYYY-MM-DD HH:mm')} ${t(
                'app.common.to',
              )} ${moment(params.end).format('YYYY-MM-DD HH:mm')} `}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={overviewByDate.columns}
              dataSource={overviewByDate.data}
              scroll={{ x: 500 }}
              pagination={false}
              summary={() => {
                const overallSales = overview?.overall.totalSales
                const totalSales = overview?.overall?.sales
                const totalDiscount = overview?.overall?.discount
                const totalCustomerCount = overview?.overall?.customerCount
                const totalOrderConut = overview?.overall?.orderCount
                const totalSurcharge = overview?.overall?.surcharge
                const totalCancelledAmount = overview?.overall?.cancelledAmount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(overallSales)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{currencyWithCommas(totalSales)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalSurcharge)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalCancelledAmount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalCustomerCount}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalOrderConut}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  return (
    <PageContainer ghost header={{ title: t('page.overview.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog
            className='filter'
            filter
            filterTime
            filterOrderType
            filterCustomers
            filterDataInterval
            setParams={setParams}
          />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {overview && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  container: {
    paddingTop: 20,
  },
  title: {
    textAlign: 'center',
    fontSize: 18,
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
    alignItems: 'center',
  },
}))
