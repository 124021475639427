import { sum } from 'ramda'
import { useMemo } from 'react'
import styled from 'styled-components'

const Div = styled.div`
  /width: ${(props) => props.width}px;
  /height: ${(props) => props.height}px;
  flex: 1;

  display: flex;
  flex-direction: row;
`

const Block = styled.div`
  background-color: ${(props) => props.backgroundColor};
  /min-width: ${(props) => props.size}px;
  min-width: 20vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function LineChart(props) {
  const { width, height, data } = props

  const total = useMemo(() => {
    return sum(data.map(({ data }) => data))
  }, [data])

  return (
    <Div width={width} height={height}>
      {data.map(({ label, data, backgroundColor }, index) => (
        <div key={index}>
          <Block backgroundColor={backgroundColor} size={(data / total) * width}>
            {data}
          </Block>
          {label}
        </div>
      ))}
    </Div>
  )
}
