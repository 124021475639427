import {
  AreaChartOutlined,
  DashboardFilled,
  ExportOutlined,
  FilePdfFilled,
  FileTextFilled,
  ProfileOutlined,
  ShoppingOutlined,
  AppstoreOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import ProLayout from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Divider, Image, Menu } from 'antd'
import { React, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { actions, useDispatch } from '@/redux'

import CRMMenu from './components/CRMMenu'
import LanguageOption from './components/LanguageOption'
import MerchantMenu from './components/MerchantMenu'
import UserMenu from './components/UserMenu'
import AllDashboard from './page/AllDashboard'
import CRM from './page/CRM'
import CRMGroup from './page/CRM/CRMGroup'
import MemberList from './page/CRM/MemberList'
import PointRule from './page/CRM/PointRule'
import RewardSetting from './page/CRM/RewardSetting'
import CustomerReport from './page/CustomerReport'
import DailyClosing from './page/DailyClosing'
import DashboardPage from './page/Dashboard'
import DepartmentReport from './page/DepartmentReport'
import DimSupplyOrdering from './page/DimSupplyOrdering'
import DimorderStatement from './page/DimorderStatement'
import DiscountReport from './page/DiscountReport'
import FloorPlan from './page/FloorPlan'
import HourlySalesReport from './page/HourlySalesReport'
import ItemVoidReport from './page/ItemVoidReport'
import Maintenance from './page/Maintenance'
import MenuImportExport from './page/MenuImportExport'
import OctopusAccount from './page/OctopusAccount'
import OptionSalesReport from './page/OptionSalesReport'
import OrderVoidReport from './page/OrderVoidReport'
import OverviewPage from './page/Overview'
import PayInAndOutReport from './page/PayInAndOutReport'
import PaymentMethodReport from './page/PaymentMethodReport'
import PreparingTimeReport from './page/PreparingTimeReport'
import ReportDownload from './page/ReportDownload'
import SalesReportByCategory from './page/SalesReportByCategory'
import SalesReportByItem from './page/SalesReportByItem'
import Transaction from './page/Transaction'
import { useSelector } from './redux'
import colors from './style/colors'

export default function App(props) {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles(props)
  const { t } = useTranslation()
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const activeMerchantSetting = activeMerchant?.setting

  const doLogout = useCallback(() => {
    dispatch(actions.user.logout())
  }, [dispatch])

  useEffect(() => {
    if (
      location.pathname === '/reportdownload' &&
      activeMerchantSetting?.enablePortalReportDownload &&
      !activeMerchantSetting?.enablePortalDashboard
    ) {
      history.replace('/')
    }
    if (
      location.pathname === '/landing' &&
      activeMerchantSetting?.enablePortalDashboard &&
      !activeMerchantSetting?.enablePortalReportDownload
    ) {
      history.replace('/')
    }
  }, [location, history, activeMerchantSetting])

  const defaultProps = {
    route: {
      path: '/',
      routes: [
        {
          path:
            !activeMerchantSetting || activeMerchantSetting.enablePortalDashboard
              ? '/'
              : '/landing',
          icon: <DashboardFilled style={{ fontSize: 16 }} />,
          name: t('app.component.navBar.dashboard'),
          hideInMenu: !activeMerchant || !activeMerchantSetting.enablePortalDashboard,
        },
        {
          path: '/report',
          name: t('app.component.navBar.report'),
          key: 'report',
          icon: <AreaChartOutlined style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant || !activeMerchantSetting?.enablePortalDatanReport,
          children: [
            {
              path: '/overview',
              name: t('app.component.navBar.overview'),
            },
            {
              path: '/dailyclosing',
              name: t('app.component.navBar.dailyClosing'),
            },
            {
              path: '/transaction',
              name: t('app.component.navBar.transaction'),
            },
            {
              path: '/hourlysalesreport',
              name: t('app.component.navBar.hourlySalesReport'),
            },
            {
              path: '/salesreportbyitem',
              name: t('app.component.navBar.salesReportByItem'),
            },
            {
              path: '/salesreportbycategory',
              name: t('app.component.navBar.salesReportByCategory'),
            },
            {
              path: '/ordervoidreport',
              name: t('app.component.navBar.orderVoidReport'),
            },
            {
              path: '/itemvoidreport',
              name: t('app.component.navBar.itemVoidReport'),
            },
            {
              path: '/discountreport',
              name: t('app.component.navBar.discountReport'),
            },
            {
              path: '/paymentmethodreport',
              name: t('app.component.navBar.paymentMethodReport'),
            },
            {
              path: '/customerreport',
              name: t('app.component.navBar.customerReport'),
            },
            {
              path: '/preparingtimereport',
              name: t('page.preparingTimeReport.title'),
            },
            {
              path: '/payinandoutreport',
              name: t('page.payInAndOutReport.title'),
            },
            {
              path: '/optionsalesreport',
              name: t('page.optionSalesReport.title'),
            },
            {
              path: '/departmentreport',
              name: t('page.departmentReport.title'),
            },
          ],
        },
        {
          path:
            activeMerchantSetting?.enablePortalReportDownload &&
            !activeMerchantSetting?.enablePortalDashboard
              ? '/'
              : '/reportdownload',
          name: t('app.component.navBar.reportDownload'),
          icon: <FileTextFilled style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant || !activeMerchantSetting.enablePortalReportDownload,
        },
        {
          path: '/menuimportexport',
          name: t('page.menuImportExport.title'),
          icon: <ExportOutlined style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant,
        },
        {
          path: '/dimorderstatement',
          name: t('page.dimorderStatement.title'),
          icon: <FilePdfFilled style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant || !activeMerchantSetting.enablePortalStatementPage,
        },
        {
          path: '/dimsupplyordering',
          name: t('page.dimSupplyOrdering.title'),
          icon: <ShoppingOutlined style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant,
        },
        {
          path: '/floorplan',
          name: t('page.floorPlan.title'),
          icon: <AppstoreOutlined style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant,
        },
        {
          path: '/octopusaccount',
          name: t('page.octopusAccount.title'),
          icon: <ProfileOutlined style={{ fontSize: 16 }} />,
          hideInMenu: !activeMerchant,
        },
      ],
    },
  }

  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <div
        style={{
          height: '100vh',
        }}
      >
        <ProLayout
          {...defaultProps}
          siderWidth={250}
          logo='https://img.dimorder.com/414x/public/Landing_Banner/logo/dimpos-v2.png'
          title='點單 DimOrder'
          navTheme='dark'
          location={location}
          fixSiderbar
          fixedHeader
          menuHeaderRender={() => {}}
          menuExtraRender={({ collapsed }) =>
            !collapsed && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: 10,
                  }}
                >
                  <UserMenu />
                </div>
                <Divider style={{ borderColor: colors.textTertiary }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingLeft: 10,
                  }}
                >
                  <CRMMenu />
                </div>
                <Divider style={{ borderColor: colors.textTertiary }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    paddingTop: 5,
                  }}
                >
                  <MerchantMenu />
                </div>
              </>
            )
          }
          menuItemRender={(item, dom) => {
            return (
              <NavLink to={item.path} activeClassName={classes.activeLink} exact>
                {dom}
              </NavLink>
            )
          }}
          headerContentRender={({ collapsed }) => {
            return (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  paddingTop: 8,
                  paddingLeft: collapsed ? 0 : 36,
                }}
              >
                <Image
                  src='https://img.dimorder.com/414x/public/Landing_Banner/logo/dimpos-v2.png'
                  height={44}
                  width={100}
                  preview={false}
                  onClick={() => {
                    history.push('/')
                  }}
                  className={classes.logo}
                />
              </span>
            )
          }}
          rightContentRender={() => {
            return (
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <LanguageOption position='header' />
              </span>
            )
          }}
          menuFooterRender={({ collapsed }) =>
            !collapsed && (
              <Menu theme='dark'>
                <Menu.Item key='logout' onClick={doLogout}>
                  <div style={{ gap: 12, display: 'flex', alignItems: 'center' }}>
                    <LogoutOutlined />
                    <div>{t('app.common.logout')}</div>
                  </div>
                </Menu.Item>
              </Menu>
            )
          }
        >
          <Switch>
            <Route
              exact
              path={activeMerchantSetting?.enablePortalDashboard ? '/' : '/landing'}
              component={DashboardPage}
            />
            <Route exact path='/overview' component={OverviewPage} />
            <Route exact path='/transaction' component={Transaction} />
            <Route exact path='/dailyclosing' component={DailyClosing} />
            <Route exact path='/hourlysalesreport' component={HourlySalesReport} />
            <Route exact path='/salesreportbyitem' component={SalesReportByItem} />
            <Route exact path='/salesreportbycategory' component={SalesReportByCategory} />
            <Route exact path='/itemvoidreport' component={ItemVoidReport} />
            <Route exact path='/ordervoidreport' component={OrderVoidReport} />
            <Route exact path='/discountreport' component={DiscountReport} />
            <Route exact path='/paymentmethodreport' component={PaymentMethodReport} />
            <Route exact path='/customerreport' component={CustomerReport} />
            <Route exact path='/departmentreport' component={DepartmentReport} />
            <Route exact path='/alldashboard' component={AllDashboard} />
            <Route
              exact
              path={
                activeMerchantSetting?.enablePortalReportDownload &&
                !activeMerchantSetting?.enablePortalDashboard
                  ? '/'
                  : '/reportdownload'
              }
              component={ReportDownload}
            />
            <Route exact path='/dimsupplyordering' component={DimSupplyOrdering} />
            <Route exact path='/payinandoutreport' component={PayInAndOutReport} />
            <Route exact path='/menuimportexport' component={MenuImportExport} />
            <Route exact path='/dimorderstatement' component={DimorderStatement} />
            <Route exact path='/preparingtimereport' component={PreparingTimeReport} />
            <Route exact path='/maintenance' component={Maintenance} />
            <Route exact path='/optionsalesreport' component={OptionSalesReport} />
            <Route exact path='/crm' component={CRM} />
            <Route exact path='/crm/:id' component={CRMGroup} />
            <Route exact path='/crm/:id/memberlist' component={MemberList} />
            <Route exact path='/crm/:id/pointrule' component={PointRule} />
            <Route exact path='/crm/:id/rewardsetting' component={RewardSetting} />
            <Route exact path='/floorplan' component={FloorPlan} />
            <Route exact path='/octopusaccount' component={OctopusAccount} />
          </Switch>
        </ProLayout>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

const useStyles = makeStyles(() => ({
  activeLink: {
    '&::after': {
      content: '" "',
      backgroundImage: 'none',
      float: 'right',
      position: 'absolute',
      right: '0px',
      top: '6px',
      background: 'none',
      width: '0',
      height: '0',
      borderTop: '14px solid transparent',
      borderBottom: '14px solid transparent',
      borderRight: '14px solid #E8F0FF',
    },
  },
  logo: {
    cursor: 'pointer',
  },
}))
