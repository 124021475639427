import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Typography as Text, Row, Col, Image } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Loading from '@/components/Loading'
import ReportCard from '@/components/ReportCard'
import { useSelector } from '@/redux'

import BreadcrumbItem from './BreadcrumbItem'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameText: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  infoText: {
    fontSize: 16,
  },
})

export default function CRM(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const crmGroups = useSelector((state) => state.merchant.crmGroups)

  // 顯示餐廳的 card
  const GroupCard = (props) => {
    const { group } = props
    return (
      <Link
        to={{
          pathname: `/crm/${group.id}`,
          state: group,
        }}
      >
        <ReportCard>
          <Row style={{ display: 'flex', flexDirection: 'row' }}>
            <Col xs={24} md={8} className={classes.cardItem}>
              <Image
                preview={false}
                height={150}
                width={180}
                style={{ objectFit: 'cover' }}
                src={group.imageUrl}
              />
            </Col>
            <Col xs={24} md={8} className={classes.cardItem}>
              <Text className={classes.nameText}>{group.name}</Text>
            </Col>
            <Col xs={24} md={8} className={classes.cardItem} style={{ flexDirection: 'column' }}>
              {group.restautants && (
                <Text className={classes.infoText}>
                  {t('page.crm.numberOfRestaurant', { number: group.restautants })}
                </Text>
              )}
              <Text className={classes.infoText}>
                {t('page.crm.numberOfMember', { number: group.totalUserCount })}
              </Text>
            </Col>
          </Row>
        </ReportCard>
      </Link>
    )
  }

  if (_.isEmpty(crmGroups)) return <Loading />

  return (
    <PageContainer
      ghost
      header={{
        breadcrumb: {
          routes: [
            {
              path: '/crm',
              breadcrumbName: t('page.crm.title'),
            },
          ],
          itemRender: (route, params, routes, paths) => {
            return <BreadcrumbItem route={route} params={params} routes={routes} paths={paths} />
          },
        },
        title: '',
        style: {
          paddingTop: '24px',
        },
      }}
    >
      <Row className={classes.container}>
        <Col xs={24} lg={16}>
          <div style={{ gap: 20, display: 'flex', flexDirection: 'column' }}>
            {_.map(crmGroups, (group) => {
              return <GroupCard key={group.id} group={group} />
            })}
          </div>
        </Col>
      </Row>
    </PageContainer>
  )
}
