import { makeStyles } from '@material-ui/styles'
import { Typography as Text } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from '@/style/colors'

/**
 * @typedef ReportResultCountsProps
 * @property {number} counts
 */

/**
 *
 * @param {ReportResultCountsProps} props
 * @returns
 */
export default function ReportResultCounts(props) {
  const { counts = 0 } = props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const title = `${counts}${t('app.common.numberOfResults')}`

  return <Text className={classes.counts}>{title}</Text>
}

const useStyles = makeStyles(() => ({
  counts: {
    fontSize: 14,
    color: colors.button,
    marginLeft: 10,
  },
}))
