import { QuestionCircleOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Button, Col, Collapse, Modal, Row, Table, Tooltip, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import config from '@/config'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function OrderVoidReport(props) {
  const { t } = useTranslation()
  const { Panel } = Collapse
  const printRef = useRef()
  const classes = useStyles(props)
  const receiptUrl = config.receipt
  const [params, setParams] = React.useState(null)
  const [orderVoid, setOrderVoid] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const [tableFilters, setTableFilters] = React.useState({})
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const staffFilters = []
  const approverFilters = []
  const approverResults = []
  const staffResults = []
  const reasonFilters = []
  const reasonResults = []

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const orderVoid = await dimorderApi
            .instance(activeMerchant.id)
            ?.getVoidReport(params, 'orders')
          setOrderVoid(orderVoid)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(orderVoid?.data?.length)
  }, [orderVoid])

  orderVoid?.data?.forEach((o) => {
    if (o.cancelIdentifier) {
      if (!staffResults.includes(o.cancelIdentifier)) {
        staffResults.push(o.cancelIdentifier)
        staffFilters.push({
          text: o.cancelIdentifier,
          value: o.cancelIdentifier,
        })
      }
    }
    if (o.cancelApprover) {
      if (!staffResults.includes(o.cancelApprover)) {
        approverResults.push(o.cancelApprover)
        approverFilters.push({
          text: o.cancelApprover,
          value: o.cancelApprover,
        })
      }
    }
    if (o.cancelReason) {
      if (!reasonResults.includes(o.cancelReason)) {
        reasonResults.push(o.cancelReason)
        reasonFilters.push({
          text: o.cancelReason,
          value: o.cancelReason,
        })
      }
    }
  })

  const voidOrderData = {
    columns: [
      {
        title: (
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {t('app.constant.order.orderNumber')}
            <Tooltip title={t('page.orderVoidReport.orderNoTooltip')}>
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'order',
        sorter: (a, b) =>
          parseInt(a.order.orderNumber.replace('-', '')) -
          parseInt(b.order.orderNumber.replace('-', '')),
        render: (order) => {
          if (order.deliveryType === t('app.constant.deliveryType.table')) {
            return (
              <Button
                className={classes.btn}
                onClick={() => {
                  window.open(receiptUrl + `${order.orderId}`)
                }}
              >
                {order.orderNumber}
              </Button>
            )
          } else {
            return <Text>{order.orderNumber}</Text>
          }
        },
      },
      {
        title: t('app.constant.order.orderType'),
        dataIndex: 'deliveryType',
        filters: [
          {
            text: '堂食',
            value: '堂食',
          },
          {
            text: '餐廳外賣',
            value: '餐廳外賣',
          },
          {
            text: '點單外送',
            value: '點單外送',
          },
          {
            text: '點單外賣',
            value: '點單外賣',
          },
        ],
        filteredValue: tableFilters?.deliveryType || null,
        onFilter: (value, record) => record?.deliveryType === value,
      },
      {
        title: t('page.orderVoidReport.createAt'),
        dataIndex: 'createTime',
        sorter: (a, b) => new Date(a.createTime) - new Date(b.createTime),
      },
      {
        title: t('page.orderVoidReport.cancelledBy'),
        dataIndex: 'cancelIdentifier',
        filters: staffFilters,
        filteredValue: tableFilters?.cancelIdentifier || null,
        onFilter: (value, record) => record?.cancelIdentifier === value,
      },
      {
        title: t('page.orderVoidReport.approveBy'),
        dataIndex: 'cancelApprover',
        filters: approverFilters,
        filteredValue: tableFilters?.cancelApprover || null,
        onFilter: (value, record) => record?.cancelApprover === value,
      },
      {
        title: t('page.orderVoidReport.reason'),
        dataIndex: 'reason',
        filters: reasonFilters,
        filteredValue: tableFilters?.reason || null,
        onFilter: (value, record) => record?.reason === value,
      },
      {
        title: t('page.orderVoidReport.cancelledAt'),
        dataIndex: 'cancelTime',
        sorter: (a, b) => new Date(a.cancelTime) - new Date(b.cancelTime),
      },
      {
        title: t('page.orderVoidReport.cancelType'),
        dataIndex: 'cancelType',
        filters: [
          {
            text: t('page.orderVoidReport.paid'),
            value: 'paid',
          },
          {
            text: t('page.orderVoidReport.unpaid'),
            value: 'unpaid',
          },
        ],
        filteredValue: tableFilters?.cancelType || null,
        onFilter: (value, record) => record?.cancelType === value,
        render: (cancelType) => {
          if (cancelType === 'paid') {
            return <Text>{t('page.orderVoidReport.paid')}</Text>
          }
          if (cancelType === 'unpaid') {
            return <Text>{t('page.orderVoidReport.unpaid')}</Text>
          }
        },
      },
      {
        title: (
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {t('page.orderVoidReport.price')}
            <Tooltip title={t('page.orderVoidReport.salesAmountDesc')}>
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'price',
        render: (price) => <Text>{currencyWithCommas(price)}</Text>,
        sorter: (a, b) => a.price - b.price,
      },
    ],
    data: [],
  }
  if (orderVoid?.data) {
    for (const [key, value] of Object.entries(orderVoid.data)) {
      voidOrderData.data.push({
        key,
        order: {
          orderNumber: value.orderNumber,
          orderId: value.orderId,
          deliveryType: value.deliveryType,
        },
        deliveryType: value.deliveryType,
        createTime: moment(value.createdAt).format('YYYY-MM-DD HH:mm'),
        cancelIdentifier: value.cancelIdentifier || '--',
        cancelApprover: value.cancelApprover || '--',
        reason: value.cancelReason,
        cancelTime: moment(value.cancelledAt).format('YYYY-MM-DD HH:mm'),
        cancelType: value.paymentStatus,
        price: value.amount,
      })
    }
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableFilters(filters)
    setNumberOfResults(extra.currentDataSource.length)
  }

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24} className={classes.container}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={voidOrderData.columns}
            dataSource={voidOrderData.data}
            scroll={{ x: 500 }}
            onChange={handleTableChange}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.orderVoidReport.title')}
    >
      <Row gutter={16}>
        <Col span={24} className={classes.container}>
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={voidOrderData.columns}
            dataSource={voidOrderData.data}
            pagination={false}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportVoidReportCsv(params, 'orders')
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.orderVoidReport.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog filter filterTime setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {orderVoid && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  container: {
    paddingTop: 20,
  },
}))
