import dimorderApi from '@/libs/api/dimorderApi'

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )
  return JSON.parse(jsonPayload)
}

/**
 * 檢查 user 的 scope 是否 admin 或 merchant-portal-crm
 * @param {{
 * id: string
 * name: string
 * uid: string
 * identifier: string
 * }} merchant
 * @returns {Promise<Boolean>}
 */
export default async function checkCRMPermission(merchantId, username) {
  const { scopeCredentials } = await dimorderApi.instance(merchantId)?.getUserInfo()
  const userInfo = scopeCredentials.find((user) => user.identifier.trim() === username.trim())
  const hasPermission =
    userInfo?.scopes?.includes('admin') ||
    userInfo?.scopes?.includes('merchant-portal-crm') ||
    false
  return hasPermission
}
