import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Button, Col, Collapse, Modal, Row, Table, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { Doughnut } from '@/components/chart'
import config from '@/config'
import { dataColors, pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function DiscountReport(props) {
  const classes = useStyles(props)
  const printRef = useRef()
  const { t } = useTranslation()
  const { Panel } = Collapse
  const receiptUrl = config.receipt
  const [params, setParams] = React.useState(null)
  const [discountReport, setDiscountReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [reportTableFilters, setReportTableFilters] = React.useState({})
  const [numberOfDiscountReport, setNumberOfDiscountReport] = React.useState(0)
  const [detailTableFilters, setDetailTableFilters] = React.useState({})
  const [numberOfDiscountDetails, setNumberOfDiscountDetails] = React.useState(0)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const discountReportNameFilters = []
  const discountReportNameResults = []
  const discountReportTypeFilters = []
  const discountReportTypeResults = []
  const discountDetailNameFilters = []
  const discountDetailNameResults = []
  const discountDetailTypeFilters = []
  const discountDetailTypeResults = []

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const discountReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getDiscountReport(params)
          setDiscountReport(discountReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfDiscountReport(discountReport?.overview?.items?.length)
    setNumberOfDiscountDetails(discountReport?.details?.items?.length)
  }, [discountReport])

  discountReport?.overview?.items.forEach((o) => {
    if (o.name) {
      if (!discountReportNameResults.includes(o.name)) {
        discountReportNameResults.push(o.name)
        discountReportNameFilters.push({
          text: o.name,
          value: o.name,
        })
      }
    }
    if (o.typeText) {
      if (!discountReportTypeResults.includes(o.typeText)) {
        discountReportTypeResults.push(o.typeText)
        discountReportTypeFilters.push({
          text: o.typeText,
          value: o.typeText,
        })
      }
    }
  })

  discountReport?.details?.items.forEach((o) => {
    if (o.name) {
      if (!discountDetailNameResults.includes(o.name)) {
        discountDetailNameResults.push(o.name)
        discountDetailNameFilters.push({
          text: o.name,
          value: o.name,
        })
      }
    }
    if (o.typeText) {
      if (!discountDetailTypeResults.includes(o.typeText)) {
        discountDetailTypeResults.push(o.typeText)
        discountDetailTypeFilters.push({
          text: o.typeText,
          value: o.typeText,
        })
      }
    }
  })

  const discountTypeChart = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  }

  const discountTypeTable = {
    columns: [
      {
        title: t('page.discountReport.discountName'),
        dataIndex: 'name',
        filters: discountReportNameFilters,
        filteredValue: reportTableFilters?.name || null,
        onFilter: (value, record) => record?.name === value,
      },
      {
        title: t('page.discountReport.discountType'),
        dataIndex: 'type',
        filters: discountReportTypeFilters,
        filteredValue: reportTableFilters?.type || null,
        onFilter: (value, record) => record?.type === value,
      },
      {
        title: t('page.discountReport.useCount'),
        dataIndex: 'count',
        sorter: (a, b) => a.count - b.count,
      },
      {
        title: t('app.constant.order.discount'),
        dataIndex: 'amount',
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
        sorter: (a, b) => a.amount - b.amount,
      },
    ],
    data: [],
  }

  const discountDetailTable = {
    columns: [
      {
        title: t('page.discountReport.discountName'),
        dataIndex: 'name',
        filters: discountDetailNameFilters,
        filteredValue: detailTableFilters?.name || null,
        onFilter: (value, record) => record?.name === value,
      },
      {
        title: t('page.discountReport.discountType'),
        dataIndex: 'type',
        filters: discountDetailTypeFilters,
        filteredValue: detailTableFilters?.type || null,
        onFilter: (value, record) => record?.type === value,
      },
      {
        title: t('app.constant.order.orderNumber'),
        dataIndex: 'order',
        sorter: (a, b) =>
          parseInt(a.order.serial.replace('-', '')) - parseInt(b.order.serial.replace('-', '')),
        render: (order) => {
          return (
            <Button
              className={classes.btn}
              onClick={() => {
                window.open(receiptUrl + `${order.orderId}`)
              }}
            >
              {order.serial}
            </Button>
          )
        },
      },
      {
        title: t('app.common.time'),
        dataIndex: 'time',
        defaultSortOrder: 'descend',
        sorter: (a, b) => new Date(a.time) - new Date(b.time),
      },
      {
        title: t('app.constant.order.discount'),
        dataIndex: 'amount',
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
        sorter: (a, b) => a.amount - b.amount,
      },
    ],
    data: [],
  }

  function randomColor() {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
    if (randomColor === '#ffffff') return randomColor()
    return randomColor
  }

  if (discountReport?.overview?.items) {
    for (const [key, value] of Object.entries(discountReport?.overview?.items)) {
      discountTypeChart.labels.push(value.name)
      discountTypeChart.datasets[0].data.push(value.count)
      if (key <= dataColors.length - 1) {
        discountTypeChart.datasets[0].backgroundColor.push(dataColors[key])
      } else {
        discountTypeChart.datasets[0].backgroundColor.push(randomColor())
      }
      discountTypeTable.data.push({
        key,
        name: value.name,
        type: value.typeText,
        count: value.count,
        amount: value.discountAmount,
      })
    }
  }

  if (discountReport?.details?.items) {
    for (const [key, value] of Object.entries(discountReport?.details?.items)) {
      discountDetailTable.data.push({
        key,
        name: value.name,
        type: value.typeText,
        order: { serial: value.serial, orderId: value.orderId },
        time: moment(value.date).format('YYYY-MM-DD HH:mm'),
        amount: value.discount,
      })
    }
  }

  const handleReportTableChange = (pagination, filters, sorter, extra) => {
    setReportTableFilters(filters)
    setNumberOfDiscountReport(extra.currentDataSource.length)
  }

  const handleDetailTableChange = (pagination, filters, sorter, extra) => {
    setDetailTableFilters(filters)
    setNumberOfDiscountDetails(extra.currentDataSource.length)
  }

  const displayResult = () => (
    <>
      <Row gutter={16} className={classes.container}>
        <Col xs={24} xl={14}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.discountReport.usageChart')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <div className={classes.chartContainer}>
              <Doughnut
                width='80%'
                data={discountTypeChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className={classes.container}>
        <Col span={24}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.discountReport.reportTable')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfDiscountReport} />
            <br />
            <Table
              columns={discountTypeTable.columns}
              dataSource={discountTypeTable.data}
              scroll={{ x: 500 }}
              onChange={handleReportTableChange}
              summary={() => {
                const totalCount = discountReport?.overview?.totalCount
                const totalDiscount = discountReport?.overview?.totalDiscount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalCount}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className={classes.container}>
        <Col span={24}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.discountReport.detailTable')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfDiscountDetails} />
            <br />
            <Table
              columns={discountDetailTable.columns}
              dataSource={discountDetailTable.data}
              scroll={{ x: 500 }}
              onChange={handleDetailTableChange}
              summary={() => {
                const totalDiscount = discountReport?.details?.totalDiscount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.discountReport.title')}
    >
      <Row gutter={16} className={classes.printContainer}>
        <Col span={14}>
          <>
            <Text className={classes.title}>
              {t('page.discountReport.usageChart')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <div className={classes.chartContainer}>
              <Doughnut
                width='80%'
                data={discountTypeChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </>
        </Col>
      </Row>
      <Row gutter={16} className={classes.printContainer}>
        <Col span={24}>
          <>
            <Text className={classes.title}>
              {t('page.discountReport.reportTable')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfDiscountReport} />
            <br />
            <Table
              columns={discountTypeTable.columns}
              dataSource={discountTypeTable.data}
              pagination={false}
              onChange={handleReportTableChange}
              summary={() => {
                const totalCount = discountReport?.overview?.totalCount
                const totalDiscount = discountReport?.overview?.totalDiscount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalCount}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </>
        </Col>
      </Row>
      <Row gutter={16} className={classes.printContainer}>
        <Col span={24}>
          <>
            <Text className={classes.title}>
              {t('page.discountReport.detailTable')}{' '}
              {moment(params?.start).format('YYYY-MM-DD HH:mm')} {t('app.common.to')}{' '}
              {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <ReportResultCounts counts={numberOfDiscountDetails} />
            <br />
            <Table
              columns={discountDetailTable.columns}
              dataSource={discountDetailTable.data}
              pagination={false}
              onChange={handleDetailTableChange}
              summary={() => {
                const totalDiscount = discountReport?.details?.totalDiscount
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />

                      <Table.Summary.Cell />

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportDiscountReportCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.discountReport.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog filter filterTime filterOrderType filterCustomers setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {discountReport && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  title: {
    fontSize: 18,
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
  },
}))
