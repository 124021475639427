import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'

import PaymentInfo from './PaymentInfo'

export default function PaymentMethodReport(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const printRef = useRef()
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [paymentMethods, setPaymentMethods] = React.useState(null)
  const [paymentMethodReport, setPaymentMethodReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const numberOfResults = []
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  const getPaymentMethods = async () => {
    const paymentMethods = await dimorderApi.instance(activeMerchant.id)?.getPaymentMethods()
    setPaymentMethods(paymentMethods)
  }

  useEffect(() => {
    getPaymentMethods()
    setParams(null)
    setPaymentMethodReport(null)
    setActiveKey('1')
  }, [activeMerchant])

  useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const paymentMethodReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getPaymentMethodReport(params)
          setPaymentMethodReport(paymentMethodReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t])

  const reports = {
    displayResult: [],
    printResult: [],
  }

  const displayResult = (report, data) => (
    <Row gutter={16}>
      <Col span={24} className={classes.container}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <PaymentInfo report={report} data={data} />
        </Card>
      </Col>
    </Row>
  )

  const printResult = (report, data) => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.paymentMethodReport.title')}
    >
      <Row gutter={16}>
        <Col span={24} className={classes.printContainer}>
          <PaymentInfo report={report} data={data} isPrintResult />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  if (paymentMethodReport?.data?.length > 0) {
    paymentMethodReport?.data?.forEach((report, index) => {
      const data = []
      if (report.orders) {
        numberOfResults.push(report.orders.length)
        for (const [key, value] of Object.entries(report.orders)) {
          const orderData = {
            key,
            date: moment(value.date).format('YYYY-MM-DD HH:mm'),
            orderType: value.orderType,
            order: {
              serial: value.serial,
              id: value.id,
            },
            sales: value.sales,
            isDineInPayment: value.isDineInPayment,
          }

          if (report.paymentType === '八達通' || report.paymentType === 'Octopus') {
            orderData.gateway = value?.gateway

            orderData.deviceId =
              value?.gateway === 'octopusMiniPC'
                ? value?.payload && `${JSON.parse(value?.payload)?.hexDeviceId}`
                : ''
          }
          data.push(orderData)
        }
      }
      reports?.displayResult?.push(displayResult(report, data, index))
      reports?.printResult?.push(printResult(report, data, index))
    })
  } else {
    const data = null
    reports?.displayResult.push(displayResult(null, data))
    reports?.printResult.push(printResult(null, data))
  }

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportPaymentMethodReportCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.paymentMethodReport.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog
            filter
            filterPayment
            paymentMethods={paymentMethods}
            setParams={setParams}
          />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {!loading && paymentMethodReport && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {reports.displayResult}
          {reports.printResult}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  container: {
    paddingTop: 20,
  },
}))
