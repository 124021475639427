import { EditFilled, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import {
  Avatar,
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Typography as Text,
  Upload,
  message,
} from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { dummyRequest } from '@/libs/uploadFile'
import { useDispatch, useSelector } from '@/redux'
import actions from '@/redux/actions'
import colors from '@/style/colors'

export default function UserInfoModal(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { visible } = props
  const [btnLoading, setBtnLoading] = React.useState(false)
  const [uploadLoading, setUploadLoading] = React.useState(false)
  const { avatar, name, mobile } = useSelector((state) => state.user.user.data)
  const [username, setUserName] = React.useState(`${name}`)

  const onUserNameChange = (name) => {
    setUserName(name.target.value)
  }

  const onClose = () => {
    props.setVisible(false)
    setUserName(name)
  }

  const limitFileType = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(t('app.component.userInfoModal.error.file.type'))
    }
    const isLtUploadSize = file.size / 1024 / 1024 < 10
    if (!isLtUploadSize) {
      message.error(t('app.component.userInfoModal.error.file.size'))
    }
    return isJpgOrPng && isLtUploadSize
  }

  const handleUploadAvatar = async (info) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true)
    }
    if (info.file.status === 'done') {
      await dispatch(actions.user.uploadCustomerAvatar(info.file.originFileObj))
      setUploadLoading(false)
    }
  }

  function validateUserName(name) {
    const MAX_NAME_BYTE = 20
    const regexpLA = /[a-zA-z ]/
    const regexpCJK = /[\u4E00-\u9FFF\u3400-\u4DBF]/

    if (!name) {
      message.error(t('app.component.userInfoModal.error.name.empty'))
      return false
    }

    if (name.length > MAX_NAME_BYTE) {
      message.error(
        t('app.component.userInfoModal.error.name.length', {
          cjk: MAX_NAME_BYTE / 2,
          la: MAX_NAME_BYTE,
        }),
      )
      return false
    }

    let byte = 0
    const invalidChars = []
    for (const char of name) {
      if (regexpLA.test(char)) {
        byte += 1
      } else if (regexpCJK.test(char)) {
        byte += 2
      } else {
        invalidChars.push(char)
      }
    }

    if (invalidChars.length > 0) {
      message.error(t('app.component.userInfoModal.error.name.regex'))
      return false
    }

    if (byte > MAX_NAME_BYTE) {
      message.error(
        t('app.component.userInfoModal.error.name.length', {
          cjk: MAX_NAME_BYTE / 2,
          la: MAX_NAME_BYTE,
        }),
      )
      return false
    }

    return true
  }

  const handleSubmit = async () => {
    if (!validateUserName(username)) {
      return
    }
    setBtnLoading(true)
    await dispatch(actions.user.updateCustomerInfo({ name: username }))
    setBtnLoading(false)
    props.setVisible(false)
  }

  return (
    <>
      <Modal
        title={t('app.component.user.edit')}
        visible={visible}
        centered
        footer={null}
        onCancel={onClose}
        maskClosable={false}
      >
        <Spin spinning={uploadLoading}>
          <div style={{ textAlign: 'center' }}>
            <Upload
              name='avatar'
              onChange={handleUploadAvatar}
              beforeUpload={limitFileType}
              showUploadList={false}
              customRequest={dummyRequest}
            >
              <Badge count={<EditFilled style={{ fontSize: 20 }} />} offset={[-15, 60]}>
                {avatar && <Avatar src={avatar} size={70} className={classes.avatar} />}
                {!avatar && (
                  <Avatar
                    style={{
                      backgroundColor: colors.data1,
                    }}
                    icon={<UserOutlined />}
                    size={70}
                    className={classes.avatar}
                  />
                )}
              </Badge>
            </Upload>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 5,
              }}
            >
              <Col sm={5} xs={24}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginTop: 27,
                  }}
                >
                  {t('app.component.userInfoModal.name')}
                </Text>
              </Col>
              <Col sm={15} xs={24}>
                <Input
                  prefix={<UserOutlined className='site-form-item-icon' />}
                  value={username}
                  onChange={onUserNameChange}
                  size='large'
                  style={{ width: 280, marginTop: 20 }}
                  allowClear
                />
              </Col>
            </Row>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 5,
              }}
            >
              <Col sm={5} xs={24}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    marginTop: 27,
                  }}
                >
                  {t('app.component.userInfoModal.mobile')}
                </Text>
              </Col>
              <Col sm={15} xs={24}>
                <Input
                  prefix={<PhoneOutlined className='site-form-item-icon' />}
                  value={mobile.slice(4, 12)}
                  size='large'
                  style={{ width: 280, marginTop: 20 }}
                  disabled
                />
              </Col>
            </Row>
            <Button
              className={classes.btn}
              size='large'
              onClick={handleSubmit}
              loading={btnLoading}
            >
              {t('app.component.userInfoModal.update')}
            </Button>
          </div>
        </Spin>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(() => ({
  avatar: {
    '&:hover': {
      opacity: 0.7,
    },
  },
  btn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    fontSize: 14,
    color: colors.white,
    margin: 30,
    width: 130,
  },
}))
