import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useRef } from 'react'

import dimorderApi from '@/libs/api/dimorderApi'
import delay from '@/libs/delay'

// 每一頁顯示的 reward 數量
const PER_PAGE = 10

/**
 *
 * @param {string} groupId
 * @param {number} page
 * @returns
 */
function generateQueryKey(groupId, page) {
  const allPages = ['crm', 'rules', `groupId:${groupId}`]
  const currentPage = [...allPages, `page:${page}`]
  return { allPages, currentPage }
}

function usePaginatedPointRulesQueryOptions(groupId = '', page = 0) {
  const nextRef = useRef(new Map())
  const enabled = !!groupId
  const queryKey = generateQueryKey(groupId, page)
  const queryFn = () => fetchFn()
  async function fetchFn() {
    const { next, data } = await await dimorderApi.group(groupId).getPointRules({
      limit: PER_PAGE,
      after: nextRef.current.get(page - 1), // 上一頁的 next 就會是這一頁的 after
    })

    // 紀錄當前頁面的 next
    nextRef.current.set(page, next)

    // 將 locales 改用 object 並新增 name 用以在 Table 中顯示正確語言的 name
    const formatedPointRules = data.map((pointRule) => {
      const locales = {
        zh: pointRule?.locales?.find((locale) => locale.locale === 'zh') ?? {
          locale: 'zh',
          name: '',
          desc: '',
        },
        en: pointRule?.locales?.find((locale) => locale.locale === 'en') ?? {
          locale: 'en',
          name: '',
          desc: '',
        },
      }
      return { key: pointRule.id, ...pointRule, locales }
    })

    // 記錄分頁資訊
    const meta = {
      next,
      page,
      nextPage: next ? page + 1 : null,
      prevPage: page === 0 ? null : page - 1,
      perPage: PER_PAGE,
    }

    return { data: formatedPointRules, meta }
  }
  return { queryKey: queryKey.currentPage, queryFn, enabled }
}

/**
 *
 * @param {string} groupId
 * @param {number} page
 * @returns
 */
export function usePaginatedPointRulesQuery(groupId, page) {
  const quernOptions = usePaginatedPointRulesQueryOptions(groupId, page)
  const query = useQuery(quernOptions)
  return query
}

/**
 *
 * @param {string} groupId
 * @param {number} currentPage
 * @param {string} ruleId
 * @param {{ onSuccess: () => void, onError: () => void}} options
 * @returns
 */
export function usePointRuleMutation(
  groupId,
  currentPage,
  ruleId,
  { onSuccess = () => {}, onError = () => {} },
) {
  const queryClient = useQueryClient()
  const queryKey = generateQueryKey(groupId, currentPage)
  const mutation = useMutation({
    mutationFn: async (data) => {
      let result
      if (ruleId) {
        // update
        result = await dimorderApi.group(groupId).updatePointRule(ruleId, data)
      } else {
        // create
        result = await dimorderApi.group(groupId).addPointRule(data)
      }
      await delay(1000) // wait 1 second for db writing
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.allPages })
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
  return mutation
}

/**
 *
 * @param {string} groupId
 * @param {number} currentPage
 * @param {string} ruleId
 * @param {{ onSuccess: () => void, onError: () => void}} options
 * @returns
 */
export function usePointRuleDeletion(
  groupId,
  currentPage,
  ruleId,
  { onSuccess = () => {}, onError = () => {} },
) {
  const queryClient = useQueryClient()
  const queryKey = generateQueryKey(groupId, currentPage)
  const mutation = useMutation({
    mutationFn: async () => {
      if (ruleId) {
        // delete
        const result = await dimorderApi.group(groupId).deletePointRule(ruleId)
        await delay(1000) // wait 1 second for db writing
        return result
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.allPages })
      onSuccess()
    },
    onError: () => {
      onError()
    },
  })
  return mutation
}
