import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row, Table, Typography as Text } from 'antd'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { Bar } from '@/components/chart'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas, numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function HourlySalesReport(props) {
  const printRef = useRef()
  const classes = useStyles(props)
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [salesReport, setSalesReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const { t } = useTranslation()
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: t('app.constant.order.totalSales'),
          color: colors.data1,
          font: {
            size: 14,
          },
        },
        ticks: {
          color: colors.data1,
          font: {
            size: 14,
          },
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        title: {
          display: true,
          text: t('page.hourlySalesReport.transaction'),
          color: colors.data5,
          font: {
            size: 14,
          },
        },
        ticks: {
          color: colors.data5,
          font: {
            size: 14,
          },
        },
      },
    },
  }

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const salesReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getHourlySalesReport(params)
          setSalesReport(salesReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(salesReport?.main?.byHour.length)
  }, [salesReport])

  const salesReportChart = {
    labels: [],
    datasets: [
      {
        type: 'line',
        label: t('page.hourlySalesReport.report.transaction'),
        borderColor: colors.data5,
        backgroundColor: colors.white,
        borderWidth: 3,
        fill: false,
        data: [],
        yAxisID: 'y1',
        order: 2,
      },
      {
        type: 'bar',
        label: t('page.hourlySalesReport.report.totalSales'),
        backgroundColor: colors.data1,
        data: [],
        yAxisID: 'y',
        order: 3,
      },
    ],
  }

  const reportData = {
    columns: [
      {
        title: t('app.common.time'),
        dataIndex: 'time',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => parseInt(a.time.slice(0, 5)) - parseInt(b.time.slice(0, 5)),
      },
      {
        title: t('app.constant.order.totalSales'),
        dataIndex: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
      },
      {
        title: t('app.constant.order.discount'),
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
        render: (discount) => <Text>{currencyWithCommas(discount)}</Text>,
      },
      {
        title: t('app.constant.order.surcharge'),
        dataIndex: 'surcharge',
        sorter: (a, b) => a.surcharge - b.surcharge,
        render: (surcharge) => <Text>{currencyWithCommas(surcharge)}</Text>,
      },
      {
        title: t('page.hourlySalesReport.transaction'),
        dataIndex: 'transaction',
        sorter: (a, b) => a.transaction - b.transaction,
      },
      {
        title: t('page.hourlySalesReport.customers'),
        dataIndex: 'customers',
        sorter: (a, b) => a.customers - b.customers,
      },
    ],
    data: [],
  }

  const compareData = {
    columns: [
      {
        title: t('app.common.time'),
        dataIndex: 'time',
        defaultSortOrder: 'ascend',
        sorter: (a, b) => parseInt(a.time.slice(0, 5)) - parseInt(b.time.slice(0, 5)),
      },
      {
        title: t('app.constant.order.totalSales'),
        dataIndex: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
      },
      {
        title: t('app.constant.order.discount'),
        dataIndex: 'discount',
        sorter: (a, b) => a.discount - b.discount,
        render: (discount) => <Text>{currencyWithCommas(discount)}</Text>,
      },
      {
        title: t('app.constant.order.surcharge'),
        dataIndex: 'surcharge',
        sorter: (a, b) => a.surcharge - b.surcharge,
        render: (surcharge) => <Text>{currencyWithCommas(surcharge)}</Text>,
      },
      {
        title: t('page.hourlySalesReport.transaction'),
        dataIndex: 'transaction',
        sorter: (a, b) => a.transaction - b.transaction,
      },
      {
        title: t('page.hourlySalesReport.customers'),
        dataIndex: 'customers',
        sorter: (a, b) => a.customers - b.customers,
      },
    ],
    data: [],
  }

  if (salesReport?.main) {
    for (const [key, value] of Object.entries(salesReport.main.byHour)) {
      salesReportChart.labels.push(`${value.hourStart} - ${value.hourEnd}`)
      salesReportChart.datasets[0].data.push(value.numTransactions)
      salesReportChart.datasets[1].data.push(value.totalSales)
      reportData.data.push({
        key,
        time: `${value.hourStart} - ${value.hourEnd}`,
        amount: value.totalSales,
        discount: value.totalDiscount,
        surcharge: value.totalSurcharge,
        transaction: value.numTransactions,
        customers: value.numCustomers,
      })
    }
  }

  if (params?.compare) {
    salesReportChart.datasets.push(
      {
        type: 'line',
        label: t('page.hourlySalesReport.compare.transaction'),
        borderColor: colors.data8,
        backgroundColor: colors.white,
        borderWidth: 3,
        fill: false,
        data: [],
        yAxisID: 'y1',
        order: 1,
      },
      {
        type: 'bar',
        label: t('page.hourlySalesReport.compare.totalSales'),
        backgroundColor: colors.data3,
        data: [],
        yAxisID: 'y',
        order: 4,
      },
    )
    if (salesReport?.compare) {
      for (const [key, value] of Object.entries(salesReport.compare.byHour)) {
        salesReportChart.datasets[2].data.push(value.numTransactions)
        salesReportChart.datasets[3].data.push(value.totalSales)
        compareData.data.push({
          key,
          time: `${value.hourStart} - ${value.hourEnd}`,
          amount: value.totalSales,
          discount: value.totalDiscount,
          surcharge: value.totalSurcharge,
          transaction: value.numTransactions,
          customers: value.numCustomers,
        })
      }
    }
  }

  const displayResult = () => (
    <>
      <Row gutter={16}>
        <Col span={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='center'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.hourlySalesReport.chart')}</Text>
            <Bar data={salesReportChart} options={options} height={350} width='98%' />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={params.compare ? 12 : 24} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>{t('page.hourlySalesReport.report.table')}</Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={reportData.columns}
              dataSource={reportData.data}
              scroll={{ x: 500 }}
              pagination={false}
              summary={() => {
                const totalSales = salesReport?.main?.total?.sales
                const totalCustomerCount = salesReport?.main?.total?.numCustomers
                const totalTransactionConut = salesReport?.main?.total?.numTransactions
                const totalDiscount = salesReport?.main?.total?.discount
                const totalSurcharge = salesReport?.main?.total?.surcharge
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{currencyWithCommas(totalSales)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalSurcharge)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {numberWithCommas(totalTransactionConut)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {numberWithCommas(totalCustomerCount)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </Col>
        {params.compare && (
          <Col xl={12} xs={24} className={classes.container}>
            <Card
              width='100%'
              height='100%'
              padding='20px'
              borderRadius='2px'
              textAlign='left'
              boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
            >
              <Text className={classes.title}>{t('page.hourlySalesReport.compare.table')}</Text>
              <br />
              <ReportResultCounts counts={numberOfResults} />
              <br />
              <Table
                columns={compareData.columns}
                dataSource={compareData.data}
                scroll={{ x: 500 }}
                pagination={false}
                summary={() => {
                  const totalSales = salesReport?.compare?.total?.sales
                  const totalCustomerCount = salesReport?.compare?.total?.numCustomers
                  const totalTransactionConut = salesReport?.compare?.total?.numTransactions
                  const totalDiscount = salesReport?.compare?.total?.discount
                  const totalSurcharge = salesReport?.compare?.total?.surcharge
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {t('page.salesReportByItem.total')}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSales)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalDiscount)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSurcharge)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalTransactionConut}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalCustomerCount}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </Card>
          </Col>
        )}
      </Row>
    </>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.hourlySalesReport.title')}
    >
      <Row gutter={16}>
        <Col span={24} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.hourlySalesReport.chart')}</Text>
            <Bar data={salesReportChart} options={options} height={350} width={1200} />
          </>
        </Col>
      </Row>
      <Row gutter={60}>
        <Col span={params.compare ? 12 : 24} className={classes.printContainer}>
          <>
            <Text className={classes.title}>{t('page.hourlySalesReport.report.table')}</Text>
            <br />
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={reportData.columns}
              dataSource={reportData.data}
              scroll={{ x: 500 }}
              pagination={false}
              summary={() => {
                const totalSales = salesReport?.main?.total?.sales
                const totalCustomerCount = salesReport?.main?.total?.numCustomers
                const totalTransactionConut = salesReport?.main?.total?.numTransactions
                const totalDiscount = salesReport?.main?.total?.discount
                const totalSurcharge = salesReport?.main?.total?.surcharge
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {t('page.salesReportByItem.total')}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{currencyWithCommas(totalSales)}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalDiscount)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(totalSurcharge)}
                        </Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalTransactionConut}</Text>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{totalCustomerCount}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </>
        </Col>
        {params.compare && (
          <Col span={12} className={classes.printContainer}>
            <>
              <Text className={classes.title}>{t('page.hourlySalesReport.compare.table')}</Text>
              <br />
              <ReportResultCounts counts={numberOfResults} />
              <br />
              <Table
                columns={compareData.columns}
                dataSource={compareData.data}
                scroll={{ x: 500 }}
                pagination={false}
                summary={() => {
                  const totalSales = salesReport?.compare?.total?.sales
                  const totalCustomerCount = salesReport?.compare?.total?.numCustomers
                  const totalTransactionConut = salesReport?.compare?.total?.numTransactions
                  const totalDiscount = salesReport?.compare?.total?.discount
                  const totalSurcharge = salesReport?.compare?.total?.surcharge
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {t('page.salesReportByItem.total')}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSales)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalDiscount)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>
                            {currencyWithCommas(totalSurcharge)}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalTransactionConut}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text className={classes.totalText}>{totalCustomerCount}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </>
          </Col>
        )}
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportSalesReportByHourCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.hourlySalesReport.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog compare skipCompareTime filter filterOrderType setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {salesReport && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  container: {
    paddingTop: 20,
  },
  bigTitle: {
    fontSize: 24,
    textAlign: 'center',
  },
  title: {
    fontSize: 18,
    paddingBottom: 10,
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
  },
}))
