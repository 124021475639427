import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router'

const useStyles = makeStyles({
  cardContainer: {
    display: 'table',
    width: '100%',
    maxWidth: 1024,
    borderRadius: 10,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    '@media(max-width: 1024px)': {
      backgroundImage: (props) => {
        const image = props.image
        return `url(${image.cocaCola.x1200})`
      },
      paddingBottom: '30%',
    },

    '@media(min-width: 1025px)': {
      backgroundImage: (props) => {
        const image = props.image
        return `url(${image.cocaCola.x2048})`
      },
      paddingBottom: '9.8%',
    },

    '@media(min-width: 1320px)': {
      backgroundImage: (props) => {
        const image = props.image
        return `url(${image.cocaCola.x2048})`
      },
      paddingBottom: 0,
      height: 100,
    },
  },
})

export default function BannerCard(props) {
  const classes = useStyles(props)
  const history = useHistory()

  const onClick = () => {
    history.push('/dimsupplyordering')
  }

  return <figure className={classes.cardContainer} onClick={onClick} />
}
