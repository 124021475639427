import Logger from '@dimorder/orderapp-lib/dist/libs/Logger'

import config from '@/config'
import shortCode from '@/libs/shortCode'

export const logId = shortCode.generate(4)

const gcpLogger = new Logger('https://orderapp-logger-ssj7hdeukq-de.a.run.app/log', {
  // buildNumber: string,
  environment: config.env,
  client: 'MERCHANT_PORTAL',
  logId,
})

const nativeSupportLogLevel = ['debug', 'error', 'info', 'log', 'trace', 'warn']

const logger = {}

nativeSupportLogLevel.forEach((level) => {
  logger[level] = (...args) => {
    const [message, data] = args
    console.log(...args)

    gcpLogger.log(level, {
      message,
      ...data,
    })
  }
})

export default logger
