import { ArrowsAltOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Rnd } from 'react-rnd'

import { FLOOR_PLAN, SHAPE } from '@/constants'

export default function FloorPlanPreview(props) {
  const { areaSize, tables, selectedTableName, setSelectedTableName, updateTable } = props

  const useStyles = makeStyles({
    floorPlan: {
      position: 'relative',
      padding: 10,
      width: areaSize?.width || FLOOR_PLAN[10.2].width,
      height: areaSize?.height || FLOOR_PLAN[10.2].height,
      backgroundSize: `${FLOOR_PLAN.grid}px ${FLOOR_PLAN.grid}px`,
      backgroundImage:
        'linear-gradient(#e3e3e3 2px, transparent 0, transparent calc(100% - 2px), #e3e3e3 0), linear-gradient(90deg, #e3e3e3 2px, transparent 0, transparent calc(100% - 2px), #e3e3e3 0)',
    },
  })

  const classes = useStyles(props)

  // 調整 x-coordinate
  const adjustedX = (x, width) => {
    if (x === 0) return 2
    if (x + width === areaSize.width) return x - 2
    return x
  }

  // 調整 y-coordinate
  const adjustedY = (y, height) => {
    if (y === 0) return 2
    if (y + height === areaSize.height) return y - 2
    return y
  }

  // 更改餐檯位置
  const updateTablePosition = (data, tableName) => {
    const [{ ...table }] = tables.filter(({ name }) => name === tableName)
    table.x = adjustedX(data.x, table.width)
    table.y = adjustedY(data.y, table.height)
    updateTable(table)
  }

  // 更改餐檯大小
  const updateTableSize = (size, tableName) => {
    const prevTable = tables.find(({ name }) => name === tableName)
    const [{ ...table }] = tables.filter(({ name }) => name === tableName)
    table.width = parseInt(size.width.split('px')[0]) - 4
    table.height = parseInt(size.height.split('px')[0]) - 4
    if (!(prevTable.width - 4 === table.width && prevTable.height - 4 === table.height))
      updateTable(table)
  }

  return (
    <div className={classes.floorPlan}>
      {tables?.map((table, i) => {
        return (
          <Rnd
            key={i}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: selectedTableName === table.name ? '#b0bec5' : '#78909c',
              zIndex: selectedTableName === table.name ? 1 : 0,
              borderRadius:
                table.shape === SHAPE.CIRCLE || table.shape === SHAPE.OVAL ? table.width / 2 : 5,
            }}
            bounds='parent'
            dragGrid={[FLOOR_PLAN.grid, FLOOR_PLAN.grid]}
            resizeGrid={[FLOOR_PLAN.grid, FLOOR_PLAN.grid]}
            minWidth={FLOOR_PLAN.minTableSize}
            minHeight={FLOOR_PLAN.minTableSize}
            size={{ width: table.width, height: table.height }}
            position={{ x: table.x, y: table.y }}
            lockAspectRatio
            enableResizing={{
              bottomRight: selectedTableName === table.name,
            }}
            onDragStart={() => setSelectedTableName(table.name)}
            onDragStop={(e, data) => updateTablePosition(data, table.name)}
            onResizeStop={(e, dir, refToElement, delta, position) => {
              updateTableSize(refToElement.style, table.name)
            }}
          >
            <div
              style={{
                fontSize: 20,
                color: 'white',
                fontWeight: selectedTableName === table.name ? 'bold' : null,
                userSelect: 'none',
              }}
            >
              {table.name}
            </div>
            {selectedTableName === table.name && (
              <ArrowsAltOutlined
                rotate={90}
                style={{
                  fontSize: 16,
                  position: 'absolute',
                  bottom: -8,
                  right: -8,
                }}
              />
            )}
          </Rnd>
        )
      })}
    </div>
  )
}
