/* eslint-disable import/no-anonymous-default-export */
import * as app from './app/actions'
import * as merchant from './merchant/actions'
import * as user from './user/actions'

export default {
  app,
  user,
  merchant,
}
