import { makeStyles } from '@material-ui/styles'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import colors from '@/style/colors'

import DurationFilterLabel from './DurationFilterLabel'

/**
 * @typedef DurationFilterButtonProps
 * @property {string} start 2022-08-18T16:00:00.000Z
 * @property {string} end 2022-08-19T15:59:00.000Z
 * @property {string} activeKey
 */

/**
 *
 * @param {DurationFilterButtonProps} props
 * @returns
 */
export default function DurationFilterButton(props) {
  const { start, end, showLabel = true, activeKey = '' } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  const title = activeKey.includes('1')
    ? t('app.component.filterDialog.hideFilter')
    : t('app.component.filterDialog.showFilter')

  return (
    <div className={classes.container}>
      <DurationFilterLabel show={showLabel} start={start} end={end} />
      <Button className={classes.button}>{title}</Button>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  button: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
    marginLeft: 10,
    fontSize: 13,
  },
}))
