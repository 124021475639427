import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Row, Modal } from 'antd'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import ReportCard from 'src/components/ReportCard'
import dimorderApi from 'src/libs/api/dimorderApi'

import { FLOOR_PLAN } from '@/constants'
import { useSelector } from '@/redux'

import AreaSection from './AreaSection'
import ButtonList from './ButtonList'
import FloorPlanPreview from './FloorPlanPreview'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  floorPlanEditor: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 10,
  },
})

export default function FloorPlan(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [floorPlanModified, setFloorPlanModified] = useState(false) // 平面圖是否被更改
  const [merchantTables, setMerchantTables] = useState([]) // 餐廳所有在檯號群的餐檯
  const [tableGeometry, setTableGeometry] = useState([]) // 已設定好的平面圖
  const [enableFloorPlan, setEnableFloorPlan] = useState(false)

  // 還沒有新增的餐檯
  const remainingTables = useMemo(() => {
    const mappedTables = tableGeometry.flatMap((area) => area.tables.map((table) => table.name))
    return merchantTables.filter((table) => !mappedTables.includes(table))
  }, [merchantTables, tableGeometry])
  // 所有區域的名稱
  const areas = useMemo(() => tableGeometry.map((area) => area.name), [tableGeometry])
  // 編輯中區域的名稱
  const [selectedAreaName, setSelectedAreaName] = useState(
    tableGeometry ? tableGeometry[0]?.name : '',
  )
  // 編輯中區域的尺寸
  const areaSize = useMemo(
    () => tableGeometry.find(({ name }) => name === selectedAreaName)?.size,
    [tableGeometry, selectedAreaName],
  )
  // 編輯中區域中的所有餐檯
  const tables = useMemo(
    () => tableGeometry.find(({ name }) => name === selectedAreaName)?.tables,
    [tableGeometry, selectedAreaName],
  )
  // 編輯中餐檯的名稱
  const [selectedTableName, setSelectedTableName] = useState()
  // 編輯中的餐檯
  const selectedTable = useMemo(
    () => tables?.find(({ name }) => selectedTableName === name),
    [tableGeometry, tables, selectedTableName],
  )

  // 新增區域
  const onAddArea = (value) => {
    setTableGeometry((prevFloorPlan) => [
      ...prevFloorPlan,
      { name: value.name, size: FLOOR_PLAN[value.size], tables: [] },
    ])
    setSelectedAreaName(value.name)
    setFloorPlanModified(true)
  }

  // 刪除區域
  const onDeleteArea = () => {
    const updatedTableGeometry = tableGeometry.filter((area) => area.name !== selectedAreaName)
    setTableGeometry(updatedTableGeometry)
    setSelectedAreaName(updatedTableGeometry[0]?.name)
    setFloorPlanModified(true)
  }

  // 點選區域
  const onSelectArea = (areaName) => {
    setSelectedAreaName(areaName)
    setSelectedTableName()
  }

  // 檢查 selectedTable 會否與其他 table 重疊
  const isOverlapped = (tables, selectedTable) => {
    return (
      tables
        .map((table) => {
          return (
            (selectedTable.x < table.x + table.width &&
              table.x < selectedTable.x + selectedTable.width &&
              selectedTable.y < table.y + table.height &&
              table.y < selectedTable.y + selectedTable.height) ||
            selectedTable.x + selectedTable.width > areaSize.width ||
            selectedTable.y + selectedTable.height > areaSize.height
          )
        })
        .filter((value) => value).length >= 1
    )
  }

  // 尋找適合放該 table 的位置
  const findTableCoordinate = (updatedTables, table, unsuccessfulTables) => {
    for (let i = 2; i <= areaSize.height - table.height; i += FLOOR_PLAN.grid) {
      for (let j = 2; j <= areaSize.width - table.width; j += FLOOR_PLAN.grid) {
        table.x = j
        table.y = i
        if (!isOverlapped(updatedTables, table)) {
          updatedTables.push(table)
          setFloorPlanModified(true)
          return
        }
      }
    }
    unsuccessfulTables.push(table.name)
  }

  // 新增餐檯
  const onAddTable = (value) => {
    value.x = 2
    value.y = 2
    const updatedTables = tables
    const unsuccessfulTables = []
    for (let i = 0; i < value.name.length; i++) {
      const table = Object.assign({}, value)
      delete table.name
      table.name = value.name[i]
      findTableCoordinate(updatedTables, table, unsuccessfulTables)
      setSelectedTableName(table.name)
    }
    setTableGeometry(
      tableGeometry.map((area) => {
        if (area.name === selectedAreaName) return { ...area, tables: updatedTables }
        return area
      }),
    )
    if (unsuccessfulTables.length !== 0) handleNotEnoughSpace(unsuccessfulTables)
  }

  // 刪除餐檯
  const onDeleteTable = () => {
    const updatedTables = tables.filter(({ name }) => !(selectedTableName === name))
    setTableGeometry(
      tableGeometry.map((area) => {
        if (area.name === selectedAreaName) return { ...area, tables: updatedTables }
        return area
      }),
    )
    setSelectedTableName()
    setFloorPlanModified(true)
  }

  // 編輯餐檯
  const updateTable = (pendingTable) => {
    const updatedTables = tables
    if (
      !isOverlapped(
        tables.filter(({ name }) => name !== pendingTable.name),
        pendingTable,
      )
    ) {
      updatedTables.splice(
        updatedTables.findIndex((updatedTable) => updatedTable.name === pendingTable.name),
        1,
        pendingTable,
      )
      setTableGeometry(
        tableGeometry.map((area) => {
          if (area.name === selectedAreaName) return { ...area, tables: updatedTables }
          return area
        }),
      )
      setFloorPlanModified(true)
    } else {
      handleOverlapped(pendingTable.name)
    }
  }

  // 屏幕尺寸太小不支援編輯餐桌平面圖
  const ScreenSizeNotSupported = () => {
    return (
      <Col xs={24} md={0}>
        <div style={{ textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
          {t('page.floorPlan.modal.screenSizeNotSupported.title')}
        </div>
        <div style={{ textAlign: 'center', fontSize: 18 }}>
          {t('page.floorPlan.modal.screenSizeNotSupported.message')}
        </div>
      </Col>
    )
  }

  const handleNotEnoughSpace = (unsuccessfulTables = []) => {
    Modal.warning({
      title: t('page.floorPlan.modal.notEnoughSpace.title'),
      content: t('page.floorPlan.modal.notEnoughSpace.message', {
        tables: unsuccessfulTables.join(', '),
      }),
    })
  }

  const handleOverlapped = (unsuccessfulTable) => {
    Modal.warning({
      title: t('page.floorPlan.modal.overlapped.title'),
      content: t('page.floorPlan.modal.overlapped.message', {
        table: unsuccessfulTable,
      }),
    })
  }

  // 儲存平面圖
  const onUpdateTableGeometry = async () => {
    try {
      if (floorPlanModified) {
        await dimorderApi.instance(activeMerchant.id)?.updateTableGeometry(tableGeometry)
        await dimorderApi.instance(activeMerchant.id)?.enableFloorPlan(enableFloorPlan)
        setFloorPlanModified(false)
        Modal.success({
          title: t('page.floorPlan.modal.saveSuccess'),
        })
      } else {
        Modal.warning({
          title: t('page.floorPlan.modal.noChange'),
        })
      }
    } catch (error) {
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.submitError'),
      })
    }
  }

  // 開關平面圖
  const onUpdateEnableFloorPlan = () => {
    setEnableFloorPlan(!enableFloorPlan)
    setFloorPlanModified(true)
  }

  // 餐廳所有包含在檯號群的餐檯和已設定的平面圖
  useEffect(() => {
    console.log('getMerchant in FloorPlan')
    const getTableGroups = async () => {
      const merchantData = await dimorderApi.instance(activeMerchant.id)?.getMerchant()
      const { tables, tableGeometry, setting } = merchantData
      const { enableFloorPlan } = setting
      setMerchantTables(tables)
      setTableGeometry(tableGeometry ?? [])
      setSelectedAreaName(tableGeometry ? tableGeometry[0]?.name : '')
      setEnableFloorPlan(enableFloorPlan)
    }
    getTableGroups()
  }, [activeMerchant])

  // 避免 user 沒有儲存的時候離開頁面
  useEffect(() => {
    const handler = (event) => {
      event.preventDefault()
      event.returnValue = ''
    }

    if (floorPlanModified) {
      window.addEventListener('beforeunload', handler)
      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
    return () => {}
  }, [floorPlanModified])

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.floorPlan.title'),
      }}
    >
      <Prompt when={floorPlanModified} message={t('page.floorPlan.unsavedChanges')} />
      <ScreenSizeNotSupported />
      <Row className={classes.container}>
        <Col xs={0} md={24} xxl={20}>
          <ReportCard>{t('page.floorPlan.versionReminder')}</ReportCard>
          <div style={{ height: '20px' }} />
          <ReportCard>
            <div className={classes.floorPlanEditor}>
              <ButtonList
                areaSize={areaSize}
                onAddTable={onAddTable}
                onDeleteTable={onDeleteTable}
                selectedTable={selectedTable}
                selectedAreaName={selectedAreaName}
                updateTable={updateTable}
                remainingTables={remainingTables}
                onUpdateTableGeometry={onUpdateTableGeometry}
                enableFloorPlan={enableFloorPlan}
                onUpdateEnableFloorPlan={onUpdateEnableFloorPlan}
              />
              <FloorPlanPreview
                areaSize={areaSize}
                tables={tables}
                selectedTableName={selectedTableName}
                setSelectedTableName={setSelectedTableName}
                updateTable={updateTable}
              />
            </div>
            <AreaSection
              areas={areas}
              areaSize={areaSize}
              selectedAreaName={selectedAreaName}
              onAddArea={onAddArea}
              onDeleteArea={onDeleteArea}
              onSelectArea={onSelectArea}
            />
          </ReportCard>
        </Col>
      </Row>
    </PageContainer>
  )
}
