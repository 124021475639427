import { SearchOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { Button, Col, Collapse, Input, Modal, Row, Space, Table, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { numberWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function PayInAndOutReport(props) {
  const { t } = useTranslation()
  const printRef = useRef()
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [payInAndOutReport, setPayInAndOutReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [reasonSearchText, setReasonSearchText] = React.useState()
  const [tableFilters, setTableFilters] = React.useState({})
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [numberOfResults, setNumberOfResults] = React.useState(0)

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const payInAndOutReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getPayInAndOutReport(params)
          setPayInAndOutReport(payInAndOutReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(payInAndOutReport?.length)
  }, [payInAndOutReport])

  let searchInput = null

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`${t('app.common.search')}${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            {t('app.common.search')}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            {t('app.common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : colors.textSecondary,
          fontSize: 15,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'reason') {
        return record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : ''
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    if (dataIndex === 'reason') {
      setReasonSearchText(selectedKeys[0])
    }
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    if (dataIndex === 'reason') {
      setReasonSearchText('')
    }
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableFilters(filters)
    setNumberOfResults(extra.currentDataSource.length)
  }

  const displayReason = (reason) => {
    const reasons = {
      food: t('page.payInAndOutReport.reasons.food'),
      cleaningSupplies: t('page.payInAndOutReport.reasons.cleaningSupplies'),
      kitchenware: t('page.payInAndOutReport.reasons.kitchenware'),
      tableware: t('page.payInAndOutReport.reasons.tableware'),
      stationery: t('page.payInAndOutReport.reasons.stationery'),
    }

    return reasons[reason] ?? reason
  }

  const displayType = (type) => {
    const types = {
      in: t('page.payInAndOutReport.types.in'),
      out: t('page.payInAndOutReport.types.out'),
    }

    return types[type] ?? type
  }

  const tableData = {
    columns: [
      {
        title: t('page.payInAndOutReport.reason'),
        dataIndex: 'reason',
        ...getColumnSearchProps('reason', '原因'),
        filteredValue: tableFilters?.reason || null,
        render: (item) => {
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[reasonSearchText]}
              autoEscape
              textToHighlight={item ? item.toString() : ''}
            />
          )
        },
      },
      {
        title: t('page.payInAndOutReport.type'),
        dataIndex: 'type',
        filters: [
          {
            text: t('page.payInAndOutReport.types.in'),
            value: 'in',
          },
          {
            text: t('page.payInAndOutReport.types.out'),
            value: 'out',
          },
        ],
        filteredValue: tableFilters?.type || null,
        onFilter: (value, record) => record?.type === value,
        render: (type) => {
          return <Text>{displayType(type)}</Text>
        },
      },
      {
        title: t('page.payInAndOutReport.date'),
        dataIndex: 'date',
        sorter: (a, b) => new Date(a.date) - new Date(b.date),
      },
      {
        title: t('page.payInAndOutReport.amount'),
        dataIndex: 'amount',
        sorter: (a, b) => a.amount - b.amount,
        render: (amount) => {
          return <Text>{`$${numberWithCommas(amount)}`}</Text>
        },
      },
    ],
    data: [],
  }

  if (payInAndOutReport) {
    for (const [, value] of Object.entries(payInAndOutReport)) {
      tableData.data.push({
        key: value.id,
        reason: displayReason(value.reason),
        type: value.type,
        date: moment(value.createdAt).format('YYYY-MM-DD HH:mm'),
        amount: value.amount,
      })
    }
  }

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={tableData.columns}
            dataSource={tableData.data}
            // scroll={{ x: 1500 }}
            onChange={handleTableChange}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.payInAndOutReport.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={tableData.columns}
            dataSource={tableData.data}
            pagination={false}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportPayInAndOutReportCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.payInAndOutReport.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog momentFormatX setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {payInAndOutReport && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}
