import produce from 'immer'

import ActionTypes from './ActionTypes'

/** @type {IMerchantState} */
export const initialState = {
  isInit: false,
  merchants: [],
  activeMerchant: null,
  crmGroups: {},
  merchantLoginDialog: {
    visible: false,
    data: {
      title: '',
      merchantName: '',
      merchantShortCode: '',
      username: '',
    },
    firstLogin: false,
    onSuccess: () => {},
  },
}

export default produce((draft, action) => {
  switch (action?.type) {
    case ActionTypes.INIT: {
      draft.isInit = true
      break
    }

    case 'LOGOUT': {
      return initialState
    }

    case ActionTypes.ADD_MERCHANT: {
      draft.merchants = action.payload
      break
    }

    case ActionTypes.ACTIVE_MERCHANT: {
      draft.activeMerchant = action.payload
      break
    }

    case ActionTypes.UPDATE_CRM_GROUPS: {
      draft.crmGroups = action.payload.groups
      break
    }

    case ActionTypes.UPDATE_CRM_GROUP: {
      const { group } = action.payload
      const existedGroupId = draft.crmGroups.findIndex((g) => g?.id === group.id)
      if (existedGroupId === -1) {
        draft.crmGroups.push(group)
      } else {
        draft.crmGroups.splice(existedGroupId, 1, group)
      }
      break
    }

    case ActionTypes.OPEN_MERCHANT_LOGIN_DIALOG: {
      const { data, firstLogin, onSuccess } = action.payload
      draft.merchantLoginDialog.visible = true
      draft.merchantLoginDialog.data = data
      draft.merchantLoginDialog.firstLogin = firstLogin
      draft.merchantLoginDialog.onSuccess = onSuccess
      break
    }

    case ActionTypes.CLOSE_MERCHANT_LOGIN_DIALOG: {
      draft.merchantLoginDialog = initialState.merchantLoginDialog
      break
    }

    default:
      break
  }
}, initialState)
