import { makeStyles } from '@material-ui/styles'
import { Col, Row, Typography as Text } from 'antd'

export default function SummaryRow(props) {
  const { title, count, amount, textStyle } = props
  const classes = useStyles(props)

  return (
    <Row className={classes.tableRow}>
      <Col xl={8} md={8} sm={10} xs={14} className={classes.title}>
        <Text className={classes.text} style={textStyle}>
          {title}
        </Text>
      </Col>
      <Col xl={2} md={2} sm={2} xs={4} className={classes.count}>
        <Text className={classes.text} style={textStyle}>
          {count}
        </Text>
      </Col>
      <Col xl={4} md={6} sm={6} xs={6} className={classes.amount}>
        <Text className={classes.text} style={textStyle}>
          {amount}
        </Text>
      </Col>
    </Row>
  )
}

const useStyles = makeStyles(() => ({
  tableRow: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  amount: {
    textAlign: 'right',
  },
  count: {
    textAlign: 'center',
  },
  title: {},
  text: {
    fontSize: 16,
  },
}))
