import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Col,
  Collapse,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography as Text,
} from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import config from '@/config'
import { landscapePageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function PreparingTimeReport(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const printRef = useRef()
  const { Panel } = Collapse
  const receiptUrl = config.receipt
  const [params, setParams] = React.useState(null)
  const [preparingTimeReport, setPreparingTimeReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const [itemNameSearchText, setItemNameSearchText] = React.useState()
  const [orderNoSearchText, setOrderNoSearchText] = React.useState()
  const [tableFilters, setTableFilters] = React.useState({})
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const categoryResults = []
  const categoryFilters = []
  const paymentMethodFilters = []

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const preparingTimeReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getPreparingTimeReport(params)
          setPreparingTimeReport(preparingTimeReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  React.useEffect(() => {
    setNumberOfResults(preparingTimeReport?.items.length)
  }, [preparingTimeReport])

  preparingTimeReport?.items.forEach((o) => {
    if (o.category) {
      if (!categoryResults.includes(o.category)) {
        categoryResults.push(o.category)
        categoryFilters.push({
          text: o.category,
          value: o.category,
        })
      }
    }
  })

  categoryFilters.sort((a, b) => parseInt(a.value) - parseInt(b.value))
  paymentMethodFilters.sort()

  let searchInput = null

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node
          }}
          placeholder={`${t('app.common.search')}${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            {t('app.common.search')}
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, dataIndex)}
            size='small'
            style={{ width: 90 }}
          >
            {t('app.common.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : colors.textSecondary,
          fontSize: 15,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'order') {
        return record[dataIndex]
          ? record[dataIndex].orderNumber.toString().toLowerCase().includes(value.toLowerCase())
          : ''
      }
      if (dataIndex === 'item') {
        return record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : ''
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    },
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    if (dataIndex === 'item') {
      setItemNameSearchText(selectedKeys[0])
    }
    if (dataIndex === 'order') {
      setOrderNoSearchText(selectedKeys[0])
    }
  }

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    if (dataIndex === 'item') {
      setItemNameSearchText('')
    }
    if (dataIndex === 'order') {
      setOrderNoSearchText('')
    }
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setTableFilters(filters)
    setNumberOfResults(extra.currentDataSource.length)
  }

  const tableData = {
    columns: [
      {
        title: t('page.preparingTimeReport.item'),
        dataIndex: 'item',
        ...getColumnSearchProps('item', t('page.preparingTimeReport.item')),
        filteredValue: tableFilters?.item || null,
        render: (item) => {
          return (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[itemNameSearchText]}
              autoEscape
              textToHighlight={item ? item.toString() : ''}
            />
          )
        },
      },
      {
        title: t('page.preparingTimeReport.category'),
        dataIndex: 'category',
        filters: categoryFilters,
        filteredValue: tableFilters?.category || null,
        onFilter: (value, record) => record?.category === value,
      },
      {
        title: t('page.preparingTimeReport.quantity'),
        dataIndex: 'quantity',
        sorter: (a, b) => new Date(a.quantity) - new Date(b.quantity),
      },
      {
        title: (
          <Text style={{ display: 'flex', alignItems: 'center' }}>
            {t('app.constant.order.orderNumber')}
            <Tooltip title={t('page.transaction.orderNoTooltip')}>
              <QuestionCircleOutlined
                style={{
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              />
            </Tooltip>
          </Text>
        ),
        dataIndex: 'order',
        ...getColumnSearchProps('order', t('app.constant.order.orderNumber')),
        filteredValue: tableFilters?.order || null,
        sorter: (a, b) => a.order.orderNumber.localeCompare(b.order.orderNumber),
        render: (order) => {
          return (
            <Button
              className={classes.btn}
              onClick={() => {
                window.open(receiptUrl + `${order.orderId}`)
              }}
            >
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[orderNoSearchText]}
                autoEscape
                textToHighlight={order.orderNumber ? order.orderNumber.toString() : ''}
              />
            </Button>
          )
        },
      },
      {
        title: t('app.constant.order.orderType'),
        dataIndex: 'deliveryType',
        render: (deliveryType) => (
          <Text>
            {deliveryType === 'table'
              ? t('app.constant.deliveryType.table')
              : deliveryType === 'merchantTakeaway'
              ? t('app.constant.deliveryType.merchantTakeaway')
              : deliveryType === 'dimorderStoreDelivery'
              ? t('app.constant.deliveryType.dimorderStoreDelivery')
              : deliveryType === 'dimorderTakeaway'
              ? t('app.constant.deliveryType.dimorderTakeaway')
              : '-'}
          </Text>
        ),
        filters: [
          { text: t('app.constant.deliveryType.table'), value: 'table' },
          {
            text: t('app.constant.deliveryType.merchantTakeaway'),
            value: 'merchantTakeaway',
          },
          {
            text: t('app.constant.deliveryType.dimorderStoreDelivery'),
            value: 'dimorderStoreDelivery',
          },
          {
            text: t('app.constant.deliveryType.dimorderTakeaway'),
            value: 'dimorderTakeaway',
          },
        ],
        filteredValue: tableFilters?.deliveryType || null,
        onFilter: (value, record) => record?.deliveryType.includes(value),
      },
      {
        title: t('page.preparingTimeReport.orderAt'),
        dataIndex: 'orderAt',
        sorter: (a, b) => new Date(a.orderAt) - new Date(b.orderAt),
      },
      {
        title: t('page.preparingTimeReport.completeAt'),
        dataIndex: 'completeAt',
        sorter: (a, b) => new Date(a.completeAt) - new Date(b.completeAt),
      },
      {
        title: t('page.preparingTimeReport.preparingTime'),
        dataIndex: 'prepareTime',
        sorter: (a, b) => a.prepareTime - b.prepareTime,
        render: (prepareTime) => {
          return <Text>{moment.utc(prepareTime * 1000).format('HH:mm:ss')}</Text>
        },
      },
      {
        title: t('page.preparingTimeReport.avgPreparingTime'),
        dataIndex: 'avgPrepareTime',
        sorter: (a, b) => a.avgPrepareTime - b.avgPrepareTime,
        render: (avgPrepareTime) => {
          return <Text>{moment.utc(avgPrepareTime * 1000).format('HH:mm:ss')}</Text>
        },
      },
    ],
    data: [],
  }

  if (preparingTimeReport) {
    for (const [key, value] of Object.entries(preparingTimeReport.items)) {
      tableData.data.push({
        key,
        item: value.name,
        category: value.category,
        quantity: value.quantity,
        order: {
          orderNumber: value.serial,
          orderId: value.orderId,
        },
        deliveryType: value.deliveryType ? value.deliveryType : '-',
        orderAt: value.kitchenReceivedAt
          ? `${moment(value.kitchenReceivedAt).format('YYYY/MM/DD HH:mm')}`
          : '-',
        completeAt: value.kitchenCompletedAt
          ? `${moment(value.kitchenCompletedAt).format('YYYY/MM/DD HH:mm')}`
          : '-',
        prepareTime: value.preparationTimeInSec,
        avgPrepareTime: value.avgPrepTimeInSec,
      })
    }
  }

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={tableData.columns}
            dataSource={tableData.data}
            scroll={{ x: 1500 }}
            onChange={handleTableChange}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.preparingTimeReport.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={tableData.columns}
            dataSource={tableData.data}
            pagination={false}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: landscapePageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportTransactionCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: t('page.preparingTimeReport.title') }}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {preparingTimeReport && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  extra: {
    fontSize: 13,
  },
}))
