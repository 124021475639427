import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {PaginationProps} props
 * @property {number} currentPage
 * @property {hasNextPage} setCurrentPage
 * @property {boolean} hasNextPage
 * @property {boolean} loading
 * @returns
 */
export default function Pagination(props) {
  const { currentPage, setCurrentPage, hasNextPage, loading } = props
  const { t } = useTranslation()

  return (
    <div style={{ display: 'flex', paddingTop: 15, justifyContent: 'end', gap: 10 }}>
      <Button
        disabled={currentPage === 0 || loading}
        onClick={() => {
          setCurrentPage((prev) => prev - 1)
        }}
      >
        {t('app.common.previousPage')}
      </Button>
      <Button
        disabled={!hasNextPage || loading}
        onClick={() => {
          setCurrentPage((prev) => prev + 1)
        }}
      >
        {t('app.common.nextPage')}
      </Button>
    </div>
  )
}
