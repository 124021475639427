import { CaretDownFilled, UserOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Col, Collapse, Menu, Row, Typography as Text } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import UserInfoModal from '@/components/UserInfoModal'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

const Div = styled.div`
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    width: 250px;
    padding: 0px 5px 5px 0px;
    padding-left: 0px;
    padding-top: 4px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${colors.primary};
  }

  .ant-collapse-ghost .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding: 1px 5px 0px 0px;
  }
`

export default function UserMenu(props) {
  const { Panel } = Collapse
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [editUserModal, setEditUserModal] = useState(false)
  const { avatar, name } = useSelector((state) => state.user.user.data)
  const history = useHistory()

  const doEditUser = () => {
    setEditUserModal(true)
  }

  const showAllDashboard = () => {
    history.push(`/alldashboard`)
  }

  const menu = (
    <Menu theme='dark' selectable={false} className={classes.menuContainer} mode='inline'>
      <Menu.Item key='allDashboard' onClick={showAllDashboard} className={classes.menuItem}>
        {t('page.allDashboard.title')}
      </Menu.Item>
      <Menu.Item key='edit' onClick={doEditUser} className={classes.menuItem}>
        {t('app.component.user.edit')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Div>
      <UserInfoModal visible={editUserModal} setVisible={setEditUserModal} />
      <Collapse ghost>
        <Panel
          header={
            <Row gutter={10}>
              <Col span={4}>
                {avatar && <Avatar src={avatar} size={30} />}
                {!avatar && (
                  <Avatar
                    style={{
                      backgroundColor: colors.data6,
                    }}
                    icon={<UserOutlined />}
                    size={30}
                  />
                )}
              </Col>
              <Col span={20} style={{ marginTop: 3, paddingLeft: 5 }}>
                <Text className={classes.userName}>
                  {name} <CaretDownFilled style={{ fontSize: 15 }} />
                </Text>
              </Col>
            </Row>
          }
        >
          {menu}
        </Panel>
      </Collapse>
    </Div>
  )
}

const useStyles = makeStyles(() => ({
  userName: {
    fontSize: 16,
    color: colors.white,
    fontWeight: 'bold',
  },
  menuItem: {
    fontSize: 15,
    color: colors.white,
    fontWeight: 'bold',
  },
  menuContainer: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
