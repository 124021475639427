import { LoadingOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Spin } from 'antd'

/**
 * @typedef LoadingIndicatorProps
 * @property {boolean} loading
 */

/**
 *
 * @param {LoadingIndicatorProps} props
 * @returns
 */
export default function LoadingIndicator(props) {
  const { loading } = props
  const classes = useStyles(props)

  if (!loading) return null

  return (
    <div className={classes.loadingContainer}>
      <Spin indicator={<LoadingOutlined className={classes.loadingIcon} />} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
  },
  loadingIcon: {
    fontSize: 60,
  },
}))
