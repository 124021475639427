import { makeStyles } from '@material-ui/styles'
import { Button, Divider, Table, Typography as Text } from 'antd'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ReportResultCounts from '@/components/ReportResultCounts'
import config from '@/config'
import { deliveryTypesZH } from '@/constants/orders'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import colors from '@/style/colors'

export default function PaymentInfo(props) {
  const { report, data, isPrintResult = false } = props
  const classes = useStyles(props)
  const { t } = useTranslation()
  const [numberOfResults, setNumberOfResults] = React.useState(0)
  const [currentTable, setCurrentTable] = React.useState(data ?? [])
  const receiptUrl = config.receipt

  useEffect(() => {
    if (report?.orders) {
      setNumberOfResults(report.orders.length)
    }
  }, [report])

  const filterByOrderType = (value, record) => {
    if (value === deliveryTypesZH.DINE_IN_PAID_BY_CUSTOMER) {
      return record.orderType === deliveryTypesZH.DINE_IN && record.isDineInPayment
    }

    if (value === deliveryTypesZH.DINE_IN) {
      return record.orderType === deliveryTypesZH.DINE_IN && !record.isDineInPayment
    }

    return record.orderType === value
  }

  const reportColumns = [
    {
      title: t('app.common.date'),
      dataIndex: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
    {
      title: t('app.constant.order.orderNumber'),
      dataIndex: 'order',
      sorter: (a, b) =>
        parseInt(a.order.serial.replace('-', '')) - parseInt(b.order.serial.replace('-', '')),
      render: (order) => {
        return (
          <Button
            className={classes.btn}
            onClick={() => {
              window.open(receiptUrl + `${order.id}`)
            }}
          >
            {order.serial}
          </Button>
        )
      },
    },
    {
      title: t('app.constant.order.orderType'),
      dataIndex: 'orderType',
      filters: [
        {
          text: deliveryTypesZH.DINE_IN,
          value: deliveryTypesZH.DINE_IN,
        },
        {
          text: deliveryTypesZH.DINE_IN_PAID_BY_CUSTOMER,
          value: deliveryTypesZH.DINE_IN_PAID_BY_CUSTOMER,
        },
        {
          text: deliveryTypesZH.TAKEAWAY,
          value: deliveryTypesZH.TAKEAWAY,
        },
        {
          text: deliveryTypesZH.STORE_DELIVERY,
          value: deliveryTypesZH.STORE_DELIVERY,
        },
        {
          text: deliveryTypesZH.DIMORDER_TAKEAWAY,
          value: deliveryTypesZH.DIMORDER_TAKEAWAY,
        },
      ],
      onFilter: filterByOrderType,
      render: (value, record) => {
        if (record.orderType === deliveryTypesZH.DINE_IN && record.isDineInPayment) {
          return <Text>{deliveryTypesZH.DINE_IN_PAID_BY_CUSTOMER}</Text>
        }

        return <Text>{value}</Text>
      },
    },
    {
      title: t('page.paymentMethodReport.amount'),
      dataIndex: 'sales',
      sorter: (a, b) => a.sales - b.sales,
      render: (sales) => <Text>{currencyWithCommas(sales)}</Text>,
    },
  ]

  if (report?.paymentType === '八達通' || report?.paymentType === 'Octopus') {
    const deviceIds = data.map((item) => item.deviceId)
    const uniqueDeviceIds = [...new Set(deviceIds)].map((id) => {
      return {
        text: id !== '' ? id : t('page.paymentMethodReport.thirdPartyDevice'),
        value: id !== '' ? id : '',
      }
    })
    reportColumns.unshift({
      title: t('page.paymentMethodReport.deviceId'),
      dataIndex: 'deviceId',
      filters: uniqueDeviceIds,
      onFilter: (value, record) => {
        return record.deviceId === value
      },
      render: (value, record) => {
        return record?.gateway === 'octopusMiniPC' ? (
          <Text>{t('page.paymentMethodReport.octopusDevice', { deviceId: value })}</Text>
        ) : (
          <Text>{t('page.paymentMethodReport.thirdPartyDevice')}</Text>
        )
      },
    })
  }

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setCurrentTable(extra.currentDataSource)
    setNumberOfResults(extra.currentDataSource.length)
  }

  const formatTotalOutput = (total) => {
    return `${t('page.paymentMethodReport.total')}： ${currencyWithCommas(total)}`
  }

  const getTotal = () => {
    if (!report) {
      return ''
    }

    const sum = _.sumBy(currentTable, 'sales')

    return formatTotalOutput(sum)
  }

  return (
    <>
      <Text className={classes.title}>{report?.paymentType}</Text>
      <Divider className={classes.divider} />
      <ReportResultCounts counts={numberOfResults} />
      <br />
      <Table
        columns={reportColumns}
        dataSource={data}
        scroll={{ x: 500 }}
        pagination={!isPrintResult}
        onChange={handleTableChange}
      />
      <Text className={classes.total}>{getTotal()}</Text>
    </>
  )
}

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  divider: {
    borderColor: colors.textSecondary,
    borderWidth: 2,
  },
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  total: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'right',
    paddingTop: 15,
  },
})
