import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row } from 'antd'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'

import SummaryInfo from './SummaryInfo'

export default function SalesReportByCategory(props) {
  const { t } = useTranslation()
  const printRef = useRef()
  const { Panel } = Collapse
  const classes = useStyles(props)
  const [params, setParams] = React.useState(null)
  const [salesReportByCategory, setSalesReportByCategory] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const salesReportByCategory = await dimorderApi
            .instance(activeMerchant.id)
            ?.getSalesReportByCategory(params)
          setSalesReportByCategory(salesReportByCategory)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  const displayResult = () => (
    <Row gutter={16} className={classes.container}>
      <Col xl={18} xs={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <SummaryInfo data={salesReportByCategory?.data} />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.salesReportByCategory.title')}
    >
      <Row gutter={16} className={classes.container}>
        <Col span={18}>
          <SummaryInfo data={salesReportByCategory?.data} />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportSalesReportByCategoryCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.salesReportByCategory.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {salesReportByCategory && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
}))
