import { combineReducers } from 'redux'

import app from './app/reducer'
import merchant from './merchant/reducer'
import user from './user/reducer'

export default combineReducers({
  app,
  user,
  merchant,
})
