import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Button, Col, Row, Typography as Text, Upload, message } from 'antd'
import { useTranslation } from 'react-i18next'
import ReportCard from 'src/components/ReportCard'
import dimorderApi from 'src/libs/api/dimorderApi'
import { useSelector } from 'src/redux'
import colors from 'src/style/colors'

import { dummyRequest } from '@/libs/uploadFile'

const useStyles = makeStyles({
  btn: {
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.button,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
})

export default function MenuImportExport(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  const handleDownload = async () => {
    await dimorderApi.instance(activeMerchant.id)?.downloadMenu()
  }
  const handleUploadAvatar = async (info) => {
    // if (info.file.status === 'uploading') {
    // }
    if (info.file.status === 'done') {
      try {
        await dimorderApi.instance(activeMerchant.id)?.uploadMenu(info.file.originFileObj)
        alert(t('app.common.importSucess'))
      } catch (error) {
        alert(t('app.common.importError'))
      }
    }
  }

  const limitFileType = (file) => {
    const isValidType = true
    if (!isValidType) {
      message.error(t('app.component.userInfoModal.error.file.type'))
    }
    const isLtUploadSize = file.size / 1024 / 1024 < 2
    if (!isLtUploadSize) {
      message.error(t('app.component.userInfoModal.error.file.size'))
    }
    return isValidType && isLtUploadSize
  }

  return (
    <PageContainer ghost header={{ title: t('page.menuImportExport.title') }}>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col xs={24} xl={14}>
          <ReportCard>
            <Text className={classes.title}>{`${activeMerchant?.name}`}</Text>
            <br />
            <Upload
              name='file'
              onChange={handleUploadAvatar}
              beforeUpload={limitFileType}
              showUploadList={false}
              customRequest={dummyRequest}
            >
              <Button type='primary' icon={<UploadOutlined />}>
                上傳
              </Button>
            </Upload>
            <br />
            <Button type='primary' onClick={handleDownload} icon={<DownloadOutlined />}>
              下載{' '}
            </Button>
          </ReportCard>
        </Col>
      </Row>
    </PageContainer>
  )
}
