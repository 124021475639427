import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Collapse, Modal, Row, Col, Table, Typography as Text } from 'antd'
import _ from 'lodash'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'

export default function AllDashboard(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const printRef = useRef()
  const { Panel } = Collapse
  const [activeKey, setActiveKey] = useState('1')
  const [params, setParams] = useState(null)
  const { merchants } = useSelector((state) => state.merchant)
  const [loading, setLoading] = useState(false)
  const [merchantOverviews, setMerchantOverviews] = useState([])
  const numberOfResults = merchantOverviews.length

  useEffect(() => {
    const getOverviews = async () => {
      if (!params) return
      const merchantOverviews = []
      setLoading(true)
      try {
        for (const merchant of merchants) {
          const merchantOverview = await dimorderApi.instance(merchant.id)?.getOverview(params)
          merchantOverviews.push({
            key: merchant.id,
            merchant: merchant.name,
            merchantSales: merchantOverview.overall.totalSales,
          })
        }
        setMerchantOverviews(merchantOverviews)
      } catch (error) {
        console.log(error)
        Modal.error({
          title: t('app.common.error'),
          content: t('app.common.submitError'),
        })
      } finally {
        setLoading(false)
        setActiveKey('0')
      }
    }

    getOverviews()
  }, [params, t])

  const merchantsData = useMemo(() => {
    const columns = [
      {
        title: t('page.allDashboard.merchantName'),
        dataIndex: 'merchant',
      },
      {
        title: t('page.allDashboard.merchantSales'),
        dataIndex: 'merchantSales',
        sorter: (a, b) => a.merchantSales - b.merchantSales,
        render: (amount) => <Text>{currencyWithCommas(amount)}</Text>,
      },
    ]
    return { columns, data: merchantOverviews }
  }, [merchantOverviews])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={merchantsData.columns}
            dataSource={merchantsData.data}
            summary={() => {
              const overallSales = _.sumBy(merchantOverviews, 'merchantSales')
              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell>
                    <Text className={classes.totalText}>{t('page.allDashboard.total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text className={classes.totalText}>{currencyWithCommas(overallSales)}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )
            }}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.allDashboard.title')}
    >
      <Row gutter={16}>
        <Col span={24} className={classes.printContainer}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <ReportResultCounts counts={numberOfResults} />
            <br />
            <Table
              columns={merchantsData.columns}
              dataSource={merchantsData.data}
              pagination={false}
              summary={() => {
                const overallSales = _.sumBy(merchantOverviews, 'merchantSales')
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>{t('page.allDashboard.total')}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text className={classes.totalText}>
                          {currencyWithCommas(overallSales)}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
          </Card>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  return (
    <PageContainer
      ghost
      header={{
        title: t('page.allDashboard.title'),
      }}
    >
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {!loading && params && (
        <>
          <ReportActionButtons onPrint={handlePrint} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  totalText: {
    fontWeight: 'bold',
    fontSize: 15,
    alignItems: 'center',
  },
})
