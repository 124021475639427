import { Line } from 'react-chartjs-2'

export default function LineChart(props) {
  const { width, height, ...other } = props

  return (
    <div style={{ width, height }}>
      <Line {...other} />
    </div>
  )
}
