import { makeStyles } from '@material-ui/styles'
import { Button } from 'antd'

import colors from '@/style/colors'

/**
 * @typedef ReportActionButtonBaseProps
 * @property {string} title
 * @property {() => void} onClick
 * @property {JSX.Element} [icon]
 */

/**
 *
 * @param {ReportActionButtonProps} props
 * @returns
 */
export default function ReportActionButtonBase(props) {
  const { title, onClick, icon } = props
  const classes = useStyles(props)

  return (
    <Button className={classes.button} size='middle' icon={icon} onClick={onClick}>
      {title}
    </Button>
  )
}

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    // marginRight: 20,
  },
}))
