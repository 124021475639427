import { useQuery } from '@tanstack/react-query'
import { useRef } from 'react'

import dimorderApi from '@/libs/api/dimorderApi'

// 每一頁顯示的 user 數量
const PER_PAGE = 10

function usePaginatedMemberQueryOptions(
  groupId = '',
  page = 0,
  searchParams = { name: '', phone: '' },
) {
  const nextRef = useRef(new Map())
  const enabled = !!groupId
  const queryKey = [
    'crm',
    'members',
    `groupId:${groupId}`,
    `name:${searchParams.name}`,
    `phone:${searchParams.phone}`,
    `page:${page}`,
  ]
  const queryFn = () => fetchFn()
  async function fetchFn() {
    const { next, data } = await dimorderApi.group(groupId).getGroupUser({
      ...searchParams,
      limit: PER_PAGE,
      after: nextRef.current.get(page - 1), // 上一頁的 next 就會是這一頁的 after
    })

    // 紀錄當前頁面的 next
    nextRef.current.set(page, next)

    // 附加 latestDate 到 member
    const formatedMembers = data.map((user) =>
      user.orders.length > 0
        ? { ...user, latestDate: user.orders[0].createdAt }
        : { ...user, latestDate: null },
    )

    // 記錄分頁資訊
    const meta = {
      next,
      page,
      nextPage: next ? page + 1 : null,
      prevPage: page === 0 ? null : page - 1,
      perPage: PER_PAGE,
    }

    return { data: formatedMembers, meta }
  }

  return { queryKey, queryFn, enabled }
}

/**
 *
 * @param {string} groupId
 * @param {numer} page
 * @param {MemberSearchParams} options
 * @typedef MemberSearchParams
 * @property {string} [name='']
 * @property {string} [phone='']
 */
export function usePaginatedMembersQuery(groupId, page, options) {
  const quernOptions = usePaginatedMemberQueryOptions(groupId, page, options)
  const query = useQuery(quernOptions)
  return query
}

/**
 *
 * @param {string} groupId
 * @returns
 */
export function useTotalMembersQuery(groupId) {
  async function fetchFn() {
    const { total } = await dimorderApi.group(groupId).getUserCount()
    return total
  }
  const enabled = !!groupId
  const queryKey = ['crm', 'total_members', `groupId:${groupId}`]
  const queryFn = () => fetchFn()
  const query = useQuery({
    queryKey,
    queryFn,
    enabled,
  })
  return query
}

/**
 *
 * @param {string} groupId
 * @param {string} memberId
 */
export function useMemberPointRecordsQuery(groupId, memberId) {
  const PER_PAGE = 5 // 僅顯示最後五筆資料
  async function fetchFn() {
    const { data } = await dimorderApi.group(groupId).getUserCRMPointsRecord(memberId, {
      limit: PER_PAGE,
    })
    return data
  }
  const queryKey = ['crm', 'member', 'point_records', `groupId:${groupId}`, `memberId:${memberId}`]
  const queryFn = () => fetchFn()
  const enabled = !!(groupId && memberId)
  const query = useQuery({
    queryKey,
    queryFn,
    enabled,
  })
  return query
}
