import { SaveOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import ProForm, { ModalForm, ProFormDependency, ProFormSelect } from '@ant-design/pro-form'
import { makeStyles } from '@material-ui/styles'
import { Button, Modal, Row, Switch, Typography as Text } from 'antd'
import { useTranslation } from 'react-i18next'

import { FLOOR_PLAN, SHAPE } from '@/constants'
import i18n from '@/i18n'

const useStyles = makeStyles({
  buttonList: {
    width: '100%',
    marginBottom: 10,
  },
  toggleButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

// 餐檯形狀
const TABLE_SHAPE = [
  {
    value: SHAPE.SQUARE,
    label: i18n.t('page.floorPlan.shape.square'),
  },
  {
    value: SHAPE.RECTANGLE,
    label: i18n.t('page.floorPlan.shape.rectangle'),
  },
  {
    value: SHAPE.CIRCLE,
    label: i18n.t('page.floorPlan.shape.circle'),
  },
  {
    value: SHAPE.OVAL,
    label: i18n.t('page.floorPlan.shape.oval'),
  },
]

export default function ButtonList(props) {
  const {
    areaSize,
    onAddTable,
    onDeleteTable,
    selectedTable,
    selectedAreaName,
    updateTable,
    remainingTables,
    onUpdateTableGeometry,
    enableFloorPlan,
    onUpdateEnableFloorPlan,
  } = props
  const classes = useStyles(props)
  const { t } = useTranslation()

  // 新增/編輯餐檯 Button
  const TableFormModal = (props) => {
    const { type } = props
    return (
      <ModalForm
        title={t(`page.floorPlan.tableButton.${type}`)}
        trigger={
          <Button type='primary' size='large'>
            {t(`page.floorPlan.tableButton.${type}`)}
          </Button>
        }
        submitter={{
          searchConfig: {
            submitText: t('app.common.yes'),
            resetText: t('app.common.no'),
          },
        }}
        onFinish={(value) => {
          if (value.shape === SHAPE.SQUARE || value.shape === SHAPE.CIRCLE)
            value.height = value.width
          if (type === 'add') onAddTable(value)
          else if (type === 'edit') {
            const table = Object.assign({}, value)
            table.x = selectedTable.x
            table.y = selectedTable.y
            updateTable(table)
          }
        }}
      >
        <ProForm.Group>
          <ProFormSelect
            mode='multiple'
            width='sm'
            initialValue={type === 'edit' ? selectedTable.name : undefined}
            options={remainingTables?.map((tableName) => ({
              value: tableName,
              label: tableName,
            }))}
            name='name'
            label={t('page.floorPlan.form.tableName')}
            placeholder={t('page.floorPlan.form.pleaseSelect')}
            disabled={type === 'edit'}
            rules={[{ required: true }]}
          />
        </ProForm.Group>
        <ProForm.Group>
          <ProFormSelect
            width='sm'
            initialValue={type === 'add' ? SHAPE.SQUARE : selectedTable.shape}
            options={TABLE_SHAPE}
            name='shape'
            label={t('page.floorPlan.form.tableShape')}
            placeholder={t('page.floorPlan.form.pleaseSelect')}
            rules={[{ required: true }]}
          />
        </ProForm.Group>

        <ProForm.Group>
          <ProFormDependency key='shape' name={['shape']}>
            {({ shape }) => {
              return (
                <ProFormSelect
                  width='sm'
                  options={Array.from(
                    {
                      length:
                        (shape === SHAPE.RECTANGLE || shape === SHAPE.OVAL
                          ? areaSize?.width
                          : Math.min(areaSize?.width, areaSize?.height)) /
                          FLOOR_PLAN.grid -
                        3,
                    },
                    (_, index) => ({
                      label: (index + 4).toString(),
                      value: (index + 4) * FLOOR_PLAN.grid - 4,
                    }),
                  )}
                  initialValue={type === 'add' ? FLOOR_PLAN.minTableSize : selectedTable.width}
                  name='width'
                  label={t('page.floorPlan.form.tableWidth')}
                  placeholder={t('page.floorPlan.form.pleaseSelect')}
                  rules={[{ required: true }]}
                />
              )
            }}
          </ProFormDependency>
          <ProFormDependency key='shape' name={['shape']}>
            {({ shape }) => {
              if (shape === SHAPE.RECTANGLE || shape === SHAPE.OVAL) {
                return (
                  <ProFormSelect
                    width='sm'
                    options={Array.from(
                      { length: areaSize?.height / FLOOR_PLAN.grid - 3 },
                      (_, index) => ({
                        label: (index + 4).toString(),
                        value: (index + 4) * FLOOR_PLAN.grid - 4,
                      }),
                    )}
                    initialValue={type === 'add' ? FLOOR_PLAN.minTableSize : selectedTable.height}
                    name='height'
                    label={t('page.floorPlan.form.tableHeight')}
                    placeholder={t('page.floorPlan.form.pleaseSelect')}
                    rules={[{ required: true }]}
                  />
                )
              }
            }}
          </ProFormDependency>
        </ProForm.Group>
      </ModalForm>
    )
  }

  // 刪除餐檯 Button
  const DeleteTableButton = () => {
    const handleDeleteTable = () => {
      Modal.confirm({
        title: `${t('page.floorPlan.tableButton.delete')} ${selectedTable.name} ?`,
        icon: <ExclamationCircleFilled />,
        okText: t('app.common.yes'),
        okType: 'danger',
        cancelText: t('app.common.no'),
        onOk() {
          onDeleteTable()
        },
      })
    }

    return (
      <Button type='primary' size='large' onClick={handleDeleteTable}>
        {t('page.floorPlan.tableButton.delete')}
      </Button>
    )
  }

  // 儲存平面圖 Button
  const SaveButton = () => {
    return (
      <Button
        type='primary'
        icon={<SaveOutlined />}
        shape='round'
        size='large'
        onClick={onUpdateTableGeometry}
      >
        {t('app.common.save')}
      </Button>
    )
  }

  // 開關平面圖
  const EnableFloorPlanButton = () => {
    return (
      <div className={classes.toggleButton}>
        <Text>{t('page.floorPlan.enableFloorPlan')}：</Text>
        <Switch checked={enableFloorPlan} onChange={onUpdateEnableFloorPlan} size='large' />
      </div>
    )
  }

  return (
    <Row className={classes.buttonList} justify='space-between'>
      <div style={{ gap: 5, display: 'flex' }}>
        {selectedAreaName && <TableFormModal type='add' />}
        {selectedTable && <TableFormModal type='edit' />}
        {selectedTable && <DeleteTableButton />}
      </div>
      <div style={{ gap: 20, display: 'flex' }}>
        <EnableFloorPlanButton />
        <SaveButton />
      </div>
    </Row>
  )
}
