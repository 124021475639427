/* eslint-disable import/no-anonymous-default-export */

export default {
  INIT: 'MERCHANT/INIT',
  ADD_MERCHANT: 'MERCHANT/ADDMERCHANT',
  ACTIVE_MERCHANT: 'MERCHANT/ACTIVE_MERCHANT',
  UPDATE_CATEGORY: 'MERCHANT/UPDATE_CATEGORY',
  UPDATE_CRM_GROUPS: 'MERCHANT/UPDATE_CRM_GROUPS',
  UPDATE_CRM_GROUP: 'MERCHANT/UPDATE_CRM_GROUP',
  OPEN_MERCHANT_LOGIN_DIALOG: 'MERCHANT/OPEN_MERCHANT_LOGIN_DIALOG',
  CLOSE_MERCHANT_LOGIN_DIALOG: 'MERCHANT/CLOSE_MERCHANT_LOGIN_DIALOG',
}
