const config = {
  api: {
    portalLocal: 'http://10.0.0.97:3300',
    customer: 'https://test-customer-api.dimorder.com',
    dimorder: 'https://test-api.dimorder.com',
    node: 'https://test-node.dimorder.com',
  },
  env: 'test',
  receipt: 'https://test-mp-order.dimorder.com/',
  report: 'https://test-report-api.dimorder.com/report/',
}

export default config
