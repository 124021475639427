import {
  useDispatch as reactReduxUseDispatch,
  useSelector as reactReduxUseSelect,
} from 'react-redux'

import reduxActions from './actions'

export const actions = reduxActions
export const useDispatch = reactReduxUseDispatch
export const useSelector = reactReduxUseSelect
