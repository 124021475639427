import { makeStyles } from '@material-ui/styles'

import { bannerImage } from '@/constants'

import BannerCard from './BannerCard'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    margin: '12px 0px',
    cursor: 'pointer',
  },
})

export default function Banner(props) {
  const classes = useStyles(props)

  return (
    <div className={classes.container}>
      <BannerCard image={bannerImage} />
    </div>
  )
}
