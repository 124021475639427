import { CheckCircleOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography as Text } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import ReportCard from '@/components/ReportCard'
import colors from '@/style/colors'

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  content: {
    textAlign: 'center',
    fontSize: 15,
  },
  btn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    width: '80%',
    fontSize: 15,
    height: 35,
    marginTop: 30,
  },
})

export default function OrderSubmitted(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const history = useHistory()

  const onClick = () => {
    history.push('/')
    props.setSubmitted(false)
  }

  return (
    <ReportCard padding='35px' textAlign='center'>
      <CheckCircleOutlined
        style={{
          fontSize: 50,
          color: colors.success,
          textAlign: 'center',
          padding: 20,
        }}
      />
      <br />
      <Text className={classes.title}>
        {t('page.dimSupplyOrdering.orderForm.success.submit.title')}
      </Text>
      <br />
      <Text className={classes.content}>
        {t('page.dimSupplyOrdering.orderForm.success.submit.content')}
      </Text>
      <br />
      <Button className={classes.btn} onClick={onClick}>
        回到首頁
      </Button>
    </ReportCard>
  )
}
