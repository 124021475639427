import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import colors from './colors'

export default createGlobalStyle`
  ${normalize};

  body {
    background-color: ${colors.secondary}
  }

  .ant-modal-content {
    border-radius: 15px;
  }

  .ant-modal-header {
    border-radius: 15px 15px 0px 0px;
  }

  .ant-pro-global-header {
    height: 60px;
  }


  .ant-pro-basicLayout-content .ant-pro-page-container {
    background-color: ${colors.secondary}
  }

  .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: none
  }

  .ant-divider-horizontal {
    display: flex;
    align-items: center;
    clear: both;
    width: 100%;
    min-width: 80%;
    margin: 8px 0;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header {
    padding: 5px 40px 5px 5px;
  }

  .ant-layout {
    background: ${colors.secondary};
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 1px 10px 16px 10px ;
  }

  .ant-layout-sider {
    background: ${colors.primary};
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub, .ant-pro-sider-extra, .ant-layout-sider-children {
    background: ${colors.primary};
    padding: 0px;
    margin: 5px 0px;

  }

  .ant-pro-sider-menu {
    min-height: 80%;
  }

  .ant-pro-sider-logo {
    background: ${colors.primary};
  }

  .ant-pro-sider-logo img {
    display: flex;
    align-items: center;
    height: 40px;
    vertical-align: middle;
    padding-left: 35px;
  }

  .ant-picker-input input {
    text-align: center;
  }

  .ant-input-number-input {
    text-align: center;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single 
  .ant-select-selector .ant-select-selection-placeholder {
    text-align: center;
  }
`
