import { ExclamationCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import {
  ProFormText,
  ProFormSwitch,
  ProFormSelect,
  ProFormDependency,
  ProFormTextArea,
} from '@ant-design/pro-form'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Button, Col, Form, Modal, Row, Typography as Text, Table, Tooltip } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import _ from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ReportCard from '@/components/ReportCard'
import { useGroup, useGroupId } from '@/hooks/group'
import {
  usePaginatedPointRulesQuery,
  usePointRuleMutation,
  usePointRuleDeletion,
} from '@/hooks/queries/CRM/usePaginatedPointRulesQuery'
import i18n from '@/i18n'
import { currencyWithCommas } from '@/libs/numberWithCommas'

import BreadcrumbItem from '../BreadcrumbItem'
import Pagination from '../Pagination'

const effectiveAfterDaysOptions = [
  {
    value: 0,
    label: i18n.t('page.crm.pointRule.form.instant'),
  },
  {
    value: 1,
    label: i18n.t('page.crm.pointRule.form.nextDay'),
  },
]

export default function PointRule(props) {
  const { t, i18n } = useTranslation()
  const classes = useStyles(props)
  const [modalOpen, setModalOpen] = useState(false)
  const groupId = useGroupId()
  const group = useGroup()
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedPointRuleId, setSelectedPointRuleId] = useState(null)
  const { data: paginatedPointRules, isLoading } = usePaginatedPointRulesQuery(groupId, currentPage)

  const pointRuleTableColumns = [
    {
      title: t('page.crm.pointRule.form.name'),
      dataIndex: ['locales', i18n.language === 'en-US' ? 'en' : 'zh', 'name'],
      render: (name) => {
        return <Text>{name}</Text>
      },
    },
    {
      title: t('page.crm.pointRule.form.spendingThreshold'),
      dataIndex: 'spendingThreshold',
      // sorter: (a, b) => a.spendingThreshold - b.spendingThreshold,
      render: (spendingThreshold) => <Text>{currencyWithCommas(spendingThreshold)}</Text>,
    },
    {
      title: t('page.crm.pointRule.form.spendPerPoint'),
      dataIndex: 'spendPerPoint',
      // sorter: (a, b) => a.spendPerPoint - b.spendPerPoint,
    },
    {
      title: t('page.crm.pointRule.form.expirationDays'),
      dataIndex: 'expirationDays',
      // sorter: (a, b) => a.expirationDays - b.expirationDays,
      render: (expirationDays) => {
        return (
          <Text>
            {expirationDays}
            {t('page.crm.pointRule.form.day')}
          </Text>
        )
      },
    },
    {
      title: t('app.common.enable'),
      dataIndex: 'enabled',
      // sorter: (a, b) => a.enabled - b.enabled,
      render: (enabled) => (
        <span>
          {enabled ? <Text>{t('app.common.enable')}</Text> : <Text>{t('app.common.disable')}</Text>}
        </span>
      ),
    },
    {
      title: t('app.common.edit'),
      dataIndex: 'id',
      render: (id) => (
        <a
          onClick={() => {
            setSelectedPointRuleId(id)
            setModalOpen(true)
          }}
        >
          {t('app.common.edit')}
        </a>
      ),
    },
  ]

  return (
    <PageContainer
      ghost
      breadcrumb
      header={{
        breadcrumb: {
          routes: [
            {
              path: '/crm',
              breadcrumbName: t('page.crm.title'),
            },
            {
              path: '/crm/:groupId',
              breadcrumbName: group?.name,
            },
            {
              path: '/crm/:id/pointrule',
              breadcrumbName: t('page.crm.pointRule.title'),
            },
          ],
          itemRender: (route, params, routes, paths) => {
            return <BreadcrumbItem route={route} params={params} routes={routes} paths={paths} />
          },
        },
        title: '',
        style: {
          paddingTop: '24px',
        },
      }}
    >
      <ReportCard>
        <Button
          type='primary'
          size='large'
          className={classes.button}
          onClick={() => setModalOpen(true)}
        >
          {t('page.crm.pointRule.addCondition')}
        </Button>
        <Table
          columns={pointRuleTableColumns}
          dataSource={paginatedPointRules?.data}
          scroll={{ x: 800 }}
          pagination={false}
          loading={isLoading}
        />
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hasNextPage={!!paginatedPointRules?.meta?.next}
          loading={isLoading}
        />
      </ReportCard>
      <PointRuleModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentPage={currentPage}
        selectedPointRuleId={selectedPointRuleId}
        setSelectedPointRuleId={setSelectedPointRuleId}
      />
    </PageContainer>
  )
}

/**
 *
 * @param {number} currentPage
 * @param {string | null} selectedPointRuleId
 * @returns
 */
function useFormWithInitialValues(currentPage, selectedPointRuleId) {
  const [form] = useForm()
  const groupId = useGroupId()
  const group = useGroup()
  const merchants = group.groupMerchants
  const { data: paginatedPointRules } = usePaginatedPointRulesQuery(groupId, currentPage)

  // edit / add modal 的 default value
  const initialValues = useMemo(() => {
    const selectedPointRule = paginatedPointRules?.data?.find(
      (pointRule) => pointRule.id === selectedPointRuleId,
    )
    return {
      effectiveAfterDays: selectedPointRule?.effectiveAfterDays ?? null,
      enabled: selectedPointRule?.enabled ?? false,
      expirationDays: selectedPointRule?.expirationDays ?? null,
      spendPerPoint: selectedPointRule?.spendPerPoint ?? null,
      spendingThreshold: selectedPointRule?.spendingThreshold ?? null,
      locales: selectedPointRule?.locales,
      merchants: _.map(merchants, (merchant) => {
        return {
          enabled: !selectedPointRule?.merchants[merchant?.id]?.disabled ?? true,
          name: merchant?.name,
          id: merchant?.id,
        }
      }),
    }
  }, [selectedPointRuleId])

  // 每次開 add/edit modal 前都設定 initialValues
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [selectedPointRuleId])

  return { form, initialValues }
}

/**
 * 新增/編輯 pointRule Modal
 * @typedef PointRuleModalProps
 * @property {boolean} modalOpen
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setModalOpen
 * @property {number} currentPage
 * @property {React.Dispatch<React.SetStateAction<number>>} selectedPointRuleId
 * @property {React.Dispatch<React.SetStateAction<string | null>>} setSelectedPointRuleId
 * @param {PointRuleModalProps} props
 * @returns
 */
function PointRuleModal(props) {
  const { modalOpen, setModalOpen, currentPage, selectedPointRuleId, setSelectedPointRuleId } =
    props
  const { t } = useTranslation()
  const classes = useStyles(props)
  const groupId = useGroupId()
  const { form, initialValues } = useFormWithInitialValues(currentPage, selectedPointRuleId)
  const { mutate: mutatePointRule, isLoading: isMutating } = usePointRuleMutation(
    groupId,
    currentPage,
    selectedPointRuleId,
    {
      onSuccess: () => {
        handleClose()
      },
      onError: () => {
        Modal.error({
          title: t('app.common.error'),
          content: t('app.common.submitError'),
        })
      },
    },
  )
  const { mutate: deletePointRule, isLoading: isDeleting } = usePointRuleDeletion(
    groupId,
    currentPage,
    selectedPointRuleId,
    {
      onSuccess: () => {
        handleClose()
      },
      onError: () => {
        Modal.error({
          title: t('page.crm.error.deleteInfo'),
        })
      },
    },
  )

  const handleClose = () => {
    // 重設 form
    form.resetFields()
    setModalOpen(false)
    setSelectedPointRuleId(null)
  }

  // 刪除 pointRule
  const handleDeletePointRule = () => {
    Modal.confirm({
      title: t('app.common.deleteConfirmation'),
      icon: <ExclamationCircleFilled />,
      okText: t('app.common.yes'),
      okType: 'danger',
      cancelText: t('app.common.no'),
      onOk() {
        deletePointRule()
      },
    })
  }

  // 檢查是否輸入數字(Int / Float)
  const numValidator = () => ({
    validator: (_, value) => {
      return new Promise((resolve, reject) => {
        if (isNaN(value)) {
          reject(new Error('Validation Failed'))
        }
        resolve()
      })
    },
    message: t('page.crm.error.number'),
  })

  // 檢查是否輸入整數(Int)
  const intValidator = (includeZero) => ({
    validator: (_, value) => {
      return new Promise((resolve, reject) => {
        const isInteger = includeZero ? /^([1-9]\d*|0)$/ : /^[1-9]\d*$/
        if (!isInteger.test(value)) {
          reject(new Error('Validation Failed'))
        }
        resolve()
      })
    },
    message: t('page.crm.error.integer'),
  })

  // merchants 的 Table
  const merchantSettingTableData = {
    columns: [
      {
        title: t('page.crm.pointRule.form.merchantTable.merchant'),
        dataIndex: 'name',
        render: (name) => <Text style={{ paddingBottom: 24 }}>{name}</Text>,
      },
      {
        title: t('page.crm.pointRule.form.merchantTable.grantPoint'),
        dataIndex: 'enabled',
        render: (_, record, index) => <ProFormSwitch name={['merchants', index, 'enabled']} />,
      },
    ],
    data: initialValues.merchants,
  }

  return (
    <Modal
      forceRender
      visible={modalOpen}
      title={t('page.crm.pointRule.form.title')}
      onCancel={handleClose}
      width={1000}
      footer={null}
    >
      <Form
        form={form}
        onFinish={() => {
          // values 需要從 useForm 拿才能有整個 initialValues
          const values = form.getFieldValue()
          // 把 merchants 改成 BE format
          const updatedMerchants = {}
          values.merchants.forEach((merchant) => {
            updatedMerchants[merchant.id] = {
              disabled: !merchant.enabled,
            }
          })
          // 上傳 pointRule
          const mutatedPointRule = {
            ...values,
            locales: _.map(values.locales, (locale, key) => ({ ...locale, locale: key })),
            spendingThreshold: parseInt(values.spendingThreshold),
            spendPerPoint: parseFloat(values.spendPerPoint),
            expirationDays: parseInt(values.expirationDays),
            merchants: updatedMerchants,
          }
          mutatePointRule(mutatedPointRule)
        }}
        autoComplete='off'
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col xs={24} md={10}>
            <Col xs={24}>
              <Text className={classes.label}>{t('app.common.enable')}</Text>
              <ProFormSwitch name='enabled' />
            </Col>
            <Col xs={24}>
              <ProFormText
                name={['locales', 'zh', 'name']}
                label={t('page.crm.pointRule.form.name') + ` (${t(`app.common.zh`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.name',
                )} (${t(`app.common.zh`)})`}
                rules={[
                  () => ({
                    validator: (ruleObject, value) => {
                      if (value === '') {
                        throw new Error('Validation Failed')
                      } else {
                        return Promise.resolve()
                      }
                    },
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.name'),
                    }),
                  }),
                ]}
                required
              />
            </Col>
            <Col xs={24}>
              <ProFormText
                name={['locales', 'en', 'name']}
                label={t('page.crm.pointRule.form.name') + ` (${t(`app.common.en`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.name',
                )} (${t(`app.common.en`)})`}
              />
            </Col>
            <Col xs={24}>
              <ProFormTextArea
                name={['locales', 'zh', 'desc']}
                label={t('page.crm.pointRule.form.description') + ` (${t(`app.common.zh`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.description',
                )} (${t(`app.common.zh`)})`}
              />
            </Col>
            <Col xs={24}>
              <ProFormTextArea
                name={['locales', 'en', 'desc']}
                label={t('page.crm.pointRule.form.description') + ` (${t(`app.common.en`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.description',
                )} (${t(`app.common.en`)})`}
              />
            </Col>
            <Col xs={24}>
              <div className={classes.label}>
                <Text>{t('page.crm.pointRule.form.spendingThreshold')}</Text>
                <Tooltip title={t('page.crm.pointRule.form.tooltip.spendingThreshold')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                <ProFormDependency key='spendingThreshold' name={['spendingThreshold']}>
                  {({ spendingThreshold }) => {
                    return (
                      Boolean(spendingThreshold) && (
                        <Text style={{ color: 'gray' }}>
                          {t('page.crm.pointRule.form.hints.spendingThreshold', {
                            info: spendingThreshold,
                          })}
                        </Text>
                      )
                    )
                  }}
                </ProFormDependency>
              </div>
              <ProFormText
                name='spendingThreshold'
                fieldProps={{
                  prefix: '$',
                }}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.spendingThreshold',
                )}`}
                rules={[
                  {
                    required: true,
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.spendingThreshold'),
                    }),
                  },
                  intValidator(true),
                ]}
              />
            </Col>
            <Col xs={24}>
              <div className={classes.label}>
                <Text>{t('page.crm.pointRule.form.spendPerPoint')}</Text>
                <Tooltip title={t('page.crm.pointRule.form.tooltip.spendPerPoint')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                <ProFormDependency key='spendPerPoint' name={['spendPerPoint']}>
                  {({ spendPerPoint }) => {
                    return (
                      Boolean(spendPerPoint) && (
                        <Text style={{ color: 'gray' }}>
                          {t('page.crm.pointRule.form.hints.spendPerPoint', {
                            info: spendPerPoint,
                          })}
                        </Text>
                      )
                    )
                  }}
                </ProFormDependency>
              </div>
              <ProFormText
                name='spendPerPoint'
                fieldProps={{
                  prefix: '$',
                }}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.spendPerPoint',
                )}`}
                rules={[
                  {
                    required: true,
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.spendPerPoint'),
                    }),
                  },
                  numValidator(),
                ]}
              />
            </Col>
          </Col>
          <Col xs={24} md={14}>
            <Col xs={24}>
              <div className={classes.label}>
                <Text>{t('page.crm.pointRule.form.effectiveAfterDays')}</Text>
                <Tooltip title={t('page.crm.pointRule.form.tooltip.effectiveAfterDays')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                <ProFormDependency key='effectiveAfterDays' name={['effectiveAfterDays']}>
                  {({ effectiveAfterDays }) => {
                    const option = effectiveAfterDaysOptions.find(
                      (option) => option.value === effectiveAfterDays,
                    )
                    return (
                      option && (
                        <Text style={{ color: 'gray' }}>
                          {t('page.crm.pointRule.form.hints.effectiveAfterDays', {
                            info: option.label,
                          })}
                        </Text>
                      )
                    )
                  }}
                </ProFormDependency>
              </div>
              <ProFormSelect
                name='effectiveAfterDays'
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.effectiveAfterDays',
                )}`}
                rules={[
                  {
                    required: true,
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.effectiveAfterDays'),
                    }),
                  },
                ]}
                options={effectiveAfterDaysOptions}
              />
            </Col>
            <Col xs={24}>
              <div className={classes.label}>
                <Text>{t('page.crm.pointRule.form.expirationDays')}</Text>
                <Tooltip title={t('page.crm.pointRule.form.tooltip.expirationDays')}>
                  <QuestionCircleOutlined />
                </Tooltip>
                <ProFormDependency key='expirationDays' name={['expirationDays']}>
                  {({ expirationDays }) => {
                    return (
                      Boolean(expirationDays) && (
                        <Text style={{ color: 'gray' }}>
                          {t('page.crm.pointRule.form.hints.expirationDays', {
                            info: expirationDays,
                          })}
                        </Text>
                      )
                    )
                  }}
                </ProFormDependency>
              </div>
              <ProFormText
                name='expirationDays'
                fieldProps={{
                  suffix: t('page.crm.pointRule.form.day'),
                }}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.pointRule.form.expirationDays',
                )}`}
                rules={[
                  {
                    required: true,
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.expirationDays'),
                    }),
                  },
                  intValidator(false),
                ]}
              />
            </Col>
            <Col xs={24}>
              <Text className={classes.label}>{t('page.crm.pointRule.form.merchantSetting')}</Text>
              <Table
                columns={merchantSettingTableData.columns}
                dataSource={merchantSettingTableData.data}
                rowKey={(record) => record.id}
                scroll={{ x: 500 }}
                pagination={false}
              />
            </Col>
          </Col>
          <Col xs={24} md={24} className={classes.container}>
            {selectedPointRuleId ? (
              <Button
                type='primary'
                className={classes.btn}
                danger
                onClick={handleDeletePointRule}
                loading={isDeleting}
                disabled={isMutating}
              >
                {t('app.common.delete')}
              </Button>
            ) : (
              <div />
            )}
            <div style={{ gap: 10, display: 'flex' }}>
              <Button
                type='default'
                className={classes.btn}
                onClick={handleClose}
                disabled={isMutating || isDeleting}
              >
                {t('app.common.no')}
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                className={classes.btn}
                loading={isMutating}
                disabled={isDeleting}
              >
                {t('app.common.submit')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 14,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
  },
  boldText: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  button: {
    marginBottom: 15,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    paddingBottom: 8,
  },
})
