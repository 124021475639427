import { makeStyles } from '@material-ui/styles'
import { Col, Row, Select, Space, Typography as Text } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from '@/redux'

export default function CategorySelector(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const categoriesData = useSelector((state) => state.merchant.activeMerchant?.categories)
  const [categories, setCategories] = useState()

  useEffect(() => {
    setCategories(
      categoriesData?.map((category) => {
        return category.id
      }),
    )
  }, [categoriesData])

  useEffect(() => {
    props.setCategory_ids(categories)
  }, [categories, props])

  const categoryOptions = []
  const categoryIds = []

  categoryOptions.push(
    {
      label: t('app.common.selectAll'),
      value: 'selectAll',
    },
    {
      label: t('app.common.cancelAll'),
      value: 'cancelAll',
    },
  )

  categoriesData?.forEach((category) => {
    categoryOptions.push({
      label: category.name,
      value: category.id,
    })
    categoryIds.push(category.id)
  })

  const categoryProps = {
    mode: 'multiple',
    showArrow: true,
    value: categories,
    options: categoryOptions,
    onChange: (newSelection) => {
      if (newSelection.includes('selectAll')) {
        setCategories(categoryIds)
      } else if (newSelection.includes('cancelAll')) {
        setCategories([])
      } else {
        setCategories(newSelection)
      }
    },
    placeholder: t('app.component.filterDialog.selectCategory'),
    maxTagCount: 'responsive',
    style: { width: 200, marginLeft: 40 },
  }

  return (
    <Space>
      <Text className={classes.msg}>{t('app.component.filterDialog.category')}</Text>
      <Row gutter={110}>
        <Col span={24} style={{ paddingTop: 15 }}>
          <Select {...categoryProps} />
        </Col>
      </Row>
    </Space>
  )
}

const useStyles = makeStyles(() => ({
  msg: {
    fontSize: 14,
    marginTop: 10,
    textAlign: 'left',
    paddingLeft: 10,
  },
}))
