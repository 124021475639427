import moment from 'moment'

import i18n from '@/i18n'
import dimorderApi from '@/libs/api/dimorderApi'

import actions from '../actions'
import ActionTypes from './ActionTypes'

/**
 * @function
 * @returns {ThunkFunction}
 */
export function init() {
  return async (dispatch) => {
    await dispatch(actions.user.init())
    await dispatch(actions.merchant.init())
    dispatch(restoreLang())
    dispatch(restoreAdLastPopDate())
    dispatch({ type: ActionTypes.INIT })
  }
}

/**
 * @function
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function restoreLang() {
  return (dispatch) => {
    // restore from local storage
    const i18nextLng = localStorage.getItem('i18nextLng')
    // 沒有抓到就不要 restore
    if (!i18nextLng) return

    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'
    // update api language
    dimorderApi.instance()?.setLanguage(apiLanguage)

    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })
  }
}

/**
 * @function
 * @param {string?} lang
 * @returns {ThunkFunction}
 */
export function changeLang(lang) {
  return async (dispatch, getState) => {
    const i18nextLng = lang
    // update default language
    localStorage.setItem('i18nextLng', i18nextLng)
    const apiLanguage = i18nextLng.slice(0, 2) // 'zh-TW' => 'zh'

    i18n.changeLanguage(lang)
    dimorderApi.instance()?.setLanguage(apiLanguage)

    // update redux state
    dispatch({
      type: ActionTypes.UPDATE_LANG,
      payload: { lang: i18nextLng },
    })
  }
}

export function restoreAdLastPopDate() {
  return (dispatch, getState) => {
    const lastPopDate = localStorage.getItem('adLastPopDate')
    if (lastPopDate) {
      dispatch({
        type: ActionTypes.UPDATE_ADLASTPOPDATE,
        payload: lastPopDate,
      })
    }
  }
}

export function popAd() {
  return (dispatch, getState) => {
    const lastPopDate = getState().app.adPop.lastPopDate
    if (lastPopDate) {
      if (moment().startOf('day').diff(moment(lastPopDate).startOf('day'), 'day') >= 1) {
        dispatch({
          type: ActionTypes.POP_AD,
        })
        localStorage.setItem('adLastPopDate', moment())
        dispatch(restoreAdLastPopDate())
      }
    } else {
      dispatch({
        type: ActionTypes.POP_AD,
      })
      localStorage.setItem('adLastPopDate', moment())
      dispatch(restoreAdLastPopDate())
    }
  }
}

export function closeAd() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_AD,
    })
    dispatch(restoreAdLastPopDate())
  }
}
