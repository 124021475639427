import { UserOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Col, Collapse, Menu, Row, Typography as Text } from 'antd'
import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useSelector } from '@/redux'
import colors from '@/style/colors'

const Div = styled.div`
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    width: 250px;
    padding: 0px 5px 5px 0px;
    padding-left: 0px;
    padding-top: 4px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }

  .ant-menu-item-selected {
    background-color: ${colors.primary};
  }

  .ant-collapse-ghost .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding: 1px 5px 0px 0px;
  }
`

export default function CRMMenu(props) {
  const { Panel } = Collapse
  const classes = useStyles(props)
  const { t } = useTranslation()
  const crmGroups = useSelector((state) => state.merchant.crmGroups)
  const history = useHistory()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState('')
  const [selectedKeys, setSelectedKeys] = useState([])

  const onExpand = (key) => {
    setActiveKey(key)
  }

  const onClickMenuItem = ({ key }) => {
    history.push(key)
    setSelectedKeys([key])
  }

  useEffect(() => {
    if (!location.pathname.startsWith('/crm')) {
      setSelectedKeys([])
      setActiveKey('')
    }
  }, [location.pathname])

  if (_.isEmpty(crmGroups)) return null

  const menu = (
    <Menu
      theme='dark'
      selectable={false}
      className={classes.menuContainer}
      mode='inline'
      selectedKeys={selectedKeys}
    >
      <Menu.Item key='/crm' className={classes.groupListTitle} onClick={onClickMenuItem}>
        {t('page.crm.groupList')}
      </Menu.Item>
      {_.map(crmGroups, (group) => {
        // const isActive = group.id === groupId
        return (
          <Menu.Item
            key={`/crm/${group.id}`}
            className={classes.groupName}
            // style={{ fontWeight: isActive ? 'bold' : 'normal' }}
            onClick={onClickMenuItem}
          >
            {group.name}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  return (
    <Div>
      <Collapse ghost activeKey={activeKey} onChange={onExpand}>
        <Panel
          header={
            <Row gutter={10}>
              <Col span={4}>
                <Avatar
                  style={{
                    backgroundColor: colors.data6,
                  }}
                  icon={<UserOutlined />}
                  size={30}
                />
              </Col>
              <Col span={20} style={{ marginTop: 3, paddingLeft: 5 }}>
                <Text className={classes.title}>{t('page.crm.title')}</Text>
              </Col>
            </Row>
          }
        >
          {menu}
        </Panel>
      </Collapse>
    </Div>
  )
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    color: colors.white,
    fontWeight: 'bold',
  },
  groupListTitle: {
    fontSize: 15,
    color: colors.white,
  },
  groupName: {
    fontSize: 15,
    color: colors.white,
  },
  menuContainer: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
