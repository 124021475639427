import { PageContainer } from '@ant-design/pro-layout'
import { Col, Collapse, Modal, Row, Table } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import ReportResultCounts from '@/components/ReportResultCounts'
import { pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { useSelector } from '@/redux'

export default function OptionSalesReport(props) {
  const { Panel } = Collapse
  const { t } = useTranslation()
  const printRef = React.useRef()
  const [params, setParams] = React.useState(null)
  const [activeKey, setActiveKey] = React.useState('1')
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState(null)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  React.useEffect(() => {
    async function fetchData(params) {
      setLoading(true)
      try {
        const data = await dimorderApi.instance(activeMerchant.id)?.getOptionSalesSection(params)
        setData(data)
        setActiveKey('0')
      } catch (error) {
        console.log(error)
        Modal.error({
          title: t('app.common.error'),
          content: t('app.common.submitError'),
        })
        setActiveKey('1')
      } finally {
        setLoading(false)
      }
    }

    if (params) {
      fetchData(params)
    }
  }, [params, t, activeMerchant])

  const numberOfResults = React.useMemo(() => {
    return data?.data?.length ?? 0
  }, [data?.data])

  const dataForTable = React.useMemo(() => {
    const columns = [
      {
        title: t('page.optionSalesReport.option'),
        dataIndex: 'title',
        // sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: t('page.optionSalesReport.count'),
        dataIndex: 'count',
        // sorter: (a, b) => a.count - b.count,
      },
      {
        title: t('page.optionSalesReport.totalSales'),
        dataIndex: 'totalSales',
        // sorter: (a, b) => a.totalSales - b.totalSales,
      },
    ]
    const formatedData = _.map(data?.data, (d) => ({
      ...d,
      totalSales: currencyWithCommas(d.totalSales),
    }))
    const result = { columns, data: formatedData }
    return result
  }, [data])

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportOptionSalesReport(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  const displayResult = () => (
    <Row gutter={16}>
      <Col span={24}>
        <Card
          width='100%'
          height='100%'
          padding='20px'
          borderRadius='2px'
          textAlign='left'
          boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
        >
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={dataForTable.columns}
            dataSource={dataForTable.data}
            scroll={{ x: 500 }}
            // onChange={handleTableChange}
          />
        </Card>
      </Col>
    </Row>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.optionSalesReport.title')}
    >
      <Row gutter={16}>
        <Col span={24}>
          <ReportResultCounts counts={numberOfResults} />
          <br />
          <Table
            columns={dataForTable.columns}
            dataSource={dataForTable.data}
            pagination={false}
            // onChange={handleTableChange}
          />
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  return (
    <PageContainer ghost header={t('page.optionSalesReport.title')}>
      <Collapse
        ghost
        expandIcon={() => <></>}
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
      >
        <Panel
          key='1'
          style={{ fontSize: 22 }}
          extra={
            <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
          }
        >
          <FilterDialog setParams={setParams} momentFormatX />
        </Panel>
      </Collapse>
      <br />
      <LoadingIndicator loading={loading} />
      {data && !loading && (
        <>
          <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
          {displayResult()}
          {printResult()}
        </>
      )}
    </PageContainer>
  )
}
