import { ShopOutlined } from '@ant-design/icons'
import { Avatar } from 'antd'
import React from 'react'

import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function MerchantIcon() {
  const { activeMerchant } = useSelector((state) => state.merchant)

  return (
    <>
      {activeMerchant?.image && <Avatar src={activeMerchant.image} size={30} />}
      {!activeMerchant?.image && (
        <Avatar
          style={{
            backgroundColor: colors.data3,
          }}
          icon={<ShopOutlined />}
          size={30}
        />
      )}
    </>
  )
}
