import { QuestionCircleOutlined } from '@ant-design/icons'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, DatePicker, Row, Statistic, Table, Tooltip, Typography as Text } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Banner from '@/components/Banner'
import Card from '@/components/Card'
import PopUpAd from '@/components/PopUpAd'
import ReportResultCounts from '@/components/ReportResultCounts'
import { Bar, Doughnut, LineChart } from '@/components/chart'
import { dataColors } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { currencyWithCommas } from '@/libs/numberWithCommas'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

export default function Dashboard(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [dashboard, setDashboard] = React.useState(null)
  const [date, setDate] = React.useState(moment().format('YYYY-MM-DD'))
  const [sales, setSales] = React.useState(null)
  const [numberOfTableResults, setNumberOfTableResults] = React.useState(0)
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  React.useEffect(() => {
    const getItems = async () => {
      const dashboard = await dimorderApi.instance(activeMerchant.id)?.getDashboard(date)
      const sales = await dimorderApi.instance(activeMerchant.id)?.getSales(date)
      setDashboard(dashboard.data)
      setSales(sales.data)
    }

    getItems()
  }, [date, activeMerchant])

  React.useEffect(() => {
    dispatch(actions.app.popAd())
  })

  React.useEffect(() => {
    setNumberOfTableResults(sales?.length)
  }, [sales])

  const orderCountByHour = {
    labels: [],
    datasets: [
      {
        label: t('app.constant.deliveryType.table'),
        data: [],
        backgroundColor: dataColors[0],
      },
      {
        label: t('app.constant.deliveryType.merchantTakeaway'),
        data: [],
        backgroundColor: dataColors[1],
      },
      {
        label: t('app.constant.deliveryType.dimorderTakeaway'),
        data: [],
        backgroundColor: dataColors[2],
      },
      {
        label: t('app.constant.deliveryType.dimorderStoreDelivery'),
        data: [],
        backgroundColor: dataColors[4],
      },
    ],
  }

  const salesByHour = {
    labels: [],
    datasets: [
      {
        label: t('page.dashboard.price'),
        data: [],
        backgroundColor: colors.white,
        borderColor: dataColors[0],
        borderWidth: 4,
        cubicInterpolationMode: 'monotone',
        tension: 0.4,
      },
    ],
  }

  const orderFrom = {
    labels: [
      t('page.dashboard.tablet'),
      t('page.dashboard.qrcode'),
      t('page.dashboard.web'),
      t('page.dashboard.cApp'),
      t('page.dashboard.waiterApp'),
      t('app.common.other'),
    ],
    datasets: [
      {
        data: [],
        backgroundColor: dataColors.slice(0, 6),
      },
    ],
  }

  const topSales = {
    columns: [
      {
        title: t('page.dashboard.productName'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t('page.dashboard.category'),
        dataIndex: 'category',
        sorter: (a, b) => a.category.localeCompare(b.category),
      },
      {
        title: t('page.dashboard.salesCount'),
        dataIndex: 'count',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.count - b.count,
      },
      {
        title: t('page.dashboard.salesPrice'),
        dataIndex: 'sales',
        sorter: (a, b) => a.sales - b.sales,
        render: (sales) => <Text>{currencyWithCommas(sales)}</Text>,
      },
    ],
    data: [],
  }

  const paymentMethod = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  }

  orderFrom.datasets[0].data.push(
    dashboard?.total?.orderFrom?.merchant,
    dashboard?.total?.orderFrom?.tableQR,
    dashboard?.total?.orderFrom?.web,
    dashboard?.total?.orderFrom?.app,
    dashboard?.total?.orderFrom?.waiter,
    dashboard?.total?.orderFrom?.others,
  )

  function disabledDate(current) {
    return current && current > moment().endOf('day')
  }

  function randomColor() {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
    if (randomColor === '#ffffff') return randomColor()
    return randomColor
  }

  if (dashboard) {
    const sortedPaidBy = _.sortBy(dashboard?.total?.paidBy, (o) => {
      return 0 - o.count
    })
    for (const [key, value] of Object.entries(sortedPaidBy)) {
      paymentMethod.labels.push(value.name)
      paymentMethod.datasets[0].data.push(value.count)
      if (key <= dataColors.length - 1) {
        paymentMethod.datasets[0].backgroundColor.push(dataColors[key])
      } else {
        paymentMethod.datasets[0].backgroundColor.push(randomColor())
      }
    }
  }

  dashboard?.byTime?.forEach((o) => {
    orderCountByHour.labels.push(moment(o.time).format('HH:mm'))
    salesByHour.labels.push(moment(o.time).format('HH:mm'))
    orderCountByHour.datasets[0].data.push(o.deliveryTypes.table)
    orderCountByHour.datasets[1].data.push(o.deliveryTypes.merchantTakeaway)
    orderCountByHour.datasets[2].data.push(o.deliveryTypes.dimOrderTakeaway)
    orderCountByHour.datasets[3].data.push(o.deliveryTypes.dimOrderStoreDelivery)
    salesByHour.datasets[0].data.push(o.sales)
  })

  if (sales) {
    for (const [key, value] of Object.entries(sales)) {
      topSales.data.push({
        key,
        name: value.name,
        category: value.category,
        count: value.count,
        sales: value.sales,
      })
    }
  }

  return (
    <PageContainer ghost header={{ title: '' }}>
      <PopUpAd />
      <Banner />
      <Row gutter={16}>
        <Col span={24}>
          <DatePicker
            size='large'
            disabledDate={disabledDate}
            allowClear={false}
            value={moment(date)}
            onChange={(d) => {
              d && setDate(d.format('YYYY-MM-DD'))
            }}
            className={classes.datePicker}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={120} textAlign='center'>
            <Text className={classes.title} style={{ color: colors.textSecondary }}>
              {t('page.dashboard.totalSalesPrice')}
            </Text>
            <br />
            <Text className={classes.data} style={{ color: colors.textPrimary }}>
              {dashboard ? currencyWithCommas(dashboard?.total?.sales) : '--'}
            </Text>
            <br />
            <Text className={classes.tips}>
              {dashboard
                ? dashboard?.total?.tip > 0
                  ? `+${t('page.dashboard.tips')} ${currencyWithCommas(dashboard?.total?.tips)}`
                  : ''
                : '--'}
            </Text>
          </Card>
        </Col>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={120} textAlign='center'>
            <Text className={classes.title} style={{ color: colors.textTertiary }}>
              {t('page.dashboard.totalTableGuest')}
            </Text>
            <br />
            <Text className={classes.data}>{dashboard ? dashboard?.total?.customers : '--'}</Text>
          </Card>
        </Col>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={120} textAlign='center'>
            <Text className={classes.title} style={{ color: colors.textTertiary }}>
              {t('page.dashboard.totalTransaction')}
            </Text>
            <br />
            <Text className={classes.data}>
              {dashboard ? dashboard?.total?.transactions?.paid : '--'}
            </Text>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={16} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height='100%'>
            <Text className={classes.title}>{t('page.dashboard.hourlyOrderCount')}</Text>
            <Row gutter={50} style={{ textAlign: 'center' }}>
              <Col span={6}>
                <Statistic
                  title={t('app.constant.deliveryType.table')}
                  value={dashboard ? dashboard?.total?.deliveryTypes?.table : '--'}
                  valueStyle={{
                    fontSize: 16,
                  }}
                  prefix={
                    <div
                      style={{
                        backgroundColor: dataColors[0],
                        height: 12,
                        width: 12,
                        borderRadius: 30,
                      }}
                    />
                  }
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={t('app.constant.deliveryType.merchantTakeaway')}
                  value={dashboard ? dashboard?.total?.deliveryTypes?.merchantTakeaway : '--'}
                  valueStyle={{
                    fontSize: 16,
                  }}
                  prefix={
                    <div
                      style={{
                        backgroundColor: dataColors[1],
                        height: 12,
                        width: 12,
                        borderRadius: 30,
                      }}
                    />
                  }
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={t('app.constant.deliveryType.dimorderTakeaway')}
                  value={dashboard ? dashboard?.total?.deliveryTypes?.dimOrderTakeaway : '--'}
                  valueStyle={{
                    fontSize: 16,
                  }}
                  prefix={
                    <div
                      style={{
                        backgroundColor: dataColors[2],
                        height: 12,
                        width: 12,
                        borderRadius: 30,
                      }}
                    />
                  }
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title={t('app.constant.deliveryType.dimorderStoreDelivery')}
                  value={dashboard ? dashboard?.total?.deliveryTypes?.dimOrderStoreDelivery : '--'}
                  valueStyle={{
                    fontSize: 16,
                  }}
                  prefix={
                    <div
                      style={{
                        backgroundColor: dataColors[4],
                        height: 12,
                        width: 12,
                        borderRadius: 30,
                      }}
                    />
                  }
                />
              </Col>
            </Row>
            <div style={{ marginBottom: 19 }}>
              <Bar
                flex={1}
                data={orderCountByHour}
                options={{
                  responsive: true,
                  layout: {
                    padding: 10,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maxBarThickness: 150,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Col>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height='100%'>
            <Text className={classes.title}>{t('page.dashboard.orderSource')}</Text>
            <br /> <br /> <br />
            <Doughnut
              flex={1}
              data={orderFrom}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },
              }}
            />
            <br />
            <br />
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height='100%'>
            <Text className={classes.title}>{t('page.dashboard.paymentMethod')}</Text>
            <br /> <br />
            <Doughnut
              flex={1}
              data={paymentMethod}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },
              }}
            />
          </Card>
        </Col>
        <Col xl={16} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height='100%'>
            <Text className={classes.title}>{t('page.dashboard.hourlySalesPrice')}</Text>
            <div style={{ marginBottom: 4 }}>
              <LineChart
                flex={1}
                data={salesByHour}
                options={{
                  interaction: {
                    intersect: false,
                    axis: 'x',
                  },
                  layout: {
                    padding: 20,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={145} textAlign='center'>
            <Text className={classes.bigTitle}>{t('page.dashboard.currentCustomer')}</Text> <br />
            <br />
            <Text className={classes.bigData}>
              {dashboard ? `${dashboard?.numCurrentCustomers} ${t('app.common.people')}` : '---'}
            </Text>
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={145} textAlign='center'>
            <Text className={classes.bigTitle}>{t('page.dashboard.pendingSales')}</Text> <br />
            <br />
            <Text className={classes.bigData}>
              {dashboard ? currencyWithCommas(dashboard?.total?.pendingSales) : '---'}
            </Text>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={145} textAlign='center'>
            <Text className={classes.bigTitle}>{t('page.dashboard.avgSpending')}</Text> <br />
            <br />
            <Text className={classes.bigData2}>
              {dashboard ? currencyWithCommas(dashboard?.averageSpending) : '---'}
            </Text>
          </Card>
        </Col>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={145} textAlign='center'>
            <Text className={classes.bigTitle}>
              {t('page.dashboard.tableTurnover')}
              <Tooltip title={t('page.dashboard.tooltip.turnover')}>
                <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
              </Tooltip>
            </Text>{' '}
            <br />
            <br />
            <Text className={classes.bigData2}>
              {dashboard ? `${dashboard?.turnoverPercentage}%` : '---'}
            </Text>
          </Card>
        </Col>
        <Col xl={8} xs={24} className={classes.container}>
          <Card color={colors.white} width='100%' height={145} textAlign='center'>
            <Text className={classes.bigTitle}>
              {t('page.dashboard.tableRate')}
              <Tooltip title={t('page.dashboard.tooltip.tableRate')}>
                <QuestionCircleOutlined style={{ paddingLeft: 10 }} />
              </Tooltip>
            </Text>{' '}
            <br />
            <br />
            <Text className={classes.bigData2}>
              {dashboard ? `${dashboard?.openTablePercentage}%` : '---'}
            </Text>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Card color={colors.white} width='100%' height='100%'>
            <Text className={classes.title}>{t('page.dashboard.salesRanking')}</Text>
            <br />
            <ReportResultCounts counts={numberOfTableResults} />
            <br />
            <Table columns={topSales.columns} dataSource={topSales.data} scroll={{ x: 500 }} />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  )
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 20,
  },
  title: {
    fontSize: 18,
  },
  bigTitle: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  bigData: {
    height: 60,
    fontSize: 22,
    padding: 25,
    backgroundColor: colors.data1,
    color: colors.white,
    borderRadius: 10,
  },
  bigData2: {
    height: 60,
    fontSize: 22,
    padding: 25,
    backgroundColor: colors.data3,
    color: colors.white,
    borderRadius: 10,
  },
  userName: {
    fontSize: 18,
    marginLeft: 0,
    marginTop: 5,
  },
  data: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  tips: {
    fontSize: 16,
    color: colors.textTertiary,
  },
  btn2: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    color: colors.white,
    marginRight: 20,
  },
  datePicker: {
    fontSize: 18,
    fontWeight: 'bold',
    borderColor: 'transparent',
    borderRadius: 7,
    padding: 10,
    boxShadow: 'rgba(0, 0, 0, 0.05) 3px 3px 3px',
    '&:hover': {
      borderColor: colors.textPrimary,
    },
  },
}))
