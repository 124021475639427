import { DownOutlined, GlobalOutlined } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Dropdown, Menu, Select, Typography as Text } from 'antd'
import React from 'react'

import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

export default function LanguageOption(props) {
  const { position } = props
  const { Option } = Select
  const classes = useStyles(props)
  const dispatch = useDispatch()
  const currentLang = useSelector((state) => state.app.lang)
  const [selectedLang, setSelectedLang] = React.useState(currentLang)
  let menuItems = []
  let optionItems = []

  const langs = [
    { key: 'en-US', text: 'English' },
    { key: 'zh-HK', text: '繁體中文' },
  ]

  const handleMenuSwitchLang = (key) => {
    setSelectedLang(key.key)
  }

  const onLangOptionChange = (value) => {
    setSelectedLang(value)
  }

  React.useEffect(() => {
    dispatch(actions.app.changeLang(selectedLang))
  }, [selectedLang, dispatch])

  menuItems = langs.map((lang) => {
    return (
      <Menu.Item key={lang.key} onClick={handleMenuSwitchLang} className={classes.menuItem}>
        <Text className={classes.optionItem}>{lang.text}</Text>
      </Menu.Item>
    )
  })

  optionItems = langs.map((lang) => {
    return (
      <Option key={lang.key} value={lang.key}>
        <Text className={classes.optionItem}>{lang.text}</Text>
      </Option>
    )
  })

  const menu = <Menu>{menuItems}</Menu>

  return (
    <>
      {position === 'header' && (
        <Dropdown overlay={menu}>
          <Text className={classes.icon}>
            <GlobalOutlined />
            <DownOutlined style={{ paddingLeft: 6 }} />
          </Text>
        </Dropdown>
      )}
      {position === 'auth' && (
        <>
          <GlobalOutlined className={classes.icon} />
          <Select value={selectedLang} onChange={onLangOptionChange} bordered={false}>
            {optionItems}
          </Select>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 20,
    color: colors.grey,
  },
  optionItem: {
    fontSize: 16,
  },
  menuContainer: {
    textAlign: 'left',
  },
}))
