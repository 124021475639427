import { PrinterFilled } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import ReportActionButtonBase from './ReportActionButton'

/**
 * @typedef PrintReportButtonProps
 * @property {() => void} onClick
 */

/**
 *
 * @param {PrintReportButtonProps} props
 * @returns
 */
export default function PrintReportButton(props) {
  const { onClick } = props
  const { t } = useTranslation()

  return (
    <ReportActionButtonBase
      title={t('app.common.print')}
      icon={<PrinterFilled style={{ paddingTop: 1, fontSize: 20 }} />}
      onClick={onClick}
    />
  )
}
