const devConfig = {
  api: {
    portalLocal: 'http://10.0.0.93:8080',
    customer: 'https://test-customer-api.dimorder.com',
    dimorder: 'https://test-api.dimorder.com',
    node: 'https://test-node.dimorder.com',
  },
  env: 'test',
  receipt: 'http://test-mp-order.dimorder.com/',
  report: 'https://test-report-api.dimorder.com/report/',
}

export default devConfig
