import { useEffect, useState } from 'react'

import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'

export default function usePaymentMethods() {
  const [paymentMethods, setPaymentMethods] = useState({})
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  useEffect(() => {
    const getPaymentMethods = async () => {
      const { data } = await dimorderApi.instance(activeMerchant.id)?.getPaymentMethods()
      setPaymentMethods(data)
    }

    getPaymentMethods()
  }, [activeMerchant?.id])

  return paymentMethods
}
