import { UploadOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { ProFormText, ProFormSwitch, ProFormSelect, ProFormTextArea } from '@ant-design/pro-form'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Typography as Text,
  Upload,
  message,
  Table,
  Image,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import _ from 'lodash'
import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import ReportCard from '@/components/ReportCard'
import { useGroup, useGroupId } from '@/hooks/group'
import {
  usePaginatedRewardsQuery,
  useRewardDeletion,
  useRewardMutation,
} from '@/hooks/queries/CRM/usePaginatedRewardsQuery'
import { dummyRequest } from '@/libs/uploadFile'

import BreadcrumbItem from '../BreadcrumbItem'
import Pagination from '../Pagination'

export default function RewardSetting(props) {
  const { t, i18n } = useTranslation()
  const classes = useStyles(props)
  const [modalOpen, setModalOpen] = useState(false)
  const groupId = useGroupId()
  const group = useGroup()
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedRewardId, setSelectedRewardId] = useState(null)
  const { data: paginatedRewards, isLoading } = usePaginatedRewardsQuery(groupId, currentPage)

  const RewardsTableColumns = [
    {
      title: t('page.crm.rewardSetting.form.itemImage'),
      dataIndex: 'imageUrl',
      render: (imageUrl) => (
        <Image
          height={100}
          src={
            imageUrl !== ''
              ? imageUrl.replace('/1024x', '/240x') // 縮小圖片節省資源
              : 'https://img.dimorder.com/240x/public/logo/default_food_pic.jpeg'
          }
        />
      ),
    },
    {
      title: t('page.crm.rewardSetting.form.name'),
      dataIndex: ['locales', i18n.language === 'en-US' ? 'en' : 'zh', 'name'],
      render: (name) => {
        return <Text>{name}</Text>
      },
    },
    {
      title: t('page.crm.rewardSetting.form.description'),
      dataIndex: ['locales', i18n.language === 'en-US' ? 'en' : 'zh', 'desc'],
      render: (desc) => {
        return <Text>{desc}</Text>
      },
    },
    {
      title: t('page.crm.rewardSetting.form.requiredPoint'),
      dataIndex: 'points',
      // sorter: (a, b) => a.points - b.points,
    },
    {
      title: t('app.common.enable'),
      dataIndex: 'enabled',
      // sorter: (a, b) => a.enabled - b.enabled,
      render: (enabled) => (
        <span>
          {enabled ? <Text>{t('app.common.enable')}</Text> : <Text>{t('app.common.disable')}</Text>}
        </span>
      ),
    },
    {
      title: t('app.common.edit'),
      dataIndex: 'id',
      render: (id) => (
        <a
          onClick={() => {
            setSelectedRewardId(id)
            setModalOpen(true)
          }}
        >
          {t('app.common.edit')}
        </a>
      ),
    },
  ]

  return (
    <PageContainer
      ghost
      breadcrumb
      header={{
        breadcrumb: {
          routes: [
            {
              path: '/crm',
              breadcrumbName: t('page.crm.title'),
            },
            {
              path: '/crm/:groupId',
              breadcrumbName: group?.name,
            },
            {
              path: '/crm/:groupId/rewardsetting',
              breadcrumbName: t('page.crm.rewardSetting.title'),
            },
          ],
          itemRender: (route, params, routes, paths) => {
            return <BreadcrumbItem route={route} params={params} routes={routes} paths={paths} />
          },
        },
        title: '',
        style: {
          paddingTop: '24px',
        },
      }}
    >
      <ReportCard>
        <Button
          type='primary'
          size='large'
          className={classes.button}
          onClick={() => setModalOpen(true)}
        >
          {t('page.crm.rewardSetting.addItem')}
        </Button>
        <Table
          columns={RewardsTableColumns}
          dataSource={paginatedRewards?.data}
          scroll={{ x: 800 }}
          pagination={false}
        />
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hasNextPage={!!paginatedRewards?.meta?.next}
          loading={isLoading}
        />
      </ReportCard>
      <RewardItemModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentPage={currentPage}
        selectedRewardId={selectedRewardId}
        setSelectedRewardId={setSelectedRewardId}
      />
    </PageContainer>
  )
}

/**
 *
 * @param {number} currentPage
 * @param {string | null} selectedRewardId
 * @returns
 */
function useFormWithInitialValues(currentPage, selectedRewardId) {
  const [form] = useForm()
  const groupId = useGroupId()
  const group = useGroup()
  const merchants = group.groupMerchants
  const { data: paginatedRewards } = usePaginatedRewardsQuery(groupId, currentPage)

  // edit / add modal 的 default value
  const initialValues = useMemo(() => {
    const selectedReward = paginatedRewards?.data?.find((reward) => reward.id === selectedRewardId)
    return {
      enabled: selectedReward?.enabled ?? false,
      points: selectedReward?.points ?? null,
      locales: selectedReward?.locales,
      merchants: _.map(merchants, (merchant) => {
        return {
          enabled: !selectedReward?.merchants?.[merchant?.id]?.disabled ?? true,
          kitchenReceiptSettingId:
            selectedReward?.merchants[merchant?.id]?.kitchenReceiptSettingId ?? 'all',
          name: merchant?.name,
          id: merchant?.id,
        }
      }),
    }
  }, [selectedRewardId])

  // 每次選擇 reward 後設定 form 的值
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [selectedRewardId])

  return { form, initialValues }
}

/**
 * 新增/編輯 reward Modal
 * @typedef RewardItemModal
 * @property {boolean} modalOpen
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setModalOpen
 * @property {number} currentPage
 * @property {React.Dispatch<React.SetStateAction<number>>} selectedPointRuleId
 * @property {React.Dispatch<React.SetStateAction<string | null>>} setSelectedPointRuleId
 * @param {RewardItemModalProps} props
 * @returns
 */
function RewardItemModal(props) {
  const { modalOpen, setModalOpen, currentPage, selectedRewardId, setSelectedRewardId } = props
  const { t } = useTranslation()
  const classes = useStyles(props)

  const groupId = useGroupId()
  const group = useGroup()
  const merchants = group.groupMerchants
  const { form, initialValues } = useFormWithInitialValues(currentPage, selectedRewardId)
  const { mutate: mutateReward, isLoading: isMutating } = useRewardMutation(
    groupId,
    currentPage,
    selectedRewardId,
    {
      onSuccess: () => {
        handleClose()
      },
      onError: () => {
        // Modal.error({
        //   title: t('page.crm.error.imageUpload'),
        // })
        Modal.error({
          title: t('page.crm.error.updateInfo'),
        })
      },
    },
  )
  const { mutate: deleteReward, isLoading: isDeleting } = useRewardDeletion(
    groupId,
    currentPage,
    selectedRewardId,
    {
      onSuccess: () => {
        handleClose()
      },
      onError: () => {
        Modal.error({
          title: t('page.crm.error.deleteInfo'),
        })
      },
    },
  )

  const handleClose = () => {
    // 重設 form
    form.resetFields()
    setModalOpen(false)
    setSelectedRewardId(null)
  }

  // 刪除 reward
  const handleDeleteReward = () => {
    Modal.confirm({
      title: t('app.common.deleteConfirmation'),
      icon: <ExclamationCircleFilled />,
      okText: t('app.common.yes'),
      okType: 'danger',
      cancelText: t('app.common.no'),
      onOk() {
        deleteReward()
      },
    })
  }

  const getPrinterSettingOption = (record) => {
    const merchantPrinterSetting = _.find(merchants, (m) => m.id === record.id)?.printerSetting
      ?.kitchenReceiptSettings
    const printerSetting = merchantPrinterSetting?.map((setting) => ({
      label: setting.name,
      value: setting.id,
    }))
    printerSetting?.unshift({ label: t('app.common.all'), value: 'all' })
    return printerSetting
  }

  const limitFileType = (file) => {
    // 限制只可以上傳 image 的 file
    const isValidType = file.type.includes('image')
    if (!isValidType) {
      message.error(t('page.crm.error.image'))
    }
    return isValidType || Upload.LIST_IGNORE
  }

  // merchants 的 Table
  const merchantSettingTableData = {
    columns: [
      {
        title: t('page.crm.rewardSetting.form.merchantTable.merchant'),
        dataIndex: 'name',
        render: (name) => <Text style={{ paddingBottom: 24 }}>{name}</Text>,
      },
      {
        title: t('page.crm.rewardSetting.form.merchantTable.printerSetting'),
        dataIndex: 'kitchenReceiptSettingId',
        render: (_, record, index) => {
          return (
            <ProFormSelect
              width='xs'
              options={getPrinterSettingOption(record)}
              name={['merchants', index, 'kitchenReceiptSettingId']}
              placeholder={`${t('app.common.pleaseEnter')}${t(
                'page.crm.rewardSetting.form.merchantTable.printerSetting',
              )}`}
              rules={[
                {
                  required: true,
                  message: t('app.common.required', {
                    field: t('page.crm.rewardSetting.form.merchantTable.printerSetting'),
                  }),
                },
              ]}
            />
          )
        },
      },
      {
        title: t('page.crm.rewardSetting.form.merchantTable.enableRedeem'),
        dataIndex: 'enabled',
        render: (_, record, index) => <ProFormSwitch name={['merchants', index, 'enabled']} />,
      },
    ],
    data: initialValues.merchants,
  }

  return (
    <Modal
      forceRender
      visible={modalOpen}
      title={t('page.crm.rewardSetting.form.title')}
      onCancel={handleClose}
      width={1200}
      footer={null}
    >
      <Form
        preserve
        form={form}
        onFinish={() => {
          // values 需要從 useForm 拿才能有整個 initialValues
          const values = form.getFieldValue()
          // 把 merchants 改成 BE format
          const updatedMerchants = {}
          values.merchants.forEach((merchant) => {
            updatedMerchants[merchant.id] = {
              disabled: !merchant.enabled,
              kitchenReceiptSettingId:
                merchant.kitchenReceiptSettingId !== 'all'
                  ? merchant.kitchenReceiptSettingId
                  : null,
            }
          })
          // 上傳 reward
          const mutatedReward = {
            ...values,
            locales: _.map(values.locales, (locale, key) => ({ ...locale, locale: key })),
            imageUrl: values.imageUrl,
            points: parseInt(values.points),
            merchants: updatedMerchants,
          }
          mutateReward(mutatedReward)
        }}
        autoComplete='off'
        initialValues={initialValues}
      >
        <Row gutter={24}>
          <Col xs={24} md={10}>
            <Col xs={24}>
              <Text className={classes.label}>{t('app.common.enable')}</Text>
              <ProFormSwitch name='enabled' />
            </Col>
            <Col xs={24} md={12}>
              <Text className={classes.label}>
                {t('page.crm.rewardSetting.form.requiredPoint')}
              </Text>
              <ProFormText
                name='points'
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.requiredPoint',
                )}`}
                rules={[
                  {
                    required: true,
                    message: t('app.common.required', {
                      field: t('page.crm.rewardSetting.form.requiredPoint'),
                    }),
                  },
                  () => ({
                    validator: (_, value) => {
                      return new Promise((resolve, reject) => {
                        const isInteger = /^[1-9]\d*$/
                        if (!isInteger.test(value)) {
                          reject(new Error('Validation Failed'))
                        }
                        resolve()
                      })
                    },
                    message: t('page.crm.error.integer'),
                  }),
                ]}
              />
            </Col>
            <Col xs={24}>
              <ProFormText
                name={['locales', 'zh', 'name']}
                label={t('page.crm.rewardSetting.form.name') + ` (${t(`app.common.zh`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.name',
                )} (${t(`app.common.zh`)})`}
                rules={[
                  () => ({
                    validator: (ruleObject, value) => {
                      if (value === '') {
                        throw new Error('Validation Failed')
                      } else {
                        return Promise.resolve()
                      }
                    },
                    message: t('app.common.required', {
                      field: t('page.crm.pointRule.form.name'),
                    }),
                  }),
                ]}
                required
              />
            </Col>
            <Col xs={24}>
              <ProFormText
                name={['locales', 'en', 'name']}
                label={t('page.crm.rewardSetting.form.name') + ` (${t(`app.common.en`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.name',
                )} (${t(`app.common.en`)})`}
              />
            </Col>
            <Col xs={24}>
              <ProFormText
                name={['locales', 'kitchen', 'name']}
                label={t('page.crm.rewardSetting.form.name') + ` (${t('app.common.kitchen')})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.name',
                )} (${t(`app.common.kitchen`)})`}
              />
            </Col>
            <Col xs={24}>
              <ProFormTextArea
                name={['locales', 'zh', 'desc']}
                label={t('page.crm.rewardSetting.form.description') + ` (${t(`app.common.zh`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.description',
                )} (${t(`app.common.zh`)})`}
              />
            </Col>
            <Col xs={24}>
              <ProFormTextArea
                name={['locales', 'en', 'desc']}
                label={t('page.crm.rewardSetting.form.description') + ` (${t(`app.common.en`)})`}
                placeholder={`${t('app.common.pleaseEnter')}${t(
                  'page.crm.rewardSetting.form.description',
                )} (${t(`app.common.en`)})`}
              />
            </Col>
          </Col>
          <Col xs={24} md={14}>
            <Col xs={24}>
              <Text className={classes.label}>{t('page.crm.rewardSetting.form.itemImage')}</Text>
              <Form.Item
                name='imageUrl'
                valuePropName='fileList'
                getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
              >
                <Upload
                  name='file'
                  beforeUpload={limitFileType}
                  listType='picture'
                  maxCount={1}
                  customRequest={dummyRequest}
                >
                  <Button icon={<UploadOutlined />}>{t('app.common.upload')}</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Text className={classes.label}>
                {t('page.crm.rewardSetting.form.merchantSetting')}
              </Text>

              <Table
                columns={merchantSettingTableData.columns}
                dataSource={merchantSettingTableData.data}
                rowKey={(record) => record.id}
                scroll={{ x: 600 }}
                pagination={false}
              />
            </Col>
          </Col>
          <Col xs={24} md={24} className={classes.container}>
            {selectedRewardId ? (
              <Button
                type='primary'
                className={classes.btn}
                danger
                onClick={handleDeleteReward}
                loading={isDeleting}
                disabled={isMutating}
              >
                {t('app.common.delete')}
              </Button>
            ) : (
              <div />
            )}
            <div style={{ gap: 10, display: 'flex' }}>
              <Button
                type='default'
                className={classes.btn}
                onClick={handleClose}
                disabled={isMutating || isDeleting}
              >
                {t('app.common.no')}
              </Button>
              <Button
                type='primary'
                htmlType='submit'
                className={classes.btn}
                loading={isMutating}
                disabled={isDeleting}
              >
                {t('app.common.submit')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 14,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
  },
  text: {
    fontSize: 16,
  },
  uploadLabel: {
    paddingBottom: 8,
  },
  button: {
    marginBottom: 16,
  },
  label: {
    paddingBottom: 8,
  },
})
