import Card from '@/components/Card'

export default function ReportCard(props) {
  const { children, textAlign = 'left', padding = '20px', width = '100%', height = '100%' } = props

  return (
    <Card
      width={width}
      height={height}
      padding={padding}
      borderRadius='2px'
      textAlign={textAlign}
      boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
    >
      {children}
    </Card>
  )
}
