const countWorkingdays = (current, workingdays) => {
  let count = 0
  while (workingdays > 0) {
    if (![6, 0].includes(current.add(1, 'days').day())) {
      workingdays--
    }
    count++
  }
  return count
}

export { countWorkingdays }
