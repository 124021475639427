import { LockOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { actions, useDispatch, useSelector } from '@/redux'

export default function AddMerchantModal(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { visible, data, firstLogin, onSuccess } = useSelector(
    (state) => state.merchant.merchantLoginDialog,
  )
  const [confirmLoading, setConfirmLoading] = React.useState(false)
  const [fields, setFields] = React.useState([])

  useEffect(() => {
    // preset fields
    setFields([
      { name: ['merchantAccount'], value: data.merchantShortCode },
      { name: ['username'], value: data.username },
      { name: ['password'], value: '' },
    ])
  }, [visible])

  const onFinish = async (values) => {
    if (firstLogin) {
      try {
        setConfirmLoading(true)
        await dispatch(actions.merchant.loginMerchant(values))
        dispatch(actions.user.login())
        onSuccess()
        setConfirmLoading(false)
        dispatch(actions.merchant.closeMerchantLoginDialog())
      } catch (error) {
        if (error.message === '!permission') {
          Modal.error({
            title: t('app.component.addMerchantModal.error.scope.title'),
            content: t('app.component.addMerchantModal.error.scope.msg'),
          })
        } else {
          Modal.error({
            title: t('app.component.addMerchantModal.error.invalid.title'),
            content: t('app.component.addMerchantModal.error.invalid.msg'),
          })
        }
        setConfirmLoading(false)
      }
    } else {
      try {
        setConfirmLoading(true)
        await dispatch(actions.merchant.loginMerchant(values))
        setConfirmLoading(false)
        dispatch(actions.merchant.closeMerchantLoginDialog())
      } catch (error) {
        setConfirmLoading(false)
        if (error.message === '!permission') {
          Modal.error({
            title: t('app.component.addMerchantModal.error.scope.title'),
            content: t('app.component.addMerchantModal.error.scope.msg'),
          })
        } else {
          Modal.error({
            title: t('app.component.addMerchantModal.error.invalid.title'),
            content: t('app.component.addMerchantModal.error.invalid.msg'),
          })
        }
      }
    }
  }

  const onClose = () => {
    dispatch(actions.merchant.closeMerchantLoginDialog())
  }

  return (
    <>
      <Modal
        title={data.title}
        visible={visible}
        centered
        footer={null}
        onCancel={onClose}
        maskClosable={false}
      >
        <Form
          name='merchant_login'
          className='login-form'
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          fields={fields}
          onChange={(_, allFields) => {
            setFields(allFields)
          }}
          size='large'
        >
          <Form.Item
            name='merchantAccount'
            rules={[
              {
                required: true,
                message: t('app.component.addMerchantModal.error.empty.merchantAccount'),
              },
            ]}
          >
            <Input
              prefix={<ShopOutlined className='site-form-item-icon' />}
              placeholder={t('app.component.addMerchantModal.merchantAccount')}
            />
          </Form.Item>
          <Form.Item
            name='username'
            rules={[
              {
                required: true,
                message: t('app.component.addMerchantModal.error.empty.username'),
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className='site-form-item-icon' />}
              placeholder={t('app.component.addMerchantModal.username')}
            />
          </Form.Item>
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: t('app.component.addMerchantModal.error.empty.password'),
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className='site-form-item-icon' />}
              type='password'
              placeholder={t('app.component.addMerchantModal.password')}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              loading={confirmLoading}
            >
              {t('app.component.addMerchantModal.loginMerchant')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
