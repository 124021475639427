import { UploadOutlined } from '@ant-design/icons'
import { ProFormText, ProFormTextArea } from '@ant-design/pro-form'
import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Typography as Text, Modal, Row, Col, Button, message, Upload, Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Loading from '@/components/Loading'
import { useGroup, useGroupId } from '@/hooks/group'
import dimorderApi from '@/libs/api/dimorderApi'
import { dummyRequest } from '@/libs/uploadFile'
import { useDispatch, actions } from '@/redux'
import colors from '@/style/colors'

import BreadcrumbItem from '../BreadcrumbItem'
import GroupImage from './GroupImage'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    paddingBottom: 8,
  },

  nameText: {
    fontSize: '26px',
    fontWeight: 'bold',
  },
  descText: {
    padding: '10px 0px',
    textAlign: 'justify',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bigBtn: {
    backgroundColor: colors.button,
    borderColor: colors.button,
    borderRadius: 5,
    fontSize: 15,
    height: 35,
  },
  btn: {
    borderRadius: 5,
    fontSize: 15,
    height: 35,
    margin: 4,
  },
})

export default function CRMGroup(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles(props)
  const [modalOpen, setModalOpen] = useState(false)
  const [form] = useForm()
  const groupId = useGroupId()
  const group = useGroup()

  const showModal = () => {
    setModalOpen(true)
  }

  const handleCancel = () => {
    setModalOpen(false)
    form.resetFields()
  }

  // update group information
  const onFinish = async () => {
    const filledData = form.getFieldValue()
    const { imageUrl, ...data } = filledData

    // 上傳 group 圖片
    if (imageUrl) {
      try {
        await dimorderApi.group(groupId).updateGroupImage(imageUrl[0].originFileObj)
      } catch (error) {
        Modal.error({
          title: t('page.crm.error.imageUpload'),
        })
        return
      }
    }

    let updatedGroup
    try {
      // 上傳 group name 和 desc
      const update = {
        ...data,
        id: groupId,
      }
      updatedGroup = await dimorderApi.group(groupId).updateCRMGroup(update)
    } catch (error) {
      Modal.error({
        title: t('page.crm.error.updateInfo'),
      })
      return
    }

    handleCancel()
    dispatch(actions.merchant.updateCRMGroup(updatedGroup))
  }

  const limitFileType = (file) => {
    // 限制只可以上傳 image 的 file
    const isValidType = file.type.includes('image')
    if (!isValidType) {
      message.error(t('page.crm.error.image'))
    }
    return isValidType || Upload.LIST_IGNORE
  }

  // 編輯 button
  const EditGroupInfoButton = () => {
    return (
      <>
        <Button type='primary' className={classes.bigBtn} onClick={showModal}>
          {t('app.common.edit')}
        </Button>
        <Modal
          key={modalOpen}
          visible={modalOpen}
          title={t('page.crm.editGroupInfo.title')}
          onCancel={handleCancel}
          width={800}
          footer={null}
        >
          <Form form={form} onFinish={onFinish} autoComplete='off'>
            <Row gutter={24}>
              <Col xs={24}>
                <Text className={classes.label}>{t('page.crm.editGroupInfo.name')}</Text>
                <ProFormText
                  name='name'
                  initialValue={group?.name}
                  placeholder={`${t('app.common.pleaseEnter')}${t('page.crm.editGroupInfo.name')}`}
                  rules={[
                    {
                      required: true,
                      message: t('app.common.required', {
                        field: t('page.crm.editGroupInfo.name'),
                      }),
                    },
                  ]}
                />
              </Col>
              <Col xs={24}>
                <Text className={classes.label}>{t('page.crm.editGroupInfo.image')}</Text>
                <Form.Item
                  name='imageUrl'
                  valuePropName='fileList'
                  getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
                >
                  <Upload
                    name='file'
                    beforeUpload={limitFileType}
                    listType='picture'
                    maxCount={1}
                    customRequest={dummyRequest}
                  >
                    <Button icon={<UploadOutlined />}>{t('app.common.upload')}</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Text className={classes.label}>{t('page.crm.editGroupInfo.desc')}</Text>
                <ProFormTextArea
                  name='desc'
                  initialValue={group?.desc}
                  placeholder={`${t('app.common.pleaseEnter')}${t('page.crm.editGroupInfo.desc')}`}
                />
              </Col>
              <Col xs={24} className={classes.buttons}>
                <div style={{ gap: 10, display: 'flex' }}>
                  <Button type='default' className={classes.btn} onClick={handleCancel}>
                    {t('app.common.no')}
                  </Button>
                  <Button type='primary' htmlType='submit' className={classes.btn}>
                    {t('app.common.save')}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    )
  }

  // 會員列表，積分兌換設定，積分發放條件 button
  const ButtonList = () => {
    const buttonInfo = [
      {
        path: 'memberlist',
        name: 'memberList',
      },
      {
        path: 'rewardsetting',
        name: 'rewardSetting',
      },
      {
        path: 'pointrule',
        name: 'pointRule',
      },
    ]
    return (
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {buttonInfo.map((button, index) => {
          return (
            <Button
              key={index}
              type='primary'
              className={classes.btn}
              onClick={() => {
                history.push(`/crm/${groupId}/${button.path}`)
              }}
            >
              {t(`page.crm.${button.name}.title`)}
            </Button>
          )
        })}
      </Row>
    )
  }

  if (_.isEmpty(group)) return <Loading />

  return (
    <PageContainer
      ghost
      header={{
        breadcrumb: {
          routes: [
            {
              path: '/crm',
              breadcrumbName: t('page.crm.title'),
            },
            {
              path: '/crm/:groupId',
              breadcrumbName: group?.name,
            },
          ],
          itemRender: (route, params, routes, paths) => {
            return <BreadcrumbItem route={route} params={params} routes={routes} paths={paths} />
          },
        },
        title: '',
        style: {
          paddingTop: '24px',
        },
      }}
    >
      <Row className={classes.container}>
        <Col xs={24} lg={16}>
          <GroupImage url={group?.imageUrl} />
          <Row
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text className={classes.nameText}>{group?.name}</Text>
            <EditGroupInfoButton />
          </Row>
          <ButtonList />
          <Text className={classes.descText}>{group?.desc}</Text>
        </Col>
      </Row>
    </PageContainer>
  )
}
