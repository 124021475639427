import styled from 'styled-components'

const Div = styled.div`
  background-color: ${(props) => props.color};
  color: #fff;
  font-size: 6px;

  border-radius: 10px;
  padding: 2px 5px;
`

export default function Tag(props) {
  const { className, color, text } = props

  return (
    <Div className={className} color={color}>
      {text}
    </Div>
  )
}
