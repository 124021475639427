import { CaretDownFilled, PlusCircleFilled } from '@ant-design/icons'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Menu, Row, Typography as Text } from 'antd'
import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import MerchantIcon from '@/components/MerchantIcon'
import { actions, useDispatch, useSelector } from '@/redux'
import colors from '@/style/colors'

const Div = styled.div`
  .ant-collapse .ant-collapse-item .ant-collapse-header {
    width: 250px;
    padding: 0px 5px 5px 0px;
    padding-left: 10px;
  }

  .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    display: none;
  }

  .ant-collapse-ghost .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    width: 250px;
    padding: 1px 5px 5px 6px;
  }
`

export default function MerchantMenu(props) {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { merchants, activeMerchant } = useSelector((state) => state.merchant)
  const [activeKey, setActiveKey] = React.useState('')
  const { Panel } = Collapse
  let items = []

  const handleSwitchMerchant = (key) => {
    dispatch(actions.merchant.activateMerchantById(key.key))
    setActiveKey('')
  }

  const handleAddMerchant = () => {
    dispatch(
      actions.merchant.openMerchantLoginDialog({
        data: { title: t('app.component.merchant.addMerchant') },
      }),
    )
  }

  const onExpand = (key) => {
    setActiveKey(key)
  }

  if (merchants) {
    items = _.map(merchants, (merchant) => {
      if (activeMerchant?.id !== merchant?.id) {
        return (
          <Menu.Item key={merchant.id} onClick={handleSwitchMerchant} className={classes.menuItem}>
            {merchant.name}
          </Menu.Item>
        )
      } else return null
    })
  }

  items?.push(
    <Menu.Item key='new' className={classes.menuItem} onClick={handleAddMerchant}>
      <PlusCircleFilled /> {t('app.component.merchant.addMerchant')}
    </Menu.Item>,
  )

  return (
    <Div>
      <Collapse ghost activeKey={activeKey} onChange={onExpand}>
        <Panel
          style={{
            width: 230,
          }}
          header={
            <Row>
              <Col span={4}>
                <MerchantIcon />
              </Col>
              <Col span={20} style={{ marginTop: 3, paddingLeft: 5 }}>
                <Text className={classes.merchantName}>
                  {activeMerchant?.name}
                  <CaretDownFilled
                    style={{
                      fontSize: 16,
                      color: colors.white,
                      position: 'absolute',
                      paddingLeft: 3,
                      marginTop: 5,
                    }}
                  />
                </Text>
              </Col>
            </Row>
          }
        >
          <Menu theme='dark' selectable={false} className={classes.menuContainer}>
            {items}
          </Menu>
        </Panel>
      </Collapse>
    </Div>
  )
}

const useStyles = makeStyles(() => ({
  merchantName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.white,
  },
  null: {
    fontSize: 24,
    color: colors.textTertiary,
  },
  menuItem: {
    fontSize: 15,
    fontWeight: 'bold',
    color: colors.white,
  },
  menuContainer: {
    textAlign: 'left',
    maxHeight: 400,
    overflowY: 'auto',
  },
}))
