const config = {
  api: {
    portalLocal: 'http://10.0.0.97:3300',
    customer: 'https://beta-customer-api.dimorder.com',
    dimorder: 'https://beta-api.dimorder.com',
    node: 'https://beta-node.dimorder.com',
  },
  env: 'beta',
  receipt: 'https://beta-mp-order.dimorder.com/',
  report: 'https://beta-report-api.dimorder.com/report/',
}

export default config
