import { PageContainer } from '@ant-design/pro-layout'
import { makeStyles } from '@material-ui/styles'
import { Col, Collapse, Modal, Row, Table, Typography as Text } from 'antd'
import moment from 'moment'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'

import Card from '@/components/Card'
import DurationFilterButton from '@/components/DurationFilterButton'
import FilterDialog from '@/components/FilterDialog'
import LoadingIndicator from '@/components/LoadingIndicator'
import ReportActionButtons from '@/components/ReportActionButtons'
import ReportPrintContainer from '@/components/ReportPrintContainer'
import { Bar, Doughnut } from '@/components/chart'
import { dataColors, pageStyle } from '@/constants'
import dimorderApi from '@/libs/api/dimorderApi'
import { useSelector } from '@/redux'
import colors from '@/style/colors'

export default function CustomerReport(props) {
  const { t } = useTranslation()
  const classes = useStyles(props)
  const printRef = useRef()
  const { Panel } = Collapse
  const [params, setParams] = React.useState(null)
  const [customerReport, setCustomerReport] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [activeKey, setActiveKey] = React.useState('1')
  const activeMerchant = useSelector((state) => state.merchant.activeMerchant)

  React.useEffect(() => {
    if (params) {
      const getItems = async () => {
        setLoading(true)
        try {
          const customerReport = await dimorderApi
            .instance(activeMerchant.id)
            ?.getCustomerReport(params)
          setCustomerReport(customerReport)
          setActiveKey('0')
        } catch (error) {
          console.log(error)
          Modal.error({
            title: t('app.common.error'),
            content: t('app.common.submitError'),
          })
          setLoading(false)
          setActiveKey('1')
        }
        setLoading(false)
      }
      getItems()
    }
  }, [params, t, activeMerchant])

  const customerTypeChart = {
    labels: [
      t('app.constant.deliveryType.table'),
      t('app.constant.deliveryType.takeaway'),
      t('app.constant.deliveryType.sDelivery'),
    ],
    datasets: [
      {
        data: [],
        backgroundColor: dataColors.slice(0, 3),
      },
    ],
  }

  const weekDayCustomerCount = {
    labels: [
      t('app.constant.weekDay.mon'),
      t('app.constant.weekDay.tue'),
      t('app.constant.weekDay.wed'),
      t('app.constant.weekDay.thur'),
      t('app.constant.weekDay.fri'),
    ],
    datasets: [
      {
        label: t('app.constant.deliveryType.table'),
        data: [],
        backgroundColor: dataColors[0],
      },
      {
        label: t('app.constant.deliveryType.takeaway'),
        data: [],
        backgroundColor: dataColors[1],
      },
      {
        label: t('app.constant.deliveryType.sDelivery'),
        data: [],
        backgroundColor: dataColors[2],
      },
    ],
  }

  const weekendCustomerCount = {
    labels: [t('app.constant.weekDay.sat'), t('app.constant.weekDay.sun')],
    datasets: [
      {
        label: t('app.constant.deliveryType.table'),
        data: [],
        backgroundColor: dataColors[0],
      },
      {
        label: t('app.constant.deliveryType.takeaway'),
        data: [],
        backgroundColor: dataColors[1],
      },
      {
        label: t('app.constant.deliveryType.sDelivery'),
        data: [],
        backgroundColor: dataColors[2],
      },
    ],
  }

  const customerRatio = {
    columns: [
      {
        title: t('app.common.peopleCount'),
        dataIndex: 'customerNumber',
        render: (customerNumber) => (
          <Text>
            {customerNumber} {t('app.common.people')}
          </Text>
        ),
      },
      {
        title: t('page.customerReport.ratio'),
        dataIndex: 'ratio',
        render: (count) => <Text>{count}%</Text>,
      },
      {
        title: t('page.customerReport.orderCount'),
        dataIndex: 'count',
      },
    ],
    weekDayData: [],
    weekendData: [],
  }

  customerTypeChart.datasets[0].data.push(customerReport?.data?.deliveryType?.table)
  customerTypeChart.datasets[0].data.push(customerReport?.data?.deliveryType?.takeaway)
  customerTypeChart.datasets[0].data.push(customerReport?.data?.deliveryType?.storeDelivery)

  // deliveryTypeIndex => 0: table; 1: takeaway; 2: storeDeleivery
  const fetchCustomerCountByDaysData = (data, deliveryTypeIndex) => {
    for (const [key, value] of Object.entries(data)) {
      switch (key) {
        case 'Monday': {
          weekDayCustomerCount.datasets[deliveryTypeIndex].data[0] = value
          break
        }
        case 'Tuesday': {
          weekDayCustomerCount.datasets[deliveryTypeIndex].data[1] = value
          break
        }
        case 'Wednesday': {
          weekDayCustomerCount.datasets[deliveryTypeIndex].data[2] = value
          break
        }
        case 'Thursday': {
          weekDayCustomerCount.datasets[deliveryTypeIndex].data[3] = value
          break
        }
        case 'Friday': {
          weekDayCustomerCount.datasets[deliveryTypeIndex].data[4] = value
          break
        }
        case 'Saturday': {
          weekendCustomerCount.datasets[deliveryTypeIndex].data[0] = value
          break
        }
        case 'Sunday': {
          weekendCustomerCount.datasets[deliveryTypeIndex].data[1] = value
          break
        }
        default:
          break
      }
    }
  }

  if (customerReport?.data) {
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.table?.weekday?.customerCountByDays,
      0,
    )
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.takeaway?.weekday?.customerCountByDays,
      1,
    )
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.storeDelivery?.weekday?.customerCountByDays,
      2,
    )
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.table?.weekend?.customerCountByDays,
      0,
    )
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.takeaway?.weekend?.customerCountByDays,
      1,
    )
    fetchCustomerCountByDaysData(
      customerReport?.data?.byDeliveryType?.storeDelivery?.weekend?.customerCountByDays,
      2,
    )

    for (const [key, value] of Object.entries(
      customerReport?.data?.byDeliveryType?.table?.weekday?.customerCountBuckets,
    )) {
      customerRatio.weekDayData.push({
        key,
        customerNumber:
          Number(key) !==
          customerReport?.data?.byDeliveryType?.table?.weekday?.customerCountBuckets?.length - 1
            ? `${value.minPeople} - ${value.maxPeople}`
            : `${value.minPeople}+`,
        ratio: value.percentage,
        count: value.numOrders,
      })
    }

    for (const [key, value] of Object.entries(
      customerReport?.data?.byDeliveryType?.table?.weekend?.customerCountBuckets,
    )) {
      customerRatio.weekendData.push({
        key,
        customerNumber:
          Number(key) !==
          customerReport?.data?.byDeliveryType?.table?.weekend?.customerCountBuckets?.length - 1
            ? `${value.minPeople} - ${value.maxPeople}`
            : `${value.minPeople}+`,
        ratio: value.percentage,
        count: value.numOrders,
      })
    }
  }

  const drawBarChart = (data) => (
    <Bar
      height={400}
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          axis: 'x',
        },
        layout: {
          padding: 10,
        },
        maxBarThickness: 80,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }}
    />
  )

  const drawBarChartForPrint = (data) => (
    <Bar
      height={400}
      width={550}
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          axis: 'x',
        },
        layout: {
          padding: 10,
        },
        maxBarThickness: 80,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }}
    />
  )

  const displayResult = () => (
    <>
      <Row gutter={16} className={classes.pieCardcontainer}>
        <Col xs={24} xl={14}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.customerReport.chart')} {moment(params?.start).format('YYYY-MM-DD HH:mm')}{' '}
              {t('app.common.to')} {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <Text className={classes.dataTitle}>
              {t('page.customerReport.totalCustomer')}：{customerReport?.data?.totalCustomerCount}
            </Text>
            <div className={classes.chartContainer}>
              <Doughnut
                width='80%'
                data={customerTypeChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.customerReport.customerCount')}
              {t('page.customerReport.weekDay')}
            </Text>
            {drawBarChart(weekDayCustomerCount)}
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.customerReport.customerCount')}
              {t('page.customerReport.weekend')}
            </Text>
            {drawBarChart(weekendCustomerCount)}
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.customerReport.customerRatio')}
              {t('page.customerReport.weekDay')}
            </Text>
            <Table
              columns={customerRatio.columns}
              dataSource={customerRatio.weekDayData}
              scroll={{ x: 500 }}
              pagination={false}
            />
          </Card>
        </Col>
        <Col xl={12} xs={24} className={classes.container}>
          <Card
            width='100%'
            height='100%'
            padding='20px'
            borderRadius='2px'
            textAlign='left'
            boxShadow='1px 1px  5px rgba(0, 0, 0, 0.3)'
          >
            <Text className={classes.title}>
              {t('page.customerReport.customerRatio')}
              {t('page.customerReport.weekend')}
            </Text>
            <Table
              columns={customerRatio.columns}
              dataSource={customerRatio.weekendData}
              scroll={{ x: 500 }}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    </>
  )

  const printResult = () => (
    <ReportPrintContainer
      printRef={printRef}
      start={params?.start}
      end={params?.end}
      title={t('page.customerReport.title')}
    >
      <Row gutter={16} className={classes.printContainer}>
        <Col span={14}>
          <>
            <Text className={classes.title}>
              {t('page.customerReport.chart')} {moment(params?.start).format('YYYY-MM-DD HH:mm')}{' '}
              {t('app.common.to')} {moment(params?.end).format('YYYY-MM-DD HH:mm')}
            </Text>
            <br />
            <Text className={classes.dataTitle}>
              {t('page.customerReport.totalCustomer')}：{customerReport?.data?.totalCustomerCount}
            </Text>
            <div className={classes.chartContainer}>
              <Doughnut
                width='80%'
                data={customerTypeChart}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'right',
                      labels: {
                        usePointStyle: true,
                      },
                    },
                  },
                }}
              />
            </div>
          </>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>
              {t('page.customerReport.customerCount')}
              {t('page.customerReport.weekDay')}
            </Text>
            {drawBarChartForPrint(weekDayCustomerCount)}
          </>
        </Col>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>
              {t('page.customerReport.customerCount')}
              {t('page.customerReport.weekend')}
            </Text>
            {drawBarChartForPrint(weekendCustomerCount)}
          </>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>
              {t('page.customerReport.customerRatio')}
              {t('page.customerReport.weekDay')}
            </Text>
            <Table
              columns={customerRatio.columns}
              dataSource={customerRatio.weekDayData}
              scroll={{ x: 500 }}
              pagination={false}
            />
          </>
        </Col>
        <Col span={12} className={classes.printContainer}>
          <>
            <Text className={classes.title}>
              {t('page.customerReport.customerRatio')}
              {t('page.customerReport.weekend')}
            </Text>
            <Table
              columns={customerRatio.columns}
              dataSource={customerRatio.weekendData}
              scroll={{ x: 500 }}
              pagination={false}
            />
          </>
        </Col>
      </Row>
    </ReportPrintContainer>
  )

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
  })

  const handleExport = async () => {
    try {
      await dimorderApi.instance(activeMerchant.id)?.exportCustomerReportCsv(params)
    } catch (error) {
      console.log(error)
      Modal.error({
        title: t('app.common.error'),
        content: t('app.common.exportError'),
      })
    }
  }

  return (
    <div ref={printRef}>
      <PageContainer ghost header={{ title: t('page.customerReport.title') }}>
        <Collapse
          ghost
          expandIcon={() => <></>}
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
        >
          <Panel
            key='1'
            style={{ fontSize: 22 }}
            extra={
              <DurationFilterButton start={params?.start} end={params?.end} activeKey={activeKey} />
            }
          >
            <FilterDialog filter filterTime setParams={setParams} />
          </Panel>
        </Collapse>
        <br />
        <LoadingIndicator loading={loading} />
        {customerReport && !loading && (
          <>
            <ReportActionButtons onPrint={handlePrint} onExport={handleExport} />
            {displayResult()}
            {printResult()}
          </>
        )}
      </PageContainer>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  printContainer: {
    padding: 30,
  },
  container: {
    paddingTop: 20,
  },
  pieCardcontainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 18,
  },
  dataTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.textSecondary,
    textAlign: 'center',
  },
}))
